import { Box, Typography } from "@mui/material";
import { Button } from "../../components/Button";
import CheckIcon from "../../assets/images/check.svg";
import LogoHeader from "../../components/Header/LogoHeader";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { setIsOnboardingSuccessful } from "../../features/globalStates/globalStatesSlice";

const OnboardSuccessPage = () => {
    const locale = useSelector((state) => state.globalStates.locale);
    const history = useHistory();
    const dispatch = useDispatch();
    const isOnboardingSuccessful = useSelector((state) => state.globalStates.isOnboardingSuccessful)
    const successPageText = {
        header: 'Your Moderna Direct account is now ready to use',
        body: 'You can now access Moderna Direct and place reservations. \n' +
            'We are in the process of verifying your information. Until fully verified, ' +
            'you may not see final pricing or have full access to all features'
    }

    useEffect(() => {
        if(!isOnboardingSuccessful) {
            history?.push(`/${locale}/`);
        } else {
            dispatch(setIsOnboardingSuccessful(false));
        }
    }, []);

    const handleClick = () => {
        history.push(`/${locale}/`)
    }

    return (
        <>
            <Box display='flex' width='100%' justifyContent='center'>
                <Box width='60%' minWidth='700px' display='flex' flexDirection='column' alignItems='start'>
                    <LogoHeader />
                    <Box mt={8} width={'100%'} display='flex' flexDirection='column' >
                        <Box display='flex' flexDirection='row' alignItems='center' height='64px' >
                            <Box height='48px' width='64px'>
                                <img src={CheckIcon} alt='check icon' className='CheckIcon' height='48px' width='48px'/>
                            </Box>
                            <Typography variant='h3'>{successPageText?.header}</Typography>
                        </Box>
                        <Box display='flex' flexDirection='column'>
                            <Box paddingLeft={8} mt={4} maxWidth={'640px'}>
                                <Typography variant='p1' style={{ whiteSpace: 'pre-line', fontWeight: '500' }}>{successPageText?.body}</Typography>
                                <Button
                                    type="submit"
                                    sx={{ padding: "16px 32px", textTransform: "none" , marginTop: "16px" }}
                                    data-testid='onBoardSuccessBtn'
                                    buttonType="mds-primary"
                                    onClick={handleClick}
                                >
                                    Go to Moderna Direct
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>

        </>
    )
}

export default OnboardSuccessPage
