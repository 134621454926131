import { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { setReservationData, setDisplayReservationData } from "../features/commerce/commerceSlice";
import { setIsConfirmationPageAccessible } from '../features/globalStates/globalStatesSlice';
import { loadReservationPage } from "../features/contentful/contentfulThunkApi";
import { getReservationProductsApi } from "../features/commerce/commerceThunkApi";
import { Box, Typography } from "@mui/material";
import { Button } from '../components';
import CustomCard from "../components/CustomCardComponent/CustomCard";
import { Spinner } from "../components/Spinner";
import DeliverScheduleComponent from "../components/Reservation/DeliverScheduleComponent";
import OrderDetailsComponent from "../components/Reservation/OrderDetailsComponent";
import ReservationDetailsCard from '../components/Reservation/ReservationDetailsCard';
import { AlertMessage } from "../components/AlertMessage";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useOktaAuth } from '@okta/okta-react';
import { useUserInfoContext } from "../contexts";
import { HandlerBarTemplateHandler, formattedPrice, sortAddresses } from "../utils/Helper";
import { createReservationApi, getReservationById, updateReservationApi, cancelReservationApi, cancelReservation } from '../utils/Reservation/ReservationApi';
import GroupAffiliationComponent from "../components/Reservation/GroupAffiliationComponent";
import AddressSelectionModal from "../components/Reservation/AddressSelectionModal";
import { NewAddressFormModal } from "../components/AddressesForm/NewAddressForm";
import { UserAddressFormModal } from "../components/AddressesForm/UserAddressForm";
import ConfirmationModal from '../components/Reservation/ConfirmationModal';
import { sortArrayByField } from '../utils/Helper';
import { ORDERED_PRODUCT_BY_ID_OR_CODE } from '../utils/Reservation/reservationCommons';
import { createPricingApi, getPricingApi } from '../utils/PricingService/PricingApi';
import { CONTRACT_TYPES } from '../constants';

const ReservationPage = () => {
    const isReservationEnabled = process.env.IS_RESERVATIONS_ENABLED === 'true';
    const isEditReservationEnabled = process.env.IS_EDIT_RESERVATIONS_ENABLED === 'true';
    const isNewReservationUrlsEnabled = process.env.IS_NEW_RESERVATION_URLS_ENABLED === 'true';
    const isOnboardingEnabled = process.env.SIMPLE_ONBOARDING_AND_REGISTRATION === 'true';
    const isRealTimePricingEnabled = process.env.IS_REAL_TIME_PRICING_ENABLED === 'true';
    const dispatch = useDispatch();
    const history = useHistory();
    const locale = useSelector((state) => state.globalStates.locale);
    const { reservationPage: reservationPageContent } = useSelector((store) => store?.contentful);
    const { commerceUser } = useSelector((store) => store?.commerce);
    const userData = useSelector((store) => store?.commerce?.userData);
    const pricingData = useSelector((store) => store?.commerce?.pricingData);
    const defaultAddress = isOnboardingEnabled? userData?.addresses?.filter(address => address.addressType === 'ship_to')[0] : commerceUser?.data?.defaultAddress;
    const { authState } = useOktaAuth() || '';
    const isAuthenticated = authState?.isAuthenticated;
    const [processing, setProcessing] = useState(false);
    const canOrder2023Products = useSelector((state) => state?.globalStates?.canOrder2023Products);
    const { userInfo } = useUserInfoContext() || '';
    const userId = canOrder2023Products ? userInfo?.uid : userData?.authenticatedId;
    const internalUserId = userData?.userId;

    const { reservationProducts } = useSelector((store) => store?.commerce) || [];
    const { reservationPrices } = useSelector((store) => store?.commerce);
    const { reservation } = useSelector((store) => store?.commerce);
    const { reservationData } = useSelector((store) => store?.commerce);

    const [PONumberValue, setPONumberValue] = useState("");
    const [deliverySchedule, setDeliverySchedule] = useState('');

    const alertRef = useRef(null);
    const { globalErrorMessage = [] } = useSelector(store => store?.globalMessages);
    const generalApiErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "GENERAL_API_ERROR");
    const generalApiError = generalApiErrorData && generalApiErrorData[0]?.fields?.message;
    const [alertMsg, setAlertMsg] = useState({ type:'info', text:'' });
    const [billingAddress, setBillingAddress] = useState("selectAnAddress");
    const [openAddressModal, setOpenAddressModal] = useState(false);
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
    const [confirmationModalContentType, setConfirmationModalContentType] = useState('')
    const [totalDosesQty, setTotalDoses] = useState(0);
    const [showNewAddressFormModal, setShowNewAddressFormModal] = useState(false);
    const [showUserAddressFormModal, setShowUserAddressFormModal] = useState(false);
    const disableContinue = totalDosesQty === 0 || !billingAddress || billingAddress === "new-address" ||  billingAddress === "selectAnAddress"

    const { reservationId } = useParams();
    const isEditing = !!reservationId;
    const [refreshReservationData, setRefreshReservationData] = useState(false);
    const selectedContract = userData?.selectedContract;

    const getMedicalIdentifier = () => {
        let medicalIdentifier = null;
        medicalIdentifier = selectedContract?.medicalIdentifier;

        if (!medicalIdentifier) {
            medicalIdentifier = userData?.licenses?.find(license => license.issuer === "HIN" || license.issuer === "DEA")?.number;
        }
        return medicalIdentifier || 'anonymous';
    }

    const createPricingRequestPayload = (reservationProducts) => {
        let productNumbers = reservationProducts?.map(product => product.code);

        return {
            "productNumbers": productNumbers
        };
    };

    const updateDosesRoundedUpMsg = (val) => {
        return HandlerBarTemplateHandler({enteredDoses: val}, reservationPageContent?.dosesRoundedUpMessage?.content[0])
    }
    // Retrieve Contentful data
    useEffect(() => {
        if (reservationPageContent && Object.keys(reservationPageContent).length === 0){
            dispatch(loadReservationPage());
        }
    }, [reservationPageContent, dispatch]);

    // Retrieve reservation data from APIs
    useEffect(() => {
        const createReservations = async () => {
            try {
                setProcessing(true);
                if (isAuthenticated) {
                    if (isEditReservationEnabled && isEditing) {
                        await dispatch(getReservationById({ userId: userId, reservationId: reservationId })).unwrap();
                    }
                    else {
                        await dispatch(createReservationApi({ userId: userId, userEmail: userInfo?.email })).unwrap();
                    }
                }
                const reservationProducts = await dispatch(getReservationProductsApi({ catalogVersion: 'modernaProductCatalog' })).unwrap();
                try {
                    if (isRealTimePricingEnabled) {
                        await dispatch(getPricingApi({medicalIdentifier: getMedicalIdentifier(), payload: createPricingRequestPayload(reservationProducts)})).unwrap()
                    } else {
                        await dispatch(createPricingApi({})).unwrap();
                    }
                } catch (error) {
                    const apiErrorMessage = error?.message || generalApiError;
                    setAlertMsg({ type: 'error', text: apiErrorMessage });
                    alertRef.current?.openAlert('');
                }
            } catch (error) {
                setAlertMsg({ type: 'error', text: error?.message || generalApiError })
                alertRef.current?.openAlert('');
            } finally {
                setProcessing(false);
            }
        };
    
        createReservations();
    }, [dispatch, isAuthenticated, userInfo?.uid]);

    useEffect(() => {
        // if there is reservation data, map through reservation Entries,
        // else map through products to create default product info
        if (reservation?.reservationEntries?.length) {
            let reservationEntries = reservation?.reservationEntries?.map(reservationEntry => {
                return {
                    reservationEntryId: reservationEntry.reservationEntryId,
                    deliveryAddressId: reservationEntry.deliveryAddressId,
                    productId: reservationEntry.productId,
                    quantity: reservationEntry.quantity,
                }
            });
            dispatch(setReservationData({...reservation, reservationEntries}));
        } else {
            let reservationEntries = reservationProducts?.length && reservationProducts?.map(product => {
                let addressId = reservation?.length && reservation?.reservationEntries[0]?.deliveryAddressId
                if (addressId === undefined || addressId === null && shipToAddresses.length) {
                    addressId = shipToAddresses[0]?.id;
                }

                const uniqueSuffix = Math.random().toString(36).substr(2, 9);
                const reservationEntryId = `${product.code}-${uniqueSuffix}`;

                return {
                    reservationEntryId: reservationEntryId,
                    reservationId: reservation?.reservation?.reservationId,
                    deliveryAddressId: addressId,
                    productId: product?.code,
                    quantity: 0
                }
            });
            dispatch(setReservationData({...reservation, reservationEntries: reservationEntries}));
        }
        setPONumberValue(reservation?.reservation?.purchaseOrderNumber || "")
        setBillingAddress(reservation?.reservation?.billingAddressInformation?.addressId || "selectAnAddress")
        setDeliverySchedule(reservation?.reservation?.deliverySchedule || "INITIAL")
        setRefreshReservationData(false);
    }, [reservationProducts, refreshReservationData])
    
    /** Get all ship to addresses and find default */
    const shipToAddresses = useMemo(() => {
        if(isOnboardingEnabled){
            const addresses = userData?.addresses || [];
            return addresses
                .filter(address => address.addressType === "ship_to")
                .map(address => ({
                    ...address,
                    defaultAddress: address.id === defaultAddress?.id,
                }))
                .sort(sortAddresses);
        }
        else{
            const addresses = commerceUser?.data?.orgUnit?.addresses || [];
            return addresses
                .filter(address => address.partnerFunction === "SHIP_TO")
                .map(address => ({
                    ...address,
                    defaultAddress: address.uid === defaultAddress?.uid,
                }))
                .sort(sortAddresses);
        }
    }, [commerceUser, defaultAddress]);

    const pricesByPriceReference = reservationPrices?.pricesByPriceReference?.[0];    
    const getPrices = () => {
        return pricingData?.pricesByPriceReference?.find(price =>
            selectedContract ? price?.priceReferenceId === selectedContract?.id : price?.priceReferenceType === CONTRACT_TYPES.CUSTOMER_PRICE_LIST
        );
    };    

    const allPrices = isRealTimePricingEnabled ? getPrices() : pricesByPriceReference;
    const priceReferenceId = allPrices?.priceReferenceId;
    const priceDisplayName = allPrices?.displayName;
    let productPricingMap = allPrices?.productPrices.reduce((all, productPrice) => {
        return { 
            ...all, 
            [productPrice?.productId]: {
                listPrice: productPrice?.listPriceFormatted,
                contractPrice: isRealTimePricingEnabled ? productPrice?.finalPriceFormatted : 'TBD'
            } 
        }
    }, {}) || {};

    /** Get names from ProductAPI. @type {{item.code : productName}} | {} */
    const productNameMap = reservationProducts?.length && reservationProducts?.reduce((all, item) => {
        return { ...all, [item.code]: `${item.description}, ${item.ageGroup}` }
    }, {}) || {};


    const productImageMap = reservationProducts?.length &&  reservationProducts?.reduce((all, item) => {
        // Find the image with format "product"
        const productImage = item.images.find(image => image.format === "product");
        if (productImage) {
            all[item.code] = productImage?.url;
        }
        return all;
    }, {});

    /** Array of API reservation entries to send to confirm API or update reservation API */
    const reservationEntriesModel = reservationData?.reservationEntries || [];

    // Helper function to create a display entry for a reservation details card
    const createDisplayEntry = (reservationEntry) => {
        if (!reservationEntry) return null;

        const { productId, deliveryAddressId } = reservationEntry;
        const pricing = productPricingMap[productId] || {};
        const address = shipToAddresses?.find(({ id }) => String(id) === String(deliveryAddressId));

        return {
          id: reservationEntry.reservationEntryId,
          doses: parseInt(reservationEntry?.quantity, 10) || 0,
          productId,
          productName: productNameMap && productNameMap[productId],
          listPriceValue: pricing?.listPrice,
          listPrice: formattedPrice(pricing?.listPrice),
          contractPriceValue: pricing?.contractPrice,
          contractPrice: isRealTimePricingEnabled ? formattedPrice(pricing?.contractPrice) : 'TBD',
          address,
          imageUrl: productImageMap && productImageMap[productId],
        };
      };
    // Map reservation entries to display entries and filter out undefined entries
    const reservationEntriesDisplay = reservationEntriesModel
        .map(createDisplayEntry)
        .filter(entry => entry !== null);

    // Helper function to determine if an addressId is unique within the array
    const isUniqueAddressId = (addressId, index, self) => {
        return self.findIndex(entry => entry.deliveryAddressId === addressId) === index;
      };
    // Get all unique address IDs
    const reservationAddressIds = reservationEntriesModel
        .filter(({ deliveryAddressId }, index, self) => deliveryAddressId && isUniqueAddressId(deliveryAddressId, index, self))
        .map(({ deliveryAddressId }) => deliveryAddressId);


    const handleDosesChange = (reservationEntryId, doses) => {
        // Find the index of the reservation entry by reservationEntryId
        const reservationEntryIndex = reservationEntriesModel.findIndex(entry => entry.reservationEntryId === reservationEntryId);
        
        // If the entry is not found, exit the function
        if (reservationEntryIndex === -1) return;
        
        // Retrieve the entry to be updated
        const entryToUpdate = reservationEntriesModel[reservationEntryIndex];

        // If it's the entry we want to update, return a new object with the updated doses
        const roundOffQty = (Math.ceil(parseInt(doses) / 10)) * 10;
        if (roundOffQty !== 0 && doses !== roundOffQty) {
            setAlertMsg({type: 'info', text: updateDosesRoundedUpMsg(roundOffQty)})
            alertRef.current?.openAlert('');

        }
    
        // Create a new entry with the updated doses
        const updatedEntry = {
            ...entryToUpdate,
            quantity: roundOffQty
        };

        // Create a new array with the updated entry
        const updatedReservationEntries = [
            ...reservationEntriesModel.slice(0, reservationEntryIndex),
            updatedEntry,
            ...reservationEntriesModel.slice(reservationEntryIndex + 1)
        ];

        const { totalDoses } = reservationPricingInfo(updatedReservationEntries)
        setTotalDoses(totalDoses);

        // Dispatch the new state
        dispatch(setReservationData({
            ...reservationData,
            reservationEntries: updatedReservationEntries
        }));
    };

    const handleDeleteAddressRow =  (reservationAddressId) => {
        const updatedReservationEntries = reservationData?.reservationEntries?.filter((entry) => {
            return entry.deliveryAddressId !== reservationAddressId

        });
        const { totalDoses } = reservationPricingInfo(updatedReservationEntries)
        setTotalDoses(totalDoses);
        dispatch(setReservationData({ reservationEntries: updatedReservationEntries }));
    }

    const MODALS = { ADDRESS_SELECTION: 'addressSelection',
        ADDRESS_FORM: 'addressForm',
        USER_ADDRESS_FORM: 'userAddressForm',
        CONFIRMATION: 'confirmation' }

    const closeModal = (modalName) => {
        modalName === MODALS.ADDRESS_SELECTION && setOpenAddressModal(false);
        modalName === MODALS.ADDRESS_FORM && setShowNewAddressFormModal(false);
        modalName === MODALS.USER_ADDRESS_FORM && setShowUserAddressFormModal(false);
        modalName === MODALS.CONFIRMATION && setOpenConfirmationModal(false);
    }
    const handleAddNewAddress = () => {
        closeModal(MODALS.ADDRESS_SELECTION);
        if (isOnboardingEnabled) {
            setShowUserAddressFormModal(true);
        } else {
            setShowNewAddressFormModal(true);
        }
    }

    const handleDiscardChange = () => {
        dispatch(setReservationData({ reservation: {}, reservationEntries: [] }))
        setRefreshReservationData(true)
        setOpenConfirmationModal(false)
    }

    const handleCancelReservation = () => {
        if (isNewReservationUrlsEnabled) {
            dispatch(cancelReservation({ userId: userInfo?.uid, reservationId: reservation?.reservation?.reservationId })).unwrap()
            .then(() => {
                const reservationNumber = reservationData?.reservation?.reservationNumber;
                dispatch(setIsConfirmationPageAccessible(true));
                history.push(`/${locale}/confirmation/${reservationNumber}`);
            })
            .catch((error) => {
                setOpenConfirmationModal(false);
                setAlertMsg({ type: 'error', text: generalApiError })
                alertRef.current?.openAlert('');
            })
        } else {
            cancelReservationApi(userInfo?.uid, reservationId)
            .then(() => {
                const reservationNumber = reservationData?.reservation?.reservationNumber;
                dispatch(setIsConfirmationPageAccessible(true));
                history.push(`/${locale}/confirmation/${reservationNumber}`);
            })
            .catch((error) => {
                setOpenConfirmationModal(false);
                setAlertMsg({ type: 'error', text: error?.message || generalApiError })
                alertRef.current?.openAlert('');
            })
        }
    }

    /** Reservation card components. */
    const getReservationDetailCards = reservationAddressIds.map((reservationAddressId, i) => {
        const reservationEntriesForThisAddress = reservationEntriesDisplay?.filter(({address}) => String(address?.id) === String(reservationAddressId));
        sortArrayByField(reservationEntriesForThisAddress, ORDERED_PRODUCT_BY_ID_OR_CODE, 'productId');

        return <ReservationDetailsCard
                    key={reservationAddressId}
                    entries={reservationEntriesForThisAddress} 
                    handleDosesChange={handleDosesChange}
                    deleteAddressRow={() => handleDeleteAddressRow(reservationAddressId)}
                    updateDosesQty={(count) => setTotalDoses(count)}
                />
    })

    /** Building out products to be displayed for unauthenticated users*/
    const unAuthedProducts = reservationProducts?.length &&  reservationProducts?.map((product, id) => {
        return {
            id,
            doses: 0,
            productId: product?.code,
            productName: productNameMap[product?.code],
            imageUrl: productImageMap[product?.code]
        }
    }) || []

    /** Unauthenticated reservation details card component */
    const getUnAuthenticatedReservationDetailCards = 
        <ReservationDetailsCard entries={unAuthedProducts}/>

    const reservationPricingInfo = (data) => {
        return data.reduce(({totalDoses, totalPrice}, product) => {
            const pricePerDose = (isRealTimePricingEnabled && product.contractPriceValue !== undefined) ? product.contractPriceValue : product.listPriceValue;
            return {
                totalDoses: totalDoses + product.doses,
                totalPrice: totalPrice + product.doses * pricePerDose
            };
        }, {totalDoses: 0, totalPrice: 0})
    }

    const handleContinue = async (isEditing = false) => {
        const {totalDoses, totalPrice} = reservationPricingInfo(reservationEntriesDisplay)

        // additional details needed for update API
        const reservationDetails = {
            userId: userInfo?.uid,
            medicalIdentifier: getMedicalIdentifier(),
            reservation: {
                ...reservationData.reservation,
                priceReferenceIdentifier: priceReferenceId,
                deliverySchedule: deliverySchedule,
                purchaseOrderNumber: PONumberValue,
                billingAddressInformation: {
                    addressId: billingAddress,
                },
                reservationPricingInfo: {
                    totalPrice,
                    totalDoses,
                },
                ...(isRealTimePricingEnabled ? {
                    internalUserId: internalUserId,
                    displayName: priceDisplayName
                } : {})
            }
        };

        try {
            setProcessing(true);
            dispatch(setReservationData(reservationDetails));
            await dispatch(updateReservationApi({
                payload: {
                    ...reservationDetails,
                    reservationEntries: reservationData.reservationEntries
                }
            }));
        } catch (error) {
            setProcessing(false);
            setAlertMsg({ type: 'error', text: error?.message || generalApiError })
            alertRef.current?.openAlert('')
        } finally {
            if (isEditing) history.push(`/${locale}/summary/${reservationId}`);
            else history.push(`/${locale}/summary`);
        }
    }

    const handleCancelOrDiscard = (eventType) => {
        setConfirmationModalContentType(eventType)
        setOpenConfirmationModal(true)
    }

    const getAllSelectedAddress = () => {
        return reservationAddressIds.map((reservationAddressId, i) => {
            return reservationEntriesDisplay?.filter(({address}) => String(address?.id) === String(reservationAddressId))[0]?.address
        });
    }

    return (
        isReservationEnabled &&
        <>
            {!processing && <AlertMessage data-testid='errorAlert' variant={"filled"} type={alertMsg.type} message={alertMsg.text} sx={{ top: 120}} ref={alertRef} />}
            <Spinner processing={processing} />
            {openAddressModal && <AddressSelectionModal openModal={openAddressModal} closeModal={() => closeModal(MODALS.ADDRESS_SELECTION)} allSelectedAddresses={getAllSelectedAddress()} 
                handleAddNewAddress={handleAddNewAddress} isOrdering={false}
            />
            }
            {isOnboardingEnabled && showUserAddressFormModal &&
              <UserAddressFormModal
                openModal={showUserAddressFormModal}
                closeModal={() => closeModal(MODALS.USER_ADDRESS_FORM)}
                addressType={"ship_to"}
              />
            }
            {!isOnboardingEnabled && showNewAddressFormModal &&
              <NewAddressFormModal
                openModal={showNewAddressFormModal}
                closeModal={() => closeModal(MODALS.ADDRESS_FORM)}
              />
            }
            {openConfirmationModal &&
                <ConfirmationModal
                    openModal={openConfirmationModal}
                    closeModal={() => closeModal(MODALS.CONFIRMATION)}
                    contentType={confirmationModalContentType}
                    handleConfirm={confirmationModalContentType === 'cancel' ? handleCancelReservation : handleDiscardChange}
                />
            }
            <Box display="flex" flexDirection="column" m={4}>
                <Typography variant="h1" style={{ marginBottom: '16px'}}>{reservationPageContent?.headerLabel}</Typography>
                <Typography variant="h3" style={{ fontFamily: 'Aeonik Regular', fontWeight: 500}}>{reservationPageContent?.headerDescriptionText} </Typography>
                {
                    isAuthenticated && <GroupAffiliationComponent
                        isAuthenticated={isAuthenticated}
                        reservationPageContent={reservationPageContent}
                    />
                }
                { isAuthenticated ?
                    getReservationDetailCards : getUnAuthenticatedReservationDetailCards 
                }
                <Box flex={1} minWidth={'300px'} mb={3} mt={5}>
                    <Button
                        buttonType={'mds-secondary'}
                        data-testid='deliverToAddressBtn'
                        muiIconLeft={AddCircleOutlineIcon}
                        disabled={!isAuthenticated}
                        onClick={() => {setOpenAddressModal(true)}}
                    >
                        Deliver to additional address
                    </Button>
                </Box>
                <Box display="flex" flexDirection="row" flexGrow={1} gap={3} flexWrap={'wrap'} alignItems={'flex-start'} minHeight="350px">
                    <Box flexGrow={1} width="50%" flexBasis={'350px'} style={{height: '385px'}}>
                        <CustomCard
                            title="Order details"
                            sx={{padding: "16px"}}
                        >
                            <OrderDetailsComponent
                                PONumberValue={PONumberValue}
                                setPONumberValue={setPONumberValue}
                                billingAddress={billingAddress}
                                setBillingAddress={setBillingAddress}
                                handleBillingAddressChange={setBillingAddress}
                                userId={userInfo?.uid}
                                />
                        </CustomCard>
                    </Box>

                    <Box flexGrow={1} width="50%" flexBasis={'350px'} style={{height: '385px'}}>
                        <CustomCard
                            title="Delivery schedule"
                            sx={{padding: "16px 32px"}}
                        >
                            <DeliverScheduleComponent
                                reservationPageContent={reservationPageContent}
                                deliverySchedule={deliverySchedule}
                                setDeliverySchedule={setDeliverySchedule}
                                isAuthenticated={isAuthenticated}/>
                        </CustomCard>
                    </Box>
                </Box>
                {
                    isAuthenticated && !isEditing &&
                    <Box display="flex" justifyContent="center" mt={4}>
                        <Button
                            data-testid='reservationContinueBtn'
                            buttonType={'mds-primary'}
                            muiIconRight={ArrowForwardIcon}
                            disabled={disableContinue}
                            onClick={() => handleContinue()}
                        >
                            Continue
                        </Button>
                    </Box>
                }
                {
                    isEditReservationEnabled && isEditing &&
                    <Box display="flex" justifyContent="space-between" alignItems="center" mt={4}>
                        <Button buttonType={'secondary'} onClick={() => {handleCancelOrDiscard('cancel')}}>
                            Cancel Reservation
                        </Button>
                        <Box display="flex" alignItems="center">
                            <Box mr={3}>
                                <Button
                                    buttonType={'mds-secondary'}
                                    data-testid='discardChangeBtn'
                                    onClick={() => {handleCancelOrDiscard('discard')}}
                                >
                                    Discard Changes
                                </Button>
                            </Box>
                            <Button
                                data-testid='saveEditChangeBtn'
                                buttonType={'mds-primary'}
                                onClick={() => handleContinue(isEditing)}
                            >
                                Save Changes
                            </Button>
                        </Box>
                    </Box>
                }
            </Box>
        </>
    )
}

export default ReservationPage
