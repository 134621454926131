
import { ModalComponent } from "../ModalComponent";
import ShippingAddressForm from "../Onboarding/ShippingAddressAndLicense/ShippingAddressForm";

export const UserAddressFormModal = ({openModal, closeModal, handleNewAddresses}) => {
    return (
        <ModalComponent 
            openModal={openModal}
            closeModalHandler={closeModal} 
            modalContent={
                <ShippingAddressForm
                    closeModal={closeModal}
                    handleNewAddresses={handleNewAddresses}
                />}
            style={{
                width: '50%',
                '#titleContainer':{
                    marginBottom: '0px',
                    padding: '5px'
                },
            }}
        />
    )
}
