import React, { useState , useRef , useEffect} from "react";
import IconButton from "@mui/material/IconButton";
import { styled } from '@mui/material/styles';
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import MenuIcon from '@mui/icons-material/Menu';
import ProfileIcon from "../../assets/images/profile.svg";
import { Button as SignoutButton } from "../Button";
import { useOktaAuth } from '@okta/okta-react';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { SessionHeader } from "../SessionHeader";
import { useSelector } from "react-redux";
import { Avatar, Badge, Box, Typography, useTheme } from "@mui/material";
import {useTracking} from "@modernatx/tracking";
import germanyFlag from '../../assets/images/germany-flag.svg';
import usaFlag from '../../assets/images/usa-flag.svg';
import cart  from "../../assets/images/cart.svg";

const LinkContainer = styled('div')(
  ({ linksCount, theme: { palette: { primary }, breakpoints } }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${primary.main};
    gap: 30px;
    ${[breakpoints.down('sm')]} {
      display: grid;
      grid-template-columns:1fr;
      justify-content: flex-start;
    }
  `
);

const StyledBox = styled(Box)(({ theme: { breakpoints } }) => `
  padding-right: 30px;
  cursor: pointer;
  ${[breakpoints.down('sm')]} {
    position: relative;
  }
`);

const Menu = styled('ul')(
  ({ show, theme: { palette: { primary, neutral, secondary }, breakpoints }}) => `
  position: absolute;
  top: 90px;
  right: 80px;
  background-color: ${secondary.main};
  border-top: 3px solid ${primary.blue};
  border-radius: 0px 0px 16px 16px;
  width: 230px;
  display: ${show ? "block" : "none"};
  box-shadow: 0px 3px 16px #00000029;
  padding: 0 15px;
  z-index: 10000000;
  ${[breakpoints.down('sm')]} {
    background-color: ${neutral.white};
    border-top:0px;
    top:80px;
    position:fixed;
    padding-bottom:80px;
    left:0px;
    width:100vw;
    height:100vh;
    display: ${show ? "flex" : "none"};
    flex-direction:column;
    flex-wrap:wrap;
    justify-content:space-between;
  } 
`)

const ActionsContainer = styled('div')(
  ({ openSessionHeader, theme: { palette: { neutral }, breakpoints }}) => `
  margin-top: ${localStorage.getItem("employeeUser") && localStorage.getItem("employeeUser") !== "undefined" ? (openSessionHeader ? '118px' : '87px') : '0px' };
  height: 90px;
  padding: 20px 72px;
  position: relative;
  z-index: 999;
  display: grid;
  gap: 8px;
  grid-template-columns: auto 1fr auto auto;
  align-items: center;
  background-color: ${neutral.white};
  box-shadow: 0px 3px 16px #00000029;
  @media(max-width: 620px){
    margin-top:0px;
    padding: 20px 20px;
  }
  ${[breakpoints.down('sm')]} {
    margin-top: ${localStorage.getItem("employeeUser") && localStorage.getItem("employeeUser") !== "undefined" ? (openSessionHeader ? '207px' : '73px') : '0px' };
    height:80px;
    padding: 0px;
    position: fixed;
    grid-template-columns: auto auto;
    z-index: 1000000;
    width: 100vw;
    justify-content: space-between;
    padding: 15px;
  }
  ${[breakpoints.down('lg')]} {
    padding: 20px 32px;
  }
  `
);

const CustomBadge = styled(Badge)(({ theme }) => ({
    color: theme.palette.neutral.white,
    '& .MuiBadge-badge': {
      right: 3,
      top: 8,
      backgroundColor: theme.palette.primary.button,
      color: theme.palette.neutral.white
    }
  }));

const Header = ({ role, headerData, baseStoreData }) => {
  const [showMenu, setShowMenu] = useState(false);
  const { oktaAuth } = useOktaAuth() || '';
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const openSessionHeader = useSelector((state) => state.globalStates.sessionHeaderOpen);
  const locale = useSelector((state) => state.globalStates.locale);
  const { orderCartData } = useSelector((store) => store.commerce);
  const isNewHomepageEnabled = process.env.IS_NEW_HOMEPAGE_ENABLED && locale === 'en-US';
  
  const ref = useRef()
  const { pathname: activeRoute } = useLocation();

  const { setUserId } = useTracking();

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (showMenu && ref.current && !ref.current.contains(e.target)) {
        setShowMenu(false)
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [showMenu])
  
  const wordCount = role?.match(/(\w+)/g).length;
  let roleContent;
  if (wordCount > 1) {
    roleContent = role?.match(/\b(\w)/g).join("");
  } else {
    roleContent = role?.charAt(0) + role?.slice(-1);
  }

  const logOut = async() => {
    localStorage.clear(); //for localStorage
    sessionStorage.clear(); //for sessionStorage
    setUserId(null);
    await oktaAuth.signOut();
  };

  const toggleMenu = () => setShowMenu(prev => !prev)

  return (
    <>
    {localStorage.getItem("employeeUser") && localStorage.getItem("employeeUser") !== "undefined" &&
    <SessionHeader/>}
    <ActionsContainer openSessionHeader={openSessionHeader}>
      <Link to={`/${locale}/home`} data-testid="logoLink">
        <StyledBox sx={{cursor:'pointer'}}>
          {mobile ?
          <img
            id="headerLogo"
            src={headerData?.logo?.fields.file?.url}
            alt="Moderna Logo"
            width="164px"
            onClick={() => {setShowMenu(false)}}
          />
          :
          <img
            id="headerLogo"
            src={headerData?.logo?.fields.file?.url}
            alt="Moderna Logo"
            width="158px"
          />}
        </StyledBox>
      </Link>
     
      {desktop &&
      <div style={{position: "relative",display:'flex',alignItems:"center",justifyContent:"center"}}>
        <LinkContainer linksCount={headerData?.navigationItems?.length}>
          {headerData?.navigationItems && headerData?.navigationItems.map((item, index) => {
            return (
              ((item?.fields?.title !== "Reservations" && baseStoreData?.commerceEnabled) || ( item?.fields?.title === "Reservations" && !baseStoreData?.commerceEnabled )) && 
              <Box className={`${activeRoute.includes(item?.fields?.link?.fields?.pageReference?.fields?.slug) ? 'active' : ''}`} 
                sx={{ borderBottomColor: `${theme.palette.neutral.white} !important`, borderBottom: '3px solid', '&.active': {borderBottomColor: `${theme.palette.primary.button} !important`,borderBottom: '3px solid'}}} key={index}>
                <Link to={`/${locale}/${item?.fields?.link?.fields?.pageReference?.fields?.slug}`} id={`headerLink-${index}`}>
                { isNewHomepageEnabled === 'true' ? 
                  <Typography fontSize='18px' fontWeight='500' fontStyle='normal' fontFamily='Aeonik Bold' color="primary.main" lineHeight="24px">{item?.fields?.label}</Typography> 
                  :
                  <Typography variant={'h5'} color='primary.main' lineHeight={'24px'} display='flex'>{item?.fields?.label?.toUpperCase()}</Typography> 
                }
                </Link>
              </Box>
            );
          })}
        </LinkContainer>
      </div>}
      
      <Box display={'flex'} alignItems="center" gap={2}>
        {desktop && <Avatar sx={{height:"32px", width:"32px"}}>
        {locale === 'en-DE' ? 
          <Box component={'img'}  sx={{objectFit: 'cover'}}  alt='germany' height="32px" width="32px" src={germanyFlag}/> :
          <Box component={'img'}  sx={{objectFit: 'cover'}}  alt='usa' height="32px" width="32px" src={usaFlag}/>
        }      
        </Avatar>}
        {desktop && baseStoreData?.commerceEnabled && <Link to={orderCartData && orderCartData?.totalItems > 0 ? "/cart" : '#'} style={{ pointerEvents: (Object.keys(orderCartData).length === 0 || orderCartData?.totalItems === 0) && 'none' }}>
          <IconButton aria-label={'notificationsLabel'} disableRipple>
            <CustomBadge anchorOrigin={{vertical: 'top',horizontal: 'right'}} badgeContent={orderCartData?.totalItems} invisible={false}>
              <Box component={'img'} src={cart} style={{width:40,height:40}}/>
            </CustomBadge>
          </IconButton>
        </Link>}
        {desktop && <Box component={'div'} sx={{border:"1px solid", borderColor:'neutral.gray6', height:45}}></Box>}
        <Box display='flex' alignItems='center' gap='10px' ref={ref}>
        { mobile && baseStoreData?.commerceEnabled && 
        <Link to={orderCartData?.totalItems > 0 && "/cart"}>
          <IconButton aria-label={'notificationsLabel'} disableRipple>
            <CustomBadge anchorOrigin={{vertical: 'top',horizontal: 'right'}} badgeContent={orderCartData?.totalItems} invisible={false}>
              <Box component={'img'} src={cart} width={{xs: 30, sm:40}} height={{xs: 30, sm:40}}/>
            </CustomBadge>
          </IconButton>
        </Link>}
        {mobile ?
        <IconButton
          id="profileMenu"
          aria-label="user account"
          onClick={toggleMenu}
          sx={{ padding: 0, color:"primary.blue" }}>
          <MenuIcon sx={{height:30, width:30}} fontSize="small"/>
        </IconButton> :
        isNewHomepageEnabled === 'true' ? 
          (<Box display="flex" alignItems="center" onClick={toggleMenu} style={{ cursor: 'pointer' }}>
            <img src={ProfileIcon} style={{ padding: 5 }} height="34" width="34" alt="user profile" />
            <Typography fontSize={'16px'} fontFamily={'Aeonik Regular'} color="primary.main">
              Your account
            </Typography>
          </Box>) 
          :
          (
            <IconButton
            id="profileMenu"
            aria-label="user account"
            onClick={toggleMenu}
            sx={{ padding: 0, color:"primary.blue" }}>
            <Avatar sx={{ fontFamily: 'Aeonik Bold', fontSize:'16px', backgroundColor: 'neutral.white', color: 'primary.blue', border: '2px solid', borderColor:'primary.main', '&:hover': {backgroundColor: 'secondary.main'} }}>{roleContent ? roleContent : 'GT'}</Avatar>
            </IconButton>
          )
        }
        {<Menu show={showMenu}>
          {mobile &&
          <LinkContainer linksCount={headerData?.navigationItems?.length}>
            {headerData?.navigationItems && headerData?.navigationItems.map((item, index) => {
            return (
              (item?.fields?.title !== "Reservations" || ( item?.fields?.title === "Reservations" && !baseStoreData?.commerceEnabled )) && <div key={index}>
                {index === 0 ? <div> </div> : <div style={{borderTop:"1px solid #c0c0c0"}}></div>}
                <Link style={{display:'flex', justifyContent:"space-between", paddingTop: '20px'}} id={`headerLink-${index}`} to={`/${locale}/${item?.fields?.link?.fields?.pageReference?.fields?.slug}`} onClick={toggleMenu} key={index}>
                  <Typography display='flex' variant={'h5'} color='primary.main'>{item?.fields?.label}</Typography>
                  <ArrowForwardIosIcon sx={{color:"primary.blue"}}/>
                </Link>
              </div>);
            })}
          </LinkContainer>
          }
          <div>
            <Box display='flex' alignItems="center" gap="20px">
            { mobile && locale === 'en-DE' ?
              <Avatar sx={{height:"32px", width:"32px"}}><Box component={'img'} sx={{objectFit: 'cover'}} alt='germany'  height="32px" width="32px" src={germanyFlag} title="Germany"/></Avatar> : 
              mobile && <Avatar sx={{height:"32px", width:"32px"}}><Box component={'img'} sx={{objectFit: 'cover', height:'32px',width:'32px'}} alt='usa' src={usaFlag} title="USA"/></Avatar> }
              {mobile && <Typography variant={'p1'}>{locale === 'en-DE' ? 'Germany' :'US'}</Typography>}
            </Box>
            {locale === 'en-US' && <Box component={'li'} py={'15px'}  borderBottom={{xs: `1px solid #c0c0c0`, sm: '0'}} display='flex' gap={1} color={'neutral.main'} id="profileCard">
              <Link style={{display:'flex', justifyContent:'space-between'}} to={`/${locale}/my-profile`} onClick={toggleMenu} data-testid="profileOption">
                <img src={ProfileIcon} style={{padding:5}} height="34" width="34" alt="user profile" />
                <Typography variant={'p1'} color='primary.main' display={'flex'} alignItems={'center'} ml={1}>Profile</Typography>
              </Link>
            </Box>}
            {!localStorage.getItem("employeeUser") && localStorage.getItem("employeeUser") !== "undefined" &&
            <Box display='flex' justifyContent='center' alignitems='center' mt='40px' mb={{xs:'60px', sm:'30px'}}>
              <SignoutButton id="signOutBtn" onClick={logOut} data-testid="signOutButton">
                SIGN OUT
              </SignoutButton>
            </Box>}
          </div>
        </Menu>
        }
        </Box>
      </Box>
    </ActionsContainer>
    </>
  )
};

export { Header as AuthHeader };

Header.propTypes = {
  role: PropTypes.string,
  headerData: PropTypes.object,
};
