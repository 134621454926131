import React, {useEffect, useRef, useState} from "react";
import { Box } from "@mui/system";
import { Spinner } from "../components";
import { EmailVerificationSucceed } from "../components/Registration";
import LogoHeader from "../components/Header/LogoHeader";
import { useDispatch, useSelector } from "react-redux";
import { activateUserApi } from "../utils/IdentityService/EmailVerificationApi";

const RegistrationConfirmationPage = () => {
  const alertRef = useRef(null);
  const { globalErrorMessage = [] } = useSelector(store => store?.globalMessages);
  const generalApiErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "GENERAL_API_ERROR");
  const generalApiError = generalApiErrorData && generalApiErrorData[0]?.fields?.message;
  const [alertMsg, setAlertMsg] = useState({ type:'info', text:'' });

  const dispatch = useDispatch();
  const [processing, setProcessing] = useState(false);
  const [activateStatus, setActivateStatus] = useState('');

  const urlParams = new URLSearchParams(window.location.search);
  const activateToken = urlParams.get('activateToken');
  const email = urlParams.get('email');


  useEffect(() => {
    const activateUser = async () => {
      if (!activateToken || !email) return;

      try {
        setProcessing(true);
        await activateUserApi({emailAddress: email, activationToken: activateToken});
        setActivateStatus('succeed');
      } catch (error) {
        setAlertMsg({ type: 'error', text: generalApiError });
        alertRef.current?.openAlert('');
      } finally {
        setProcessing(false);
      }
    };

    activateUser();
  }, []);

  return (
    <>
      <Spinner processing={processing} />
      <Box
        sx={{ width: '100%', height: '100vh' }}
        display="flex"
        flexDirection='column'
        alignItems='center'
      >
        <Box maxWidth='640px' width={'60%'} >
          <LogoHeader />
        </Box>
        <Box maxWidth='640px' width={'60%'} data-testid='emailVerificationHeader'>
          { activateStatus === 'succeed' && <EmailVerificationSucceed /> }
          {/*TODO: we may need it in the future */}
          {/*{ activateStatus === 'expired' && <EmailVerificationTokenExpired /> }*/}
        </Box>
      </Box>
    </>
  );
};

export default RegistrationConfirmationPage;
