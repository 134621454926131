import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { useTheme } from '@mui/material';
import CardComponent from '../CardComponent';
import { useModal } from '../../hooks/useModal';

const isReservationsEnabled = process.env.IS_RESERVATIONS_ENABLED;

export const HomepageLeftCard = () => {
    const { authState } = useOktaAuth() || '';
    const isAuthenticated = authState?.isAuthenticated;
    const locale = useSelector((state) => state.globalStates.locale);
    const { homePage: homePageContent } = useSelector((store) => store?.contentful);
    const history = useHistory();
    const { handleOpenModal } = useModal();
    const theme = useTheme();

    const navigateOrLogin = (path) => {
        if (isAuthenticated) {
            history.push(`/${locale}/${path}`);
        } else {
            handleOpenModal(`/${locale}/${path}`);
        }
    };

    const buttonActions = useMemo(() => {
        return isReservationsEnabled === 'true'
            ? [{
                label: 'Order now',
                onClick: () => navigateOrLogin('products'),
              }]
            : 
              [{
                label: 'Order History',
                onClick: () => navigateOrLogin('orders'),
              }]
    }, [isAuthenticated, locale, isReservationsEnabled]);

    return (
        <CardComponent
            title={homePageContent?.leftCardTitle}
            description={homePageContent?.leftCardDescription}
            actions={buttonActions}
            sx={{
                width: '100%',
                height: isReservationsEnabled === 'true' ? '296px': '192px',
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    height: '100%',
                    paddingBottom: '20px'
                },
            }}
        />
    );
};
