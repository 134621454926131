import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useOktaAuth } from '@okta/okta-react';
import { Box, Typography, useTheme, CardMedia } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Button } from "../Button";
import { loadLandingStaticContent, loadHomePage } from "../../features/contentful/contentfulThunkApi";
import { useModal } from "../../hooks/useModal";

const imageStyles = {
    display: 'block',
    backgroundSize: 'contain',
    objectFit: 'contain',
    width: '100%',
    maxHeight: '221px',
    marginLeft: 'auto',
    marginRight: 'auto',
    flexShrink: 0,
};

export const Container = styled('div')(
    ({ bgImageUrl, isReservations, theme: { palette: { primary }, breakpoints } }) => `
    background-color: ${primary.main};
    font-family: 'Aeonik Regular', 'Arial';
    background-image: url(${bgImageUrl});
    background-size: contain;
    background-position: right;
    height: ${isReservations ? 'auto' : '258px'};
    background-repeat: no-repeat;
    ${breakpoints.down('xs')} {
        height: "auto";
        background-size: contain;
        background-position: center;
    }
    `
    
);

const ContentContainer = styled('div')(({ isReservations, theme: { breakpoints } }) => `
    display: flex;
    padding: 27px 64px 32px 64px;  
    flex-direction: row;
    ${breakpoints.down('sm')} {
        flex-direction: column; 
        padding: 10px 32px 10px 32px; 
        margin-top: 0; 
    }
`);

const ContentLeftWrapper = styled('div') (({ theme: { breakpoints } }) => `
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 1 60%;
    ${breakpoints.down('sm')} {
        flex-basis: 100%; 
        max-width: 100%; 
    }
`);

const ContentRightWrapper = styled('div') (({ theme: { breakpoints } }) =>`
    display: flex;
    margin-left: 24px;
    ${breakpoints.down('sm')} {
        max-width: 100%; 
        flex-basis: 100%;
        padding: 54px 0px;
        margin-left: 0;
    }
`);

const isReservationsEnabled = process.env.IS_RESERVATIONS_ENABLED === 'true';

export const HomepageTopCard = () => {
    const { authState } = useOktaAuth() || '';
    const isAuthenticated = authState?.isAuthenticated;
    const locale = useSelector((state) => state.globalStates.locale);
    const localeISOCode = useSelector((state) => state.globalStates.localeISOCode);
    const { landingPageContent } = useSelector((store) => store?.contentful);
    const { welcomeBanner: bannerContent } = landingPageContent;
    const { homePage: homePageContent } = useSelector((store) => store?.contentful);
    const dispatch = useDispatch();
    const history = useHistory();
    const { handleOpenModal } = useModal();
    const openLoginModal = (redirectPath) => {
        handleOpenModal(redirectPath);
    };

    const isEmployee = () => localStorage.getItem("employeeUser") && localStorage.getItem("employeeUser") !== "undefined";
    const contentPrefix = !isEmployee() && isReservationsEnabled ? 'top' : 'orderingTop';

    const topCardDescription = homePageContent?.[`${contentPrefix}CardDescription`];
    const topCardImage = homePageContent?.[`${contentPrefix}CardImage`]?.fields?.file?.url;
    const topCardTitle = homePageContent?.[`${contentPrefix}CardTitle`];
    const topCardButton = homePageContent?.[`${contentPrefix}CardButton`];

    useEffect(() => {
        if (!landingPageContent || Object.keys(landingPageContent).length === 0) {
            dispatch(loadLandingStaticContent({ 
                title2: 'Home Page Visualization Banner Component - ' + localeISOCode, 
                title3:  `Welcome Home News Component - ${localeISOCode}`,
            }));
        }
        if (!homePageContent || Object.keys(homePageContent)?.length === 0) {
            dispatch(loadHomePage());
        }
        window.scrollTo(0, 0);
    }, []);

    const handleClick = () => {
        if (isReservationsEnabled && !isEmployee()) {
            history.push(`/${locale}/reservation`);
            return;
        }
        if (isAuthenticated) {
            history.push(`/${locale}/products`);
        } else {
            openLoginModal(`/${locale}/products`);
        }
    };

    
    return (
        <div>
            <Container
                key={'topCard'}
                id='homepage-top-card'
                data-testid='homepage-top-card'
                bgImageUrl={bannerContent?.backgroundImage?.fields?.file?.url} 
                isReservations={isReservationsEnabled}
            >
            <ContentContainer isReservations={isReservationsEnabled}>
                <ContentLeftWrapper>
                    <Typography
                        key={'topCardTitle'}
                        id='homepage-top-title'
                        data-testid='homepage-top-title'
                        variant='h3' 
                        component={'h2'} 
                        color={'neutral.white'} 
                        fontSize={{ xs: '16px', sm: '16px', md: '20px', lg: '24px' }}
                        sx = {isReservationsEnabled ? 
                                        {lineHeight: '32px'} : 
                                        {fontSize: '30px', lineHeight: '36px',
                                    }}
                    >
                        {topCardTitle}
                    </Typography>
                    <br/>
                    {
                        topCardDescription?.map((line, index) => (
                            <Typography
                                key={`topCardDesc${index}`}
                                id={`homepage-top-desc-${index}`}
                                data-testid={`homepage-top-desc-${index}`}
                                variant='p3'
                                mb={{ xs: '8px', sm: '8px' }}
                                color={'neutral.white'}
                                fontSize={{ xs: '14px', sm: '14px', md: '14px', lg: '20px' }}
                                sx = {isReservationsEnabled ? 
                                        {fontSize: {
                                            xs: '14px',
                                            sm: '14px', 
                                            md: '14px', 
                                            lg: '18px', 
                                          }} : {}}
                            >
                                {line}
                            </Typography>
                        ))
                    }
                    <Button 
                        buttonType="mds-primary" 
                        sx={{textTransform: 'none', marginTop: '16px', verticalAlign: 'end'}} 
                        onClick={handleClick}>
                            {topCardButton}
                    </Button>
                </ContentLeftWrapper>
                <ContentRightWrapper>
                    {topCardImage && <CardMedia
                        component="img"
                        image={topCardImage}
                        alt="What to expect"
                        sx={imageStyles}
                    />}
                </ContentRightWrapper>
            </ContentContainer>
            </Container>
            <br></br>
        </div>
    );
};
