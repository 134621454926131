import { useState } from 'react';
import { Box, Button, Grid, Typography, Tooltip } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CopySimple from "../../assets/images/CopySimple.svg";


const BANK_NAME = 'Bank of America';
const ACCOUNT_NAME = 'Moderna US, Inc.';
const ROUTING_NUMBER_ACH = '011000138';
const ROUTING_NUMBER_WIRE = '02009593';
const ACCOUNT_NUMBER= '466001423356';

const CheckAndWirePayment = () => {
    const [copiedText, setCopiedText] = useState('');

    const handleCopy = (text) => {
        setCopiedText(text);
        setTimeout(() => setCopiedText(''), 5000);
    };

    const labelStyle = {
        whiteSpace: 'nowrap',
    };
    
    const valueStyle = {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'nowrap',
    };

    const CopyableText = ({ text, copiedText, handleCopy, label }) => (
        <Grid item xs={2}>
            <CopyToClipboard text={text} onCopy={() => handleCopy(text)}>
                <Tooltip title={copiedText === text ? "Copied to clipboard" : "Copy"}>
                    <Button size="small" data-testid={`copy-button-${label}`}>
                        <img src={CopySimple} alt='copy icon' className='CopyIcon' height='20' width='20'/>
                    </Button>
                </Tooltip>
            </CopyToClipboard>
        </Grid>
    );

    const LabelValue = ({ label, value, copiedText, handleCopy }) => (
        <>
            <Grid item xs={6}>
                <Typography data-testid={`label-${label}`} fontFamily="Aeonik Regular" fontSize="16px" fontWeight={400} style={labelStyle}>
                    {label}
                </Typography>
            </Grid>
            <Grid item xs={4} style={valueStyle}>
                <Typography data-testid={`value-${label}`} fontFamily="Aeonik Regular" fontSize="16px" fontWeight={400} style={{ marginRight: 8 }} color='#0379B2'>
                    {value}
                </Typography>
            </Grid>
            <CopyableText text={value} copiedText={copiedText} handleCopy={handleCopy} label={label} />
        </>
    );

    return (
        <Box>
            <Typography data-testid='PaymentMethodTitle' fontFamily="Aeonik Regular" fontSize="18px" fontWeight={400} paddingBottom='10px'>Check or wire transfer</Typography>
            <Box paddingBottom='20px'>
                <Typography data-testid='MailCheckTitle' fontFamily="Aeonik Regular" fontSize="16px" fontWeight={700} paddingBottom='10px'>Mail checks to: </Typography>
                <Typography data-testid='MailAddress' fontFamily="Aeonik Regular" fontSize="16px" fontWeight={400}>
                    Moderna US, Inc. Lockbox #411543 <br/> 2 Morrissey Blvd. Dorchester, MA 02125
                </Typography>
            </Box>
            <Box>
                <Typography data-testid='WireFundsTitle' fontFamily="Aeonik Regular" fontSize="16px" fontWeight={700} paddingBottom='10px'>Wire funds to: </Typography>
                <Grid container spacing={1}>
                    <LabelValue label="Financial Institution:" value={BANK_NAME} copiedText={copiedText} handleCopy={handleCopy} />
                    <LabelValue label="Account Name:" value={ACCOUNT_NAME} copiedText={copiedText} handleCopy={handleCopy} />
                    <LabelValue label="Routing Number (ACH):" value={ROUTING_NUMBER_ACH} copiedText={copiedText} handleCopy={handleCopy} />
                    <LabelValue label="Routing Number (Wire):" value={ROUTING_NUMBER_WIRE} copiedText={copiedText} handleCopy={handleCopy} />
                    <LabelValue label="Account Number:" value={ACCOUNT_NUMBER} copiedText={copiedText} handleCopy={handleCopy} />
                </Grid>
            </Box>
        </Box>
    )
}

export default CheckAndWirePayment;
