import { useEffect, useState } from "react";
import { Box, Typography, Container, Grid, TextField, RadioGroup, Radio, FormControlLabel } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Button } from "../../Button";
import { US_STATES } from "../../../constants";
import { ModalComponent } from "../../ModalComponent"
import Dropdown from "../Dropdown";
import { validateExpirationDate, validateName } from "../Helper";

const RequiredSymbol = styled('span')({
    color: 'red',
});

const buttonsContainerSxStyles = {
    display: 'flex',
    justifyContent: 'center',
    gap: '24px'
}

const helperTextStyles = {
    sx: {
      '&.Mui-error': {
        color: '#E22929',
        fontFamily: 'Aeonik Regular',
        marginLeft: '0',
        fontSize: '14px',
        letterSpacing: '-0.28px'
      },
    },
}

const radioLabelStyles = { 
    '& .MuiTypography-root': { 
        fontFamily: "Aeonik Regular",
    },
}

const FormLabel = ({ label, isRequired }) => {
    return (
        <Typography textAlign="left" paddingBottom='7px' fontFamily="Aeonik Regular" color="#191F2A" fontWeight="700" fontSize="16px">
            {label}{isRequired && <RequiredSymbol>*</RequiredSymbol>}
        </Typography>
    )
}

const MedicalLicenseFormModal = ({ 
    isMedicalLicenseFormModalOpen,
    handleCloseModal,
    selectedState,
    setSelectedMedicalLicense,
    setAddressFormErrors,
    addressFormErrors
    }) => {

    const defaultFields = {
        licenseType: 'medical',
        firstName: '',
        lastName: '',
        name: '',
        state: selectedState,
        number: '',
        expiration: '',
    }
    
    const [inputFields, setInputFields] = useState(defaultFields);
    const [errors, setErrors] = useState({});
    const requiredFields = ['firstName', 'lastName', 'name', 'number', 'state', 'expiration']

    const USStates = US_STATES.map((state) => {
        return({
            "value": state,
            "label": state,
        })
    })

    useEffect(() => {
        setInputFields((currentFields) => ({
            ...currentFields,
            state: selectedState,
        }));
    }, [selectedState])

    const textFieldStyles = (name) => {
        return {
            "& input": {
                fontFamily: 'Aeonik Regular',
                padding: "12px",
                height: '24px',
                '&::placeholder': {
                    fontStyle: 'italic',
                },
            },
            "& .MuiFormHelperText-root": {
                minHeight: '20px',
            },
            '& .MuiOutlinedInput-root': {
                borderRadius: '6px',
                '&:hover fieldset': {
                    border: errors[name] ? '2px solid #E22929' : '1px solid #191F2A',
                },
                '&.Mui-focused fieldset': {
                    border: errors[name] ? '2px solid #E22929' : '1px solid #191F2A',
                },
            },
        }
    }

    const createTextField = (name, label, value, onChange, onBlur, isRequired, placeholder) => {
        return (
            <>
                <FormLabel label={label} isRequired={isRequired}/>
                <TextField
                    id={name}
                    data-testid={name}
                    name={name}
                    type="text"
                    value={value}
                    placeholder={placeholder}
                    onChange={onChange}
                    onBlur={onBlur}
                    focused
                    variant="outlined"
                    fullWidth
                    sx={textFieldStyles(name)}
                    error={Boolean(errors[name])}
                    helperText={errors[name]}
                    FormHelperTextProps={helperTextStyles}
                    inputProps={{
                        'data-testid': `${name}-input`
                    }}
                />
            </>
        )
    }

    const validateField = (name, value) => {
        let errorMsg = "";
        if (requiredFields.includes(name) && !value.trim()) {
            errorMsg = "Required";
        } else if (name === "firstName" || name === "lastName") {
            errorMsg = validateName(value);
        } else if (name === "expiration") {
            errorMsg = validateExpirationDate(value);
        }
        return errorMsg;
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        const newInputFields = { ...inputFields, [name]: value };
        setInputFields(newInputFields);
    };

    const handleChangeRadio = (event) => {
        const { value } = event.target;
        const resetFields = {
            ...defaultFields,
            licenseType: value,
            state: selectedState,
        }
        setInputFields(resetFields)
    }

    const handleStateSelection = (event) => {
        const { value } = event.target;
        const newInputFields = { ...inputFields, state: value };
        setInputFields(newInputFields);
    
        const newErrors = { ...errors, state: "" };
        setErrors(newErrors);
    };

    const handleBlur = (event) => {
        const { name, value } = event.target;
        const errorMsg = validateField(name, value);
        setErrors({ ...errors, [name]: errorMsg });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        let dynamicRequiredFields = [...requiredFields];
        if (inputFields.licenseType === 'medical') {
            dynamicRequiredFields = dynamicRequiredFields.filter(field => field !== 'name');
        } else if (inputFields.licenseType === 'pharmacy') {
            dynamicRequiredFields = dynamicRequiredFields.filter(field => field !== 'firstName' && field !== 'lastName');
        }

        const validationErrors = {};
        dynamicRequiredFields.forEach((fieldName) => {
            const fieldValue = inputFields[fieldName];
            const errorMsg = validateField(fieldName, fieldValue);
            if (errorMsg) validationErrors[fieldName] = errorMsg;
        });
    
        if (Object.keys(validationErrors).length === 0) {
            setSelectedMedicalLicense(inputFields);
            setInputFields(defaultFields);
            setAddressFormErrors({ ...addressFormErrors, ['medicalLicense']: '' });
            setErrors({});
            handleCloseModal();
        } else {
            setErrors(validationErrors);
        }
    }

    const MedicalLicenseForm = (
        <form onSubmit={handleSubmit}>
            <Container data-testid='medicalLicenseForm'>
                <Box display={'flex'} alignItems={'center'} gap={'16px'} marginY={'16px'}>
                    <Typography fontFamily="Aeonik Regular" color="#191F2A" fontWeight="700">License type:</Typography>
                    <RadioGroup data-testid="licenseTypeRadioSelection" aria-label="Select License Type" name="licenseType" value={inputFields.licenseType} onChange={handleChangeRadio} row>
                        <FormControlLabel
                            value="medical"
                            label="Medical Practitioner"
                            control={<Radio />}
                            sx={radioLabelStyles}
                        />
                        <FormControlLabel 
                            value="pharmacy"
                            control={<Radio />}
                            label="Pharmacy or other institution"
                            sx={radioLabelStyles}
                        />
                    </RadioGroup>
                </Box>
                <Grid container data-testid='licenseDetailsSection' rowSpacing={'24px'} columnSpacing={'16px'} justifyContent="start" alignItems="flex-start" marginBottom={'48px'}>
                    {inputFields.licenseType === 'medical' ? 
                        <>
                            <Grid item xs={12} sm={4.5} paddingBottom={'1px'}>
                                {createTextField("firstName", 'Practitioner first name', inputFields.firstName, handleChange, handleBlur, true, 'e.g., Jonathan')}
                            </Grid>
                            <Grid item xs={12} sm={4.5} paddingBottom={'1px'}>
                                {createTextField("lastName", "Practitioner last name", inputFields.lastName, handleChange, handleBlur, true, 'e.g., Doe')}
                            </Grid>
                        </> :
                        <Grid item xs={12} sm={9} paddingBottom={'1px'}>
                            {createTextField("name", 'Pharmacy name', inputFields.name, handleChange, handleBlur, true, 'e.g., Townsville Community Pharmacy, Store #5')}
                        </Grid>
                    }
                    <Grid item xs={12} sm={3} paddingBottom={'1px'} textAlign={'left'}>
                        <Dropdown 
                            dataTestId={"stateDropdownSelection"}
                            name={"state"}
                            label={"State"}
                            options={USStates}
                            value={inputFields?.state || selectedState}
                            onChangeHandler={handleStateSelection}
                            isRequired={true}
                            errors={errors}
                            isDisabled={!!selectedState}
                        />
                    </Grid>
                    <Grid item xs={12} sm={9} paddingBottom={'1px'}>
                        {createTextField("number", "License number", inputFields.number, handleChange, handleBlur, true, 'e.g., ND.1234-45678')}
                    </Grid>
                    <Grid item xs={12} sm={3} paddingBottom={'1px'}>
                        {createTextField("expiration", "Expiration date", inputFields.expiration, handleChange, handleBlur, true, 'MM/DD/YYYY')}
                    </Grid>
                </Grid>
                <Box data-testid='buttonsSection' sx={buttonsContainerSxStyles}>
                    <Button 
                        sx={{ padding: "16px 24px", textTransform: "none" }}
                        buttonType="mds-secondary"
                        data-testid='cancelBtn'
                        onClick={handleCloseModal}
                    >
                        Cancel
                    </Button>
                    <Button 
                        type="submit"
                        id="submit"
                        sx={{ padding: "16px 24px", textTransform: "none" }}
                        buttonType="mds-primary"
                        data-testid='saveBtn'
                    >
                        Save
                    </Button>
                </Box>
            </Container>
        </form>
    )
    return (
        <ModalComponent 
            title="Add a new medical license"
            openModal={isMedicalLicenseFormModalOpen}
            closeModalHandler={handleCloseModal}
            modalContent={MedicalLicenseForm}
            style={{
                '#crossIcon': {
                    marginTop: '5px'
                },
                '#titleContainer':{
                    padding: '5px',
                    marginBottom: '0px'
                },
                minWidth: '700px',
                padding: '32px',
                '#modalTitle': {
                    marginRight: {xs: '40px'},
                },
            }}
        />
    )
}

export default MedicalLicenseFormModal