import React, {useState,useRef, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {Box, Grid, Typography, styled} from "@mui/material";
import {OrderSummaryCard} from '../components/Ordering/OrderSummaryCard';
import OrderSummaryTable from '../components/Ordering/OrderSummaryTable';
import { AlertMessage, Spinner } from '../components';
import { orderProductsMockData } from './Ordering/OrderMockData';
import { mapProductNames } from '../utils/HelperFunctions/OrderAndReservationHelper';
import { getCheckoutCart } from '../utils/OrderService/CartApi';

const TypographyNote = styled(Typography)`
    font-family: Aeonik Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`

const OrderCheckoutPage = () => {
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState('');
  const alertRef = useRef(null);
  const { globalErrorMessage = [] } = useSelector(store => store?.globalMessages);
  const generalApiErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "GENERAL_API_ERROR");
  const generalApiError = generalApiErrorData && generalApiErrorData[0]?.fields?.message;
  const[processing, setProcessing] = useState(true);

  const userData = useSelector((store) => store?.commerce?.userData);
  const userId = userData?.userId;
  const cartId = useSelector((store) => store?.commerce?.cart?.cart?.cartId) || '';

  const orderableProducts = useSelector((store) => store?.commerce?.orderableProducts || []);
  const [summaryData, setSummaryData] = useState({});
  const [orderCartEntries, setOrderCartEntries] = useState([]);
  const uniqueAddressIds = new Set(orderCartEntries.map(entry => entry.shipToAddress?.addressId));

  const getSummaryData = (cart) => {
    if (!cart) return {};

    let { subtotalListPrice, subtotalContractPrice, totalTax, totalListPrice, totalContractPrice, discountTotal } = cart?.cartPriceTotals?.total || {};
    return {
      totalDoses: cart?.totalQuantity || 0,
      listPrice: subtotalListPrice?.displayValue, 
      savings: discountTotal?.displayValue, 
      contractPrice: subtotalContractPrice?.displayValue, 
      taxes: totalTax?.displayValue, 
      totalPrice: totalContractPrice?.displayValue || totalListPrice?.displayValue
    }
  }

  useEffect(() => {
    setProcessing(true);

    dispatch(getCheckoutCart({ cartId: cartId, userId: userData?.userId }))
      .unwrap()
      .then((data) => {
        setOrderCartEntries(data?.cart?.cartLineItems || []);
        setSummaryData(getSummaryData(data?.cart || null));
      })
      .catch((error) => {
        setErrorMsg(generalApiError);
        alertRef.current?.openAlert(error);
      })
      .finally(() => {
        setProcessing(false);
      });
  }, []);


  const createDisplayEntry = useMemo(() => {
    return orderCartEntries.map((orderEntry => {
      if (!orderEntry) return null;
      const { productId, shipToAddress, billToAddress, purchaseOrderNumber } = orderEntry || {};
      const productMap = mapProductNames(orderableProducts);
      const productName = productMap[productId];
      return {
          id: orderEntry?.cartLineItemId,
          doses: parseInt(orderEntry?.quantity, 10) || 0,
          productId,
          productName: productName,
          listPrice: orderEntry?.unitListPrice?.displayValue,
          contractPrice: orderEntry?.unitContractPrice?.displayValue,
          priceTotal: orderEntry?.priceTotal,
          shipToAddress,
          billToAddress,
          purchaseOrderNumber
      };
    })).filter(entry => entry !== null);
    }, [orderCartEntries, orderableProducts]);


  const getEntriesForThisAddress = (addressId) => {
      let filteredEntries = createDisplayEntry?.filter(({shipToAddress}) => String(shipToAddress?.addressId) === String(addressId));
    return { items: filteredEntries,
            shipToAddress: filteredEntries[0]?.shipToAddress,
            billToAddress: filteredEntries[0]?.billToAddress,
            purchaseOrderNumber: filteredEntries[0]?.purchaseOrderNumber
        };
  }

  const errorMsgHandler = (errorMsg) => {
    let message = errorMsg || generalApiError;
    setErrorMsg(message);
    alertRef.current?.openAlert(message);
  }

  const OrderNotes = () => { 
    return (
      <Box width={"486px"} mt="24px"> 
        <TypographyNote>
          Orders are processed immediately and cannot be modified or cancelled after being placed.
        </TypographyNote>
        <TypographyNote mt="7px"> Most orders are delivered within 2 business days. </TypographyNote>
        <TypographyNote mt="7px">
            Orders with multiple addresses will be separated into multiple sub-orders and invoiced individually.
        </TypographyNote>
        <TypographyNote mt="7px">
          Your <Link to="/my-profile/" target="_blank" style={{color: "#0379B2"}}> Corporate Address </Link>will be listed as the Sold To address on your invoice.
        </TypographyNote>
      </Box>
    )
  }

  return (
    <>
      <AlertMessage
        variant={"filled"}
        type={"error"}
        message={errorMsg}
        sx={{ top: 120 }}
        ref={alertRef}
      />
      <Spinner processing={processing} />

      {!processing && orderCartEntries.length > 0 && <Grid
        data-testid="mainContainer"
        container
        spacing={{ xs: 1, lg: 4 }}
        padding={{ xs: 2, md: 3, lg: 12 }}
      >
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: "30px",
              fontWeight: 500,
              lineHeight: "36px",
              fontFamily: "Aeonik Regular",
            }}
          >
            Place your order
          </Typography>
        </Grid>
        <Grid data-testid={"order-summary-table"} item xs={12} sm={8}>
          {[...uniqueAddressIds].map((addressId, index) => {
                  let entriesToRender = getEntriesForThisAddress(addressId);
                  let marginTop = index > 0 ? "33px" : "0px";
                  return <OrderSummaryTable orderDetail={entriesToRender} sx={{'#boxContainer': { marginTop: marginTop}}}/>
          })}
        </Grid>
        <Grid data-testid={"order-summary-card"} item xs={12} sm={4} overflow="unset">
          <OrderSummaryCard summaryData={summaryData} userId={userId} handleErrorMsg={errorMsgHandler} setProcessing={setProcessing}/>
          <OrderNotes />
        </Grid>
      </Grid>}
    </>
  );
}

export default OrderCheckoutPage;
