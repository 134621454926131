import { styled } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import { Box, Typography } from '@mui/material';

const GridContainer = styled(Grid)(({ theme: { palette: { neutral, secondary  }, breakpoints }}) => `
  background-color: ${secondary.main};
  border-radius: 16px;
  padding: 10px 10px 26px 10px;
  margin: 0 !important;
  width: 100% !important;
  &&.edit {
    background-color: ${neutral.gray5};
  }
`);

const Label = styled(Typography)(({ theme: { palette: { primary }, breakpoints }}) => `
  color: ${primary.main};
  margin-top: 5px;
  margin-Bottom: 5px;
  text-transform: uppercase;
  ${[breakpoints.down('sm')]} {
    line-height: 16.5px;
  }
`);

const Value = styled(Typography)(({ theme: { breakpoints } }) => `
  user-select: text;
  ${[breakpoints.down('sm')]} {
    line-height: 24px;
  }
`);

const NotFoundValue = styled('p')(({ theme: { palette: { neutral } }}) => `
  color: ${neutral.gray2};
`);

const CustomGrid = styled(Grid)`
    &.removePadding {
      padding-top: 0 !important;
    }
`;

export const AccountDetails = ({ staticContent, userInfo, pageType }) => {
    const address = userInfo?.orgUnit?.addresses && userInfo?.orgUnit?.addresses[0]?.line1;
    const town = userInfo?.orgUnit?.addresses && userInfo?.orgUnit?.addresses[0]?.town;
    const region = userInfo?.orgUnit?.addresses && userInfo?.orgUnit?.addresses[0].region?.isocode;
    const formattedRegion = region?.split('-')[1];
    const postalCode = userInfo?.orgUnit?.addresses && userInfo?.orgUnit?.addresses[0].postalCode;
    const customFormattedAddress = (address ? `${address}, ` : '') + (town ? `${town}, ` : '') + (formattedRegion ? `${formattedRegion}, ` : '') + (postalCode ? `${postalCode}` : '');
    const isRegistrationAndOnboardingEnabled = process.env.SIMPLE_ONBOARDING_AND_REGISTRATION === 'true';
    return(
        <Box margin={isRegistrationAndOnboardingEnabled ? '30px auto 50px auto' : '30px auto 0 auto'} width='80%' id="AccountDetails" data-testid='accountDetails'>
            <Typography variant={'h3'} fontFamily={'Aeonik Light'} color='primary.main' textAlign='left' mb='25px' mt='30px' data-testid='accountDetailsSectionLabel'>{staticContent?.accountDetailsSectionLabel}</Typography>
            <GridContainer container spacing={2} className={pageType}>
                <Grid item xs={12} md={12}>
                    <Label variant={'h6'} id="accountNameLabel" data-testid='accountNameLabel'>{staticContent?.accountNameLabel}</Label>
                    {userInfo?.legalAccountName ? <Value variant={'p1'} id="accountNameVal" data-testid='accountName'>{userInfo?.legalAccountName}</Value> :
                    <NotFoundValue variant={'p1'}>{staticContent?.notFoundText}</NotFoundValue>}
                </Grid>
                <Grid item xs={12} md={6}>
                    <Label variant={'h6'} id="officeAddressLabel" data-testid='officeAddressLabel'>{staticContent?.officeAddressLabel}</Label>
                    {customFormattedAddress ? <Value variant={'p1'} id="officeAddress"  data-testid='officeAddress'>{customFormattedAddress}</Value> : 
                    <NotFoundValue variant={'p1'}>{staticContent?.notFoundText}</NotFoundValue>}
                </Grid>
                <Grid item xs={12} md={6}>
                    <Label variant={'h6'} id="officePhoneLabel" data-testid='officePhoneLabel'>{staticContent?.officePhoneLabel}</Label>
                    {userInfo?.orgUnit?.addresses && userInfo?.orgUnit?.addresses[0]?.phone ? <Value variant={'p1'} id="officePhone" data-testid='officePhone'>{userInfo?.orgUnit?.addresses[0]?.phone}</Value> :
                    <NotFoundValue variant={'p1'}>{staticContent?.notFoundText}</NotFoundValue>}
                </Grid>
                <Grid item xs={12} md={6}>
                    <Label variant={'h6'} id="officeEmailLabel" data-testid='officeEmailLabel'>{staticContent?.officeEmailLabel}</Label>
                    {userInfo?.orgUnit?.addresses && userInfo?.orgUnit?.addresses[0]?.email ? <Value variant={'p1'} id="officeEmail" data-testid='officeEmail'>{userInfo?.orgUnit?.addresses[0]?.email}</Value> : 
                    <NotFoundValue variant={'p1'}>{staticContent?.notFoundText}</NotFoundValue>}
                </Grid>
            </GridContainer>
        </Box>
    )
}