import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, Container, Typography } from '@mui/material';
import CheckIcon from "../../assets/images/check.svg";
import { Button } from '../../components';
import { setCanAccessOrderConfirmationPage, setIsUserValidated } from '../../features/globalStates/globalStatesSlice';
import MessageBanner from '../../components/MessageBanner/MessageBanner';

const OrderConfirmationPage = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const locale = useSelector((state) => state.globalStates.locale);
    const { userData } = useSelector((store) => store?.commerce);
    const canAccessOrderConfirmationPage = useSelector((state) => state.globalStates.canAccessOrderConfirmationPage)
    const isUserValidated = useSelector((state) => state.globalStates.isUserValidated); // TO-DO: Where does this needs to be set?

    useEffect(() => {
        if(!canAccessOrderConfirmationPage) {
        history?.push(`/${locale}/`);
        } else {
        dispatch(setCanAccessOrderConfirmationPage(false));
        }
    }, [history]);

    return (
        <Container sx={{ minHeight: "576px", display: "flex", flexDirection:"column", alignItems: "center", paddingTop:"64px"}}>
            <img src={CheckIcon} alt='check icon' className='CheckIcon' height='56' width='56'/>
            <Box data-testid='ConfirmationMsgSection' display="flex" flexDirection="column" alignItems="center" gap={2}>
                <Typography data-testid='ThankYouMsg' fontFamily="Aeonik Regular" fontSize="24px" fontWeight={500}>Thank you, {`${userData?.firstName} ${userData?.lastName}`}.</Typography>
                <Typography data-testid='OrderPlacedMsg' fontFamily="Aeonik Regular" fontSize="24px" fontWeight={700}>Your order has been recieved.</Typography>
            </Box>
            <Box data-testid='OtherInstructionSection' display="flex" flexDirection="column" alignItems="center" gap={4} padding={"32px"}>
                {isUserValidated ?
                    <Typography data-testid='InfoMsg' fontFamily="Aeonik Regular" fontSize="18px" fontWeight={500}>Most orders ship within 2 business days. You will receive an invoice with payment details.</Typography>
                    :
                    <MessageBanner
                        type="sync"
                        headerText="Your account is being validated"
                        messageText="Your orders will appear “on hold” until your account is fully validated. We will contact you if we need any other information. No further action is required at this time."
                        dataTestId="AccountBeingValidatedBanner"
                        style={{width: "545px"}}
                    />
                }
            </Box>
            <Button
                buttonType='mds-secondary'
                data-testid='placeAnotherOrderButton'
                sx={{ padding: "16px 24px", textTransform: "none"}}
                onClick={() => history.push(`/${locale}/order-form`)}
            >
                Place another order
            </Button>
        </Container>
    )
}

export default OrderConfirmationPage;
