import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Box, Divider, Typography, styled } from "@mui/material";
import { loadOrderSummaryComponentContent } from "../../features/contentful/contentfulThunkApi";
import { Button, Checkbox } from "..";
import CustomCard from "../CustomCardComponent/CustomCard";
import { formattedPrice } from "../../utils/Helper";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { setCanAccessOrderConfirmationPage } from "../../features/globalStates/globalStatesSlice";
import CheckAndWirePayment from "../Payments/CheckAndWirePayment";
import { createOrder } from "../../utils/OrderService/OrderApi";
import { getErrorMessage } from "../../utils/getErrorMessage";

export const TypographyTitle = styled(Typography)`
    font-family: Aeonik Regular;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
`

export const TypographyList = styled(Typography)`
    font-family: Aeonik Regular;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
`

export const SummaryItem = ({ label, value, sx }) => (
    <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        mb="8px"
        sx={sx}
    >
        <TypographyList id="label">{label}</TypographyList>
        <TypographyList id="value">
            {value}
        </TypographyList>
    </Box>
);

export const InvoiceSummary = ({summaryData}) => {
    const { totalDoses, listPrice, savings, contractPrice, taxes, totalPrice } = summaryData;

    return (
      <Box data-testid={'invoice-summary'}>
        <SummaryItem
          label="Total Doses"
          value={totalDoses?.toLocaleString()}
          sx={{ marginBottom: "0px" }}
        />
        <SummaryItem
          label="Cartons"
          value={`${(totalDoses / 10)?.toLocaleString()} cartons`}
          sx={{
            "#label": { visibility: "hidden" },
            "#value": { fontSize: "14px" },
          }}
        />
        <SummaryItem
          label="List Price"
          value={formattedPrice(listPrice)}
          sx={{ "#value": { textDecoration: "line-through" } }}
        />
        <SummaryItem
          label="Savings"
          value={formattedPrice(savings)}
          sx={{ "#value": { color: "#E22929" } }}
        />
        <SummaryItem
          label="Contract Price"
          value={formattedPrice(contractPrice)}
        />
        <SummaryItem label="Taxes" value={formattedPrice(taxes)} />
        <SummaryItem label="Shipping" value="Free" />
        <Divider
          orientation="horizontal"
          variant="middle"
          sx={{ marginBottom: 2, marginTop: 1 }}
        />
        <SummaryItem
          label="Total"
          value={formattedPrice(totalPrice)}
          sx={{
            "#value, #label": {
              fontWeight: 700,
              lineHeight: "28px",
              fontSize: "20px",
            },
          }}
        />
      </Box>
    );
}

export const PaymentMethod = () => {
    return (
    <>
        <Box gap="16px" data-testid={'payment-method'}>
            <TypographyTitle mb="16px" textTransform="capitalize">
                Payment Method
            </TypographyTitle>
            <CheckAndWirePayment />
        </Box> 
    </>
    );
}

export const OrderSummaryCard = ({ userId, handleErrorMsg, setProcessing, summaryData }) => {
    const [checked, setChecked] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const dispatch = useDispatch();
    const history = useHistory();
    const locale = useSelector((state) => state.globalStates.locale);
    const checkoutCartData = useSelector((state) => state?.commerce?.checkoutCartData);
    const cartId = checkoutCartData?.cart?.cartId;
    const { orderSummaryComponentContent } = useSelector((store) => store.contentful);

    const mapCartLineItemsToSnapshots = (checkoutCartData) => {
        const taxSnapshotMap = {};
        const priceSnapshotMap = {};
    
        checkoutCartData?.cart?.cartLineItems?.forEach((item) => {
            taxSnapshotMap[item.cartLineItemId] = item.taxSnapshotId;
            priceSnapshotMap[item.cartLineItemId] = item.priceSnapshotId;
        });
    
        return {
            cartLineItemIdToTaxSnapshotId: taxSnapshotMap,
            cartLineItemIdToPriceSnapshotId: priceSnapshotMap
        };
    };

    const priceAndTaxSnapshots = mapCartLineItemsToSnapshots(checkoutCartData);

    const createOrderRequestBody = {
        userId: userId,
        cartId: cartId,
        cartLineItemIdToTaxSnapshotId: priceAndTaxSnapshots.cartLineItemIdToTaxSnapshotId,
        cartLineItemIdToPriceSnapshotId: priceAndTaxSnapshots.cartLineItemIdToPriceSnapshotId
    }

    const handlePlaceOrder = async () => {
        if (!checked) {
            setErrorMsg("Please accept the agreements above to place this order");
            return;
        }

        setProcessing(true);
        setErrorMsg("");
        try {
            const response = await createOrder(createOrderRequestBody);
            if (response?.status === 201) {
                setProcessing(false);
                dispatch(setCanAccessOrderConfirmationPage(true));
                history.push(`/${locale}/order-confirm`);
            }
        } catch (error) {
            setProcessing(false);
            const response = error?.response?.data;
            const errorMessage = getErrorMessage(response?.errorCode);
            handleErrorMsg(errorMessage);
        } finally {
            setProcessing(false);
        }
    };

    useEffect(() => {
        if (
            orderSummaryComponentContent &&
            Object.keys(orderSummaryComponentContent).length === 0
        ) {
            dispatch(loadOrderSummaryComponentContent());
        }
    }, [orderSummaryComponentContent]);

    const linkMap = {
        "Terms of Use Internal Link": "/terms-of-use",
        "Privacy Policy Internal Link": "/privacy-policy",
        "Terms and Conditions of Sale Internal Link":
            "/terms-and-conditions-of-sale",
    };

    const options = {
        renderNode: {
            [INLINES.ENTRY_HYPERLINK]: (node, children) => {
                const title = node.data.target.fields.title;
                // Use the title to find the correct URL
                const url = linkMap[title] || "";

                return (
                    <Box component={"span"}>
                        <Link to={`/${locale}${url}`} target="_blank">
                            <Typography variant={"link"} fontSize="16px">
                                {children}
                            </Typography>
                        </Link>
                    </Box>
                );
            },
            [BLOCKS.PARAGRAPH]: (node, children) => (
                <Typography variant={"link"} fontSize="16px">
                    {children}
                </Typography>
            ),
        },
    };
    
    return (
        <Box width={"486px"}>
            <CustomCard
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "32px",
                    gap: "24px",
                }}
            >
                <TypographyTitle mb="24px" variant="h3" textTransform="capitalize">Summary</TypographyTitle>
                <InvoiceSummary summaryData={summaryData}/>
                <PaymentMethod/>

                <Box>
                    <Checkbox
                        sx={{ alignItems: "flex-start", marginTop: "24px" }}
                        name="privacyPolicyConsent"
                        label={documentToReactComponents(
                            orderSummaryComponentContent?.reservationPrivacyPolicyConsentMessage,
                            options
                        )}
                        value={checked}
                        data-testid="privacyPolicyConsentCheckbox"
                        onChange={() => {
                            setChecked((prev) => !prev);
                            setErrorMsg("");
                        }}
                    />
                </Box>
                {errorMsg && (
                    <Typography 
                        color="#E22929" 
                        fontFamily="Aeonik Regular" 
                        fontSize="16px" 
                        fontWeight="400" >
                    {errorMsg}
                    </Typography>
                )}
                <Button
                    buttonType="mds-primary"
                    sx={{ textTransform: "none", marginTop: "24px" }}
                    onClick={handlePlaceOrder}
                >
                    Place Order
                </Button>
            </CustomCard>
        </Box>
    );
};
