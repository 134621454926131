
export const mapProductNames = (orderProducts) => {
    return orderProducts?.length ? orderProducts.reduce((all, item) => ({...all,
        [item.code]: item?.ageGroup ? `${item.description}, ${item?.ageGroup}`: `${item.description}`
        }), {}) : {};
}

export const getPricingInfo = (allPrices) => {
    let productPricingMap = allPrices?.productPrices.reduce((all, productPrice) => {
        return {
            ...all,
            [productPrice?.productId]: {
                listPrice: productPrice?.listPriceFormatted,
                contractPrice: (productPrice?.finalPriceFormatted) ? (productPrice?.finalPriceFormatted) : 'UNAVAILABLE'
            }
        }
    }, {}) || {};
    return productPricingMap;
}

export const convertUserDataAddressToCartData = (userDataAddress) => {
    return {
        "addressId": userDataAddress?.id,
        "street1": userDataAddress?.line1 ,
        "street2": userDataAddress?.line2 ,
        "city": userDataAddress?.city ,
        "state": userDataAddress?.state ,
        "zipCode": userDataAddress?.zip,
        "zipExtraFour": userDataAddress?.zipExtension ,
        "name": userDataAddress?.dba ,
        "attnTo": userDataAddress?.attention,
        "contactInfo": {
            "name": userDataAddress?.contactName,
            "email": userDataAddress?.contactEmail,
            "phone": userDataAddress?.contactPhone,
        }
    };
};

export const convertCartDataAddressToUserData = (cartDataAddress) => {
    return {
        id: cartDataAddress?.addressId,
        line1: cartDataAddress?.street1,
        line2: cartDataAddress?.street2,
        city: cartDataAddress?.city,
        state: cartDataAddress?.state,
        zip: cartDataAddress?.zipCode,
        zipExtension: cartDataAddress?.zipExtraFour,
        dba: cartDataAddress?.name,
        attention: cartDataAddress?.attnTo,
        contactName: cartDataAddress?.contactInfo?.name,
        contactEmail: cartDataAddress?.contactInfo?.email,
        contactPhone: cartDataAddress?.contactInfo?.phone,
    };
};