import { createAsyncThunk } from "@reduxjs/toolkit";
import commerceAuthAxiosInstance from "../sevices/commerceAuthAxiosInstance";

export const createAddressApi = async (payload) => {
    try {
        const res = await commerceAuthAxiosInstance({
          url: `modernaOrgUnit/address`,
          method: "POST",
          data: JSON.stringify(payload),
        });
        return res;
      } catch (error) {
        throw error;
      }
}

export const updateDefaultShipmentAddressAPI = createAsyncThunk(
  "address/setDefault",
  async ({ userId, addressId }, { rejectWithValue }) => {
    try {
      const res = await commerceAuthAxiosInstance({
        url: `/modernaOrgUsers/${userId}/addresses`,
        method: "PUT",
        data: {
          addressId: addressId
        },
      });
      return res?.data;
    } catch (error) {
        if (!error.response) {
            throw error
        }
        return rejectWithValue(error.response.data)
    }
  }
)
