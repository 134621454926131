import { createAsyncThunk } from "@reduxjs/toolkit";
import authAxiosInstance from "../../sevices/authAxiosInstance";

export const updateUserDetailsApi = async (payload) => {
    try {
        const res = await authAxiosInstance({
            url: `userservice/user/update-details`,
            method: "POST",
            data: JSON.stringify(payload)
        });
        return res;
    } catch (error) {
        throw error;
    }
}

export const updateUserPreferencesApi = createAsyncThunk(
  "commerce/updateUserPreferencesApi",
  async ({ payload } , { rejectWithValue }) => {
    try {
      const res = await authAxiosInstance({
        url: `userservice/user/update`,
        method: "POST",
        data: JSON.stringify(payload)
      });
      return res.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response);
    }
  }
);

export const getUserByEmail = createAsyncThunk(
    "commerce/getUserByEmail",
    async ({ emailAddress } , { rejectWithValue }) => {
        try {
            const res = await authAxiosInstance({
                url: `userservice/user/by-email/${emailAddress}`,
                method: "GET"
              });
            return res.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error.response);
        }
    }
);

export const getUserById = createAsyncThunk(
    "commerce/getUserById",
    async ({ userId } , { rejectWithValue }) => {
        try {
            const res = await authAxiosInstance({
                url: `userservice/user/${userId}`,
                method: "GET"
              });
            return res.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error.response);
        }
    }
);

export const getSAPUserByEmail = createAsyncThunk(
    "commerce/getSapUserByEmail",
    async ({ emailAddress } , { rejectWithValue }) => {
        try {
            const res = await authAxiosInstance({
                url: `userservice/user/by-email/${emailAddress}/sap`,
                method: "GET"
              });
            return res;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error.response);
        }
    }
);
