export const States = [
    { label: "", value: ""},
    { label: "AK", value: "AK"},
    { label: "TX", value: "TX"},
    { label: "WW", value: "WW"},
    { label: "CC", value: "CC"}
  ]
export const COMPANY_NAME = 'companyName';
export const FIRST_NAME = 'firstName';
export const LAST_NAME = 'lastName';
export const FULL_NAME = 'fullName';
export const LINE_1 = 'line1';
export const LINE_2 = 'line2';
export const CITY = 'city';
export const STATE = 'state'
export const ZIP_CODE = 'zipcode';
export const EMAIL = 'email';
export const PHONE = 'phone';
export const HIN = 'hin';
export const DEA = 'dea';
export const STATE_MAPPING = {'AL': 'Alabama'};
export const RESTRICTED_STATES = [];
export const errorMessages = {
    minOrderQuantityNotMet:'MINIMUM ORDER QUANTITY NOT MET',
}

export const ORGANIZATION_ROLES = {
    'Admin/ Executive Asst': 'admin_executive_asst',
    'Ambulatory Clinical': 'ambulatory_clinical',
    'Billing Department': 'billing_department',
    'Business Support Professional': 'business_support_professional',
    'Buying Agent': 'buying_agent',
    'CEO': 'ceo',
    'Certified Nurse Assistant': 'certified_nurse_assistant',
    'Chief Medical Officer': 'chief_medical_officer',
    'Clinical Services/ Operations': 'clinical_services_operations',
    'Doctor of Dental Medicine': 'doctor_of_dental_medicine',
    'Doctor of Medicine (MD)': 'doctor_of_medicine_MD',
    'Doctor of Osteopathic Medicine (DO)': 'doctor_of_osteopathic_medicine_DO',
    'Epidemiologist': 'epidemiologist',
    'Health & Wellness': 'health_and_wellness',
    'Human Resources': 'human_resources',
    'Immunization Services': 'immunization_services',
    'Licensed Practical Nurse': 'licensed_practical_nurse',
    'Medical Assistant': 'medical_assistant',
    'Merchandiser': 'merchandiser',
    'Nurse Practitioner': 'nurse_practitioner',
    'Office Manager': 'office_manager',
    'Operations': 'operations',
    'Pharmacist': 'pharmacist',
    'Pharmacy Technician': 'pharmacy_technician',
    'Physician Assistant': 'physician_assistant',
    'Procurement': 'procurement',
    'Purchasing': 'purchasing',
    'Quality': 'quality',
    'Registered Nurse': 'registered_nurse',
    'Researcher': 'researcher',
    'Safety and Risk': 'safety_and_risk',
    'Scientist, PhD': 'scientist_PhD',
    'Shipping Department': 'shipping_department',
    'Supply Chain': 'supply_chain',
    'Vaccine Educator': 'vaccine_educator',
    'Other': 'undefined'
}

export const US_STATES = [
  "AL", "AK", "AZ", "AR", "CA",
  "CO", "CT", "DE", "FL", "GA",
  "HI", "ID", "IL", "IN", "IA",
  "KS", "KY", "LA", "ME", "MD",
  "MA", "MI", "MN", "MS", "MO",
  "MT", "NE", "NV", "NH", "NJ",
  "NM", "NY", "NC", "ND", "OH",
  "OK", "OR", "PA", "RI", "SC",
  "SD", "TN", "TX", "UT", "VT",
  "VA", "WA", "WV", "WI", "WY"
];

export const CONTRACT_TYPES = {
    GROUP_PURCHASING_ORGANIZATION: 'GROUP_PURCHASING_ORGANIZATION',
    LETTER_OF_COMMITMENT: 'LETTER_OF_COMMITMENT',
    CUSTOMER_PRICE_LIST: 'CUSTOMER_PRICE_LIST',
    RETAIL_PHARMACY: 'RETAIL_PHARMACY',
    PHYSICIANS_BUYING_GROUP: 'PHYSICIANS_BUYING_GROUP',
    UNKNOWN: 'UNKNOWN',
}

export const USER_STATUS = {
  UNVERIFIED: 'UNVERIFIED',
  VERIFIED: 'VERIFIED',
  ONBOARDING_ORG_COMPLETE: 'ONBOARDING_ORG_COMPLETE',
  ONBOARDING_SHIP_COMPLETE: 'ONBOARDING_SHIP_COMPLETE',
  ONBOARDING_BILL_COMPLETE: 'ONBOARDING_BILL_COMPLETE',
  ACTIVE: 'ACTIVE'
}
