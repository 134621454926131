import { Box, Typography } from "@mui/material";
import { useOktaAuth} from "@okta/okta-react";
import { useHistory } from "react-router-dom";
import GPOComponent from "../../components/NewOrdering/GPOComponent";
import { AlertMessage, Button, Spinner} from "../../components";
import { useEffect, useMemo, useRef, useState } from "react";
import { formattedPrice, HandlerBarTemplateHandler, sortAddresses, sortArrayByField} from "../../utils/Helper";
import { ORDERED_PRODUCT_BY_ID_OR_CODE } from "../../utils/Reservation/reservationCommons";
import { useDispatch, useSelector } from "react-redux";
import {
    convertUserDataAddressToCartData,
    getPricingInfo,
    mapProductNames
} from "../../utils/HelperFunctions/OrderAndReservationHelper";
import OrderDetailsCard from "../../components/NewOrdering/OrderDetailsCard";
import { setCartData } from "../../features/commerce/commerceSlice";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddressSelectionModal from "../../components/Reservation/AddressSelectionModal";
import { loadOrderPage } from "../../features/contentful/contentfulThunkApi";
import { UserAddressFormModal } from "../../components/AddressesForm/UserAddressForm";
import { getOrderableProductsApi } from "../../features/commerce/commerceThunkApi";
import { getPricingApi } from "../../utils/PricingService/PricingApi";
import { createCartApi } from "../../utils/OrderService/OrderApi";
import { CONTRACT_TYPES } from "../../constants";

const MODALS = {
    ADDRESS_SELECTION: 'addressSelection',
    ADDRESS_FORM: 'addressForm',
    USER_ADDRESS_FORM: 'userAddressForm',
}

const OrderFormPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { authState } = useOktaAuth() || '';
    const isAuthenticated = authState?.isAuthenticated;
    const alertRef = useRef(null);
    const { orderPage: orderPageContent } = useSelector((store) => store?.contentful);
    const cart  = useSelector((store) => store?.commerce?.cart?.cart);
    const {cartData} = useSelector((store) => store?.commerce)
    const pricingData = useSelector((store) => store?.commerce?.pricingData);
    const orderableProducts = useSelector((store) => store?.commerce?.orderableProducts) || [];
    const userData = useSelector((store) => store?.commerce?.userData);
    const defaultShipToAddress = userData?.addresses?.filter(address => address.addressType === 'ship_to')[0];
    const defaultBillToAddress = userData?.addresses?.filter(address => address.addressType === 'bill_to')[0];
    const [totalDosesQty, setTotalDoses] = useState(0);
    const [openAddressModal, setOpenAddressModal] = useState(false);
    const [showUserAddressFormModal, setShowUserAddressFormModal] = useState(false);
    const [processing, setProcessing] = useState(false);
    const { globalErrorMessage = [] } = useSelector(store => store?.globalMessages);
    const generalApiErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "GENERAL_API_ERROR");
    const generalApiError = generalApiErrorData && generalApiErrorData[0]?.fields?.message;
    const userId = userData?.userId;
    const selectedContract = userData?.selectedContract;
    const [alertMsg, setAlertMsg] = useState({ type:'info', text:'' });

    const createPricingRequestPayload = (reservationProducts) => {
        let productNumbers = reservationProducts?.map(product => product.code);

        return {
            "productNumbers": productNumbers
        };
    };

    const shipToAddresses = useMemo(() => {
            const addresses = userData?.addresses || [];
            return addresses
                .filter(address => address.addressType === "ship_to")
                .map(address => ({
                    ...address,
                    defaultAddress: address.id === defaultShipToAddress?.id,
                }))
                .sort(sortAddresses);
    }, [defaultShipToAddress]);

    useEffect(() => {
        const createCart = async () => {
            try {
                setProcessing(true);
                if (isAuthenticated) {
                    await safeDispatch(createCartApi({ userId: userId }));
                }
                const orderableProducts = await safeDispatch(getOrderableProductsApi({ catalogVersion: 'modernaProductCatalog' }));
                await safeDispatch(getPricingApi({medicalIdentifier: getMedicalIdentifier(), payload: createPricingRequestPayload(orderableProducts)}));
            } finally {
                setProcessing(false);
            }
        };
        createCart();
    }, [dispatch, isAuthenticated]);

    useEffect(() => {
        if (orderPageContent && Object.keys(orderPageContent).length === 0){
            dispatch(loadOrderPage());
        }
    }, [orderPageContent, dispatch]);

    useEffect(() => {
        // if there is cart data, map through cart Entries,
        // else map through products to create default product info
        const internalCartLineItemId = Math.random().toString(36).substr(2, 9);

        if (cart?.cartLineItems?.length) {
            let cartLineItems = cart?.cartLineItems?.map(cartLineItem => {
                return {
                    internalCartLineItemId: internalCartLineItemId,
                    cartLineItemId: cartLineItem?.cartLineItemId,
                    shipToAddress: cartLineItem?.shipToAddress,
                    productId: cartLineItem.productId,
                    quantity: cartLineItem.quantity,
                    poNumber: cartLineItem?.poNumber || "",
                    healthcareLicense:cartLineItem?.healthcareLicense || {},
                    priceSnapshotId:cartLineItem?.priceSnapshotId || ""
                }
            });
            dispatch(setCartData({ cartLineItems: cartLineItems}));
        }
        else {
            let cartLineItems = orderableProducts?.length && orderableProducts?.map(product => {
                let shippingAddress = cart?.length && cart?.cartLineItems[0]?.shipToAddress;
                if (shippingAddress?.addressId === undefined || shippingAddress === null) {
                    shippingAddress = convertUserDataAddressToCartData(shipToAddresses[0]);
                }
                return {
                    internalCartLineItemId: internalCartLineItemId,
                    cartId: cart?.cartId,
                    shipToAddress: shippingAddress,
                    productId: product?.code,
                    quantity: 0,
                    //Need to be implemented
                    poNumber: "",
                    healthcareLicense:{},
                    priceSnapshotId:""
                }
            });
            dispatch(setCartData({cartLineItems: cartLineItems}));
        }
    }, [orderableProducts])

    const safeDispatch = async (action) =>{
        try{
            return await dispatch(action).unwrap();
        } catch(error) {
            const apiErrorMessage = error?.message || generalApiError;
            setAlertMsg({ type: 'error', text: apiErrorMessage });
            alertRef.current?.openAlert('');
        }
    }
    const createDisplayEntry = (cartLineItem) => {
        //TBD: Implement logic when no address is selected
        if (!cartLineItem) return null;
        const { healthcareLicense, productId, shipToAddress } = cartLineItem;
        const shipToAddressId = shipToAddress?.addressId;
        const allPrices = getPrices();
        const pricing = getPricingInfo(allPrices)[productId] || {};
        const address = shipToAddresses?.find(({ id }) => String(id) === String(shipToAddressId));
        const productMap = mapProductNames(orderableProducts);
        const productName = productMap[productId];

        return {
            id: cartLineItem.internalCartLineItemId,
            doses: parseInt(cartLineItem?.quantity, 10) || 0,
            productId,
            productName: productName,
            listPriceValue: pricing?.listPrice,
            listPrice: formattedPrice(pricing?.listPrice),
            contractPriceValue: pricing?.contractPrice,
            contractPrice: formattedPrice(pricing?.contractPrice),
            address,
            healthcareLicense,
        };
    };
    const getPrices = () => {
        return pricingData?.pricesByPriceReference?.find(price =>
            selectedContract ? price?.priceReferenceId === selectedContract?.id : price?.priceReferenceType === CONTRACT_TYPES.CUSTOMER_PRICE_LIST
        );
    };

    const getMedicalIdentifier = () => {
        return selectedContract?.medicalIdentifier || userData?.licenses?.find(license => license.issuer === "HIN" || license.issuer === "DEA")?.number || 'anonymous';
    }

    // TBD: Connecting to orderAPI
    const cartLineItemsModel = cartData?.cartLineItems || [];
    const cartLineItemsDisplay = cartLineItemsModel.map(createDisplayEntry).filter(entry => entry !== null);

    const orderPricingInfo = (data) => {
        return data.reduce(({totalDoses, totalPrice}, product) => {
            const pricePerDose =  product.contractPriceValue ;
            return {
                totalDoses: totalDoses + product.doses,
                totalPrice: totalPrice + product.doses * pricePerDose
            };
        }, {totalDoses: 0, totalPrice: 0})
    }
    const updateDosesRoundedUpMsg = (val) => {
        return HandlerBarTemplateHandler({enteredDoses: val}, orderPageContent?.dosesRoundedUpMessage?.content[0])
    }

    const handleDosesChange = (internalCartLineItemId, doses) => {
        const cartLineItemIndex = cartLineItemsModel.findIndex(entry => entry?.internalCartLineItemId === internalCartLineItemId);
        // If the entry is not found, exit the function
        if (cartLineItemIndex === -1) return;

        // Retrieve the entry to be updated
        const entryToUpdate = cartLineItemsModel[cartLineItemIndex];
        // If it's the entry we want to update, return a new object with the updated doses
        const roundOffQty = (Math.ceil(parseInt(doses) / 10)) * 10;

        if (roundOffQty !== 0 && doses !== roundOffQty) {
            setAlertMsg({type: 'info', text: updateDosesRoundedUpMsg(roundOffQty)})
            alertRef.current?.openAlert('');
        }

        // Create a new entry with the updated doses
        const updatedLineItem = {...entryToUpdate, quantity: roundOffQty};

        // Create a new array with the updated entry
        const updatedCartLineItems = [
            ...cartLineItemsModel.slice(0, cartLineItemIndex),
            updatedLineItem,
            ...cartLineItemsModel.slice(cartLineItemIndex + 1)
        ];

        const { totalDoses } = orderPricingInfo(updatedCartLineItems)
        setTotalDoses(totalDoses);

        dispatch(setCartData({cartLineItems: updatedCartLineItems}));
    };

    const handlePoNumberChange = (internalCartLineItemId, poNumber) => {
        const updatedCartLineItems = cartLineItemsModel?.map(item =>
            item?.internalCartLineItemId === internalCartLineItemId 
                ? { ...item, poNumber } : item);
        dispatch(setCartData({ cartLineItems: updatedCartLineItems }));
    };

    const closeModal = (modalName) => {
        modalName === MODALS.ADDRESS_SELECTION && setOpenAddressModal(false);
        modalName === MODALS.USER_ADDRESS_FORM && setShowUserAddressFormModal(false);
    }

    const handleDeleteAddressRow =  (cartAddressId) => {
        const updatedCartLineItems = cartData?.cartLineItems?.filter((entry) => {
            return entry?.shipToAddress?.addressId !== cartAddressId
        });
        const { totalDoses } = orderPricingInfo(updatedCartLineItems)
        setTotalDoses(totalDoses);
        dispatch(setCartData({ cartLineItems: updatedCartLineItems }));
    }

    const isUniqueAddressId = (addressId, index, self) => {
        return self.findIndex(entry => entry.shipToAddress?.addressId === addressId) === index;
    };

    const cartAddressIds = cartLineItemsModel
        .filter(({ shipToAddress }, index, self) => shipToAddress?.addressId && isUniqueAddressId(shipToAddress?.addressId, index, self))
        .map(({ shipToAddress }) => shipToAddress?.addressId);

    const getOrderDetailCards = cartAddressIds.map((cartAddressId, i) => {
        const cartLineItemForThisAddress = cartLineItemsDisplay?.filter(({address}) => String(address?.id) === String(cartAddressId));
        sortArrayByField(cartLineItemForThisAddress, ORDERED_PRODUCT_BY_ID_OR_CODE, 'productId');
        return <OrderDetailsCard
            key={cartAddressId}
            entries={cartLineItemForThisAddress}
            handleDosesChange={handleDosesChange}
            deleteAddressRow={() => handleDeleteAddressRow(cartAddressId)}
            updateDosesQty={(count) => setTotalDoses(count)}
            orderPageContent={orderPageContent}
            billingAddress={defaultBillToAddress}
            handlePoNumberChange={handlePoNumberChange} 
        />
    })

    const getAllSelectedAddress = () => {
        return cartAddressIds.map((orderAddressId, i) => {
            return cartLineItemsDisplay?.filter(({address}) => String(address?.id) === String(orderAddressId))[0]?.address
        });
    }
    const handleAddNewAddress = () => {
        closeModal(MODALS.ADDRESS_SELECTION);
        setShowUserAddressFormModal(true);
    }

    const handleContinue = () => {
        history.push(`/order-summary`);
    }

    return(
        <>
            <Spinner processing={processing}/>
            { <AlertMessage data-testid='errorAlert' variant={"filled"} type={alertMsg.type} message={alertMsg.text} sx={{ top: 120}} ref={alertRef} />}
            {openAddressModal && <AddressSelectionModal
                openModal={openAddressModal}
                closeModal={() => closeModal(MODALS.ADDRESS_SELECTION)}
                allSelectedAddresses={getAllSelectedAddress()}
                handleAddNewAddress={handleAddNewAddress}
                isOrdering={true}
            />
            }
            {showUserAddressFormModal &&
                <UserAddressFormModal
                    openModal={showUserAddressFormModal}
                    closeModal={() => closeModal(MODALS.USER_ADDRESS_FORM)}
                    addressType={"ship_to"}
                />
            }
            <Box display="flex" flexDirection="column" m={4}>
                <Typography style={{ fontSize: '30px', fontWeight:500, marginBottom: '16px'}}>Quick order form</Typography>
                <Box display="flex" width="100%">
                    <Box flexGrow={1} width="80%" minHeight={'70px'}>
                        {isAuthenticated && <GPOComponent/>}
                    </Box>
                </Box>

                {isAuthenticated && getOrderDetailCards}
                <Box flex={1} minWidth={'300px'} mb={3} mt={3}>
                    <Button
                        buttonType={'mds-secondary'}
                        data-testid='deliverToAddressBtn'
                        muiIconLeft={AddCircleOutlineIcon}
                        disabled={!isAuthenticated}
                        onClick={() => {setOpenAddressModal(true)}}
                    >
                        Deliver to additional address
                    </Button>
                </Box>
                {
                    isAuthenticated &&
                    <Box display="flex" justifyContent="center" mt={4}>
                        <Button
                            data-testid='orderContinueBtn'
                            buttonType={'mds-primary'}
                            muiIconRight={ArrowForwardIcon}
                            onClick={handleContinue}
                        >
                            Continue
                        </Button>
                    </Box>
                }
            </Box>

        </>
    )
}

export default OrderFormPage