import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ExternalRouterPrompt, NewsContent } from '../components';
import { HomepageTopCard } from '../components/HomePageContent/topCard';
import { HomepageLeftCard } from '../components/HomePageContent/leftCard';
import { HomepageRightCard } from '../components/HomePageContent/rightCard';
import { loadInterstitialComponentStaticContent, loadHomePage } from '../features/contentful/contentfulThunkApi';
import { HomepageAfterBannerCard } from "../components/HomePageContent/afterBannerCard";
import { MResviaCard } from "../components/HomePageContent/mResviaCard";

const isReservationsEnabled = process.env.IS_RESERVATIONS_ENABLED;

export const Container = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isReservations',
})(({ isReservations, theme }) => ({
    padding: isReservations ? '48px' : '64px 64px 0px 64px',
    display: 'flex',
    flexDirection: 'row',
    gap: '32px',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        padding: '32px 16px',
    },
}));

const NewHomePage = () => {
    const [showNavigationAlert, setShowNavigationAlert] = useState(false);
    const [externalLink, setExternalLink] = useState("");
    const [externalLinkModalType, setExternalLinkModalType] = useState("socialLink");
    const { interstitialComponentContent: staticContent } = useSelector((store) => store?.contentful);
    const { homePage: homePageContent } = useSelector((store) => store?.contentful);
    const dispatch = useDispatch();
    const canOrder2023Products = useSelector((state) => state.globalStates?.canOrder2023Products);
    const isRegistrationAndOnboardingEnabled = process.env.SIMPLE_ONBOARDING_AND_REGISTRATION === 'true';

    const isMResviaBannerEnabled = process.env.MRESVIA_BANNER_ENABLED === 'true';
    const closeNavigationAlert = () => {
        setShowNavigationAlert(false);
    };
    
    useEffect(() => {
        if (!staticContent || Object.keys(staticContent).length === 0) {
            dispatch(loadInterstitialComponentStaticContent())
          }
        if (!homePageContent || Object.keys(homePageContent)?.length === 0) {
            dispatch(loadHomePage());
        }
    }, [staticContent, homePageContent, dispatch]);

    return (
        <div>
            <HomepageTopCard />
            { (isMResviaBannerEnabled) &&
                <Box padding='48px'>
                    <MResviaCard setShowNavigationAlert={setShowNavigationAlert}
                                 setExternalLink={setExternalLink}
                                 setExternalLinkModalType={setExternalLinkModalType} />
                </Box>

            }
            { (!isRegistrationAndOnboardingEnabled || canOrder2023Products) &&
                <Container isReservations={isReservationsEnabled==='true'}>
                    <HomepageAfterBannerCard />
                </Container>
            }
            {
                externalLinkModalType === "extrenalLink" && showNavigationAlert && !localStorage.getItem('dontRemindMeExternal') && <ExternalRouterPrompt
                isOpen={showNavigationAlert}
                closeModalHandler={() => closeNavigationAlert("extrenalLink")}
                externalLink={externalLink}
                onOpenLink={setShowNavigationAlert}
                type={"extrenalLink"}
                heading={staticContent?.externalLink?.header}
                headingStyle={{
                    fontFamily: "Aeonik Bold",
                    fontSize: "30px",
                    '@media screen and (max-width: 500px)': {
                        fontSize: '24px',
                        paddingBottom: "24px"
                    }
                }}
                content={staticContent?.externalLink?.subHeader}
                checkBoxLabel={staticContent?.externalLink?.remindMeText}
                buttonLabel={staticContent?.externalLink?.buttonLabel}
                />
            }
            <NewsContent setShowNavigationAlert={setShowNavigationAlert} setExternalLink={setExternalLink} setExternalLinkModalType={setExternalLinkModalType} />
        </div>
    );
};

export default NewHomePage;