import { Radio } from '../Radio'
import { Box, Typography } from '@mui/material'
import {styled} from "@mui/material/styles";

const RadioTitleWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  height: 33px;
  align-items: center;
`;
const DeliverScheduleComponent = ({reservationPageContent, setDeliverySchedule, deliverySchedule, isAuthenticated}) => {
    const handleShippingOptionChange = (event) => {
        setDeliverySchedule(event.target.value);
    };

    return (
        <>
            <Box mt={1}>
                <Box display="flex" alignItems={"flex-start"}>
                    <Radio
                        checked={deliverySchedule === 'INITIAL'}
                        size="medium"
                        name="deliverySchedule"
                        value="INITIAL"
                        onChange={handleShippingOptionChange}
                        disabled={!isAuthenticated}
                        radiosx={{
                            '&.Mui-checked': {
                                color: '#545F71',
                            },
                        }}
                    />
                    <RadioTitleWrapper>
                        <Typography
                            data-testid='deliverAllText'
                            variant={'h5'}>
                            {reservationPageContent?.deliverScheduleShipAllText}
                        </Typography>
                    </RadioTitleWrapper>
                </Box>
                <Box display="flex" flexDirection="column" width={'100%'} ml={3}>
                        {/*keeping the code for future use*/}
                        {/*<Box width={'70%'} minWidth={'300px'} maxWidth={'500px'} mt={1}>*/}
                        {/*    <img src={reservationPageContent?.deliverScheduleShipAllImg?.fields?.file?.url}*/}
                        {/*         alt='Deliver schedule image'*/}
                        {/*         data-testid='deliverScheduleShippingImg'*/}
                        {/*         style={{*/}
                        {/*             objectFit: 'contain',*/}
                        {/*             width:'100%'*/}
                        {/*         }}*/}
                        {/*    />*/}
                        {/*</Box>*/}
                </Box>
            </Box>

            <Box mt={2} mb={2}>
                <Box display="flex" alignItems={"flex-start"}>
                    <Radio
                        checked={deliverySchedule === 'SEASONAL'}
                        size="medium"
                        name="deliverySchedule"
                        value="SEASONAL"
                        onChange={handleShippingOptionChange}
                        disabled={!isAuthenticated}
                        radiosx={{
                            '&.Mui-checked': {
                                color: '#545F71',
                            },
                        }}
                    />
                    <RadioTitleWrapper>
                        <Typography
                            data-testid='deliverSeasonText'
                            variant={'h5'}>
                            {reservationPageContent?.deliverScheduleShipSeasonText}
                        </Typography>
                    </RadioTitleWrapper>
                </Box>
                <Box display="flex" flexDirection="column" width={'100%'} ml={3}>

                       {/* Keeping the code for future use}
                       {/*<Box width={'70%'} minWidth={'300px'} maxWidth={'500px'} mt={1}>*/}
                       {/*     <img src={reservationPageContent?.deliverScheduleShippingImg?.fields?.file?.url}*/}
                       {/*          alt='Deliver schedule image'*/}
                       {/*          data-testid='deliverScheduleShippingImg'*/}
                       {/*          style={{*/}
                       {/*              objectFit: 'contain',*/}
                       {/*              width:'100%'*/}
                       {/*          }}*/}
                       {/*     />*/}
                       {/* </Box>*/}

                    <Typography variant='p1' ml={1} >
                        You will be able to schedule your delivery dates later.
                    </Typography>
                </Box>
            </Box>
        </>
    )
}

export default DeliverScheduleComponent