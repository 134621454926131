import React, { useState } from "react";
import {DataGrid} from '@mui/x-data-grid';
import {Box, TextField, Typography} from "@mui/material";
import { useDispatch } from 'react-redux';
import { Button } from '../Button';
import { getReservationProductDetailsApi } from '../../features/commerce/commerceThunkApi';
import { useModal } from '../../hooks/useModal'

const ProductTable = ({ products, isAuthenticated, setOpenModal, handleDosesChange }) =>{
    const [productObj, setProductObj] = useState({ productId: '', doseQty: 0 });
    const dispatch = useDispatch();
    const { handleOpenModal } = useModal();

    const TitleComponent = (value) => {
        const row = value.value.row;
        return (
            <div>
                <Box display="flex" alignItems="center" height={1} mb={2}>
                    <Box height={1} component="img" src={row.imageUrl} alt={row.productName} style={{height: '33px', width: '33px'}}/>
                    <Box pl={2}>
                        <Typography style={{fontSize:'18px', fontWeight:'700', lineHeight:'28px'}}>
                            {row.productName}
                        </Typography>
                        {/* DO NOT DELETE CODE BELOW we are temporarily hiding product details until BLA approval  */}
                        {/* <Button 
                            sx={{padding: '0', textDecoration: 'none'}}
                            buttonType='link' 
                            onClick={() => handleClickProductDetails(row.productId)} 
                        >
                            <Typography sx={{textTransform: 'none'}} fontSize="14px" fontFamily="Aeonik Regular">
                                View product details
                            </Typography>
                        </Button> */}
                    </Box>
                </Box>
            </div>
        );
    };

    const handleClickProductDetails = (id) => {
        dispatch(getReservationProductDetailsApi({ productId: id })).unwrap()
        .then((data) => {
            setOpenModal(true);
        })
    }

    const columns = [
        {
            field: 'productName',
            headerName: 'Title',
            flex: 1,
            minWidth: 100,
            sortable: false,
            renderCell: (params) => (
                <TitleComponent value={params}/>
            ),
        },
        {
            field: 'listPrice',
            headerName: 'List Price',
            headerAlign: 'center',
            align: 'center',
            sortable: false,
        },
        {
            field: 'contractPrice',
            headerName: 'Contract Price',
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            minWidth: 125
        },
        {
            field: 'doses',
            headerName: 'Doses',
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: (params) => {
                let val = params?.value
                if (productObj?.productId === params.id) {
                    val = productObj?.doseQty
                }
                return (
                <TextField
                    type="number"
                    value={isAuthenticated ? val : 0}
                    onChange={(e) => updateTextVal(params.id, e.target.value)}
                    onBlur={(e) => handleDosesUpdate(params, e)}
                    onKeyDown={(e) => handleDosesUpdate(params, e)}
                    onClick={!isAuthenticated ? handleOpenModal : undefined}
                    inputProps={{min: 0}}
                    size="small"
                    variant="outlined"
                    sx={{
                        '& .MuiInputBase-input': {
                            color: !isAuthenticated && 'text.disabled',
                            cursor: !isAuthenticated && 'default',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: !isAuthenticated && 'text.disabled',
                        },
                    }}
                />)
            }
        },
    ];
    const filteredColumns = isAuthenticated ? columns : columns.filter(column => column.field === 'productName' || column.field === 'doses');

    const handleDosesUpdate = (product, event) => {
        const { id, value } = product
        const dose = Number(event.target.value);
        if (value === dose) return;
        if (event?.key === 'Enter' || event?.type === 'blur') {
            const doseQty = (Math.ceil(dose / 10)) * 10;
            setProductObj((prevState) =>({ ...prevState, productId: id, doseQty}));
            handleDosesChange(id, dose)
        }
    };

    const updateTextVal = (productId, doseQty) => {
        if (doseQty.charAt(0) === '0') {
            doseQty = doseQty.slice(1);
        }
        setProductObj((prevState) =>({...prevState, productId, doseQty: doseQty}));
    };

    return (
        <DataGrid
            autoHeight
            rowHeight={70}
            rows={products || []}
            columns={filteredColumns || []}
            hideFooter
            disableColumnFilter
            disableColumnMenu
        />
    );
}

export default ProductTable;
