import React from "react";
import { Box} from "@mui/material";
import CustomCard from "../CustomCardComponent/CustomCard";
import {InvoiceSummary, TypographyTitle, PaymentMethod} from "./OrderSummaryCard"

export const OrderDetailSummaryCard = ({summaryData}) => {
    return (
        <Box>
            <CustomCard
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "32px",
                    gap: "24px",
                }}
            >
                <TypographyTitle mb="24px" variant="h3" textTransform="capitalize">Summary</TypographyTitle>
                <InvoiceSummary summaryData={summaryData}/>
                <PaymentMethod/>
            </CustomCard>
        </Box>
    );
};