import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import { Box, Divider, Typography } from "@mui/material";
import CustomCard from "../CustomCardComponent/CustomCard";
import { formattedPrice } from '../../utils/Helper';
import { setCartData } from '../../features/commerce/commerceSlice';
import { UserAddressFormModal } from "../AddressesForm/UserAddressForm";
import ProductDetailModal from "../Reservation/ProductDetailModal";
import AddressSelectionModal from "../Reservation/AddressSelectionModal";
import OrderingAddressBlock from "./OrderingAddressBlock";
import OrderProductTable from "./OrderProductTable";
import { convertUserDataAddressToCartData } from "../../utils/HelperFunctions/OrderAndReservationHelper";
import MedicalLicenseSelectionModal from '../Onboarding/ShippingAddressAndLicense/MedicalLicenseSelectionModal';

const OrderDetailsCard = ({entries: cartLineItems, handleDosesChange, deleteAddressRow, updateDosesQty, orderPageContent, billingAddress, handlePoNumberChange }) => {
    const dispatch = useDispatch();
    const { cartData } = useSelector((store) => store?.commerce);
    const { authState } = useOktaAuth() || '';
    const isAuthenticated = authState?.isAuthenticated;
    const [openProductModal, setOpenProductModal] = useState(false);
    const [openAddressModal, setOpenAddressModal] = useState(false);
    const [showUserAddressFormModal, setShowUserAddressFormModal] = useState(false);
    const [openLicenseSelectionModal, setOpenLicenseSelectionModal] = useState(false);
    const [selectedMedicalLicense, setSelectedMedicalLicense] = useState({});
    
    
    const getMedicalLicenses = (licenses) => {
        return licenses.filter(license => license.issuer !== "DEA" && license.issuer !== "HIN" && license.issuer !== "NPI")
    };

    const medicalLicenses = getMedicalLicenses(useSelector(store => store?.commerce?.userData.licenses || []));

    const closeProductModal = () => {
        setOpenProductModal(false)
    }

    const closeAddressModal = () => {
        setOpenAddressModal(false)
    }

    const handleCloseLicenseSelectionModal = () => {
        setOpenLicenseSelectionModal(false)
    }

    const handleAddNewAddress = () => {
        setOpenAddressModal(false);
        setShowUserAddressFormModal(true);
    }

    const updateEntriesOnNewAddress = (newAddresses) => {
        if (Array.isArray(newAddresses) && newAddresses.length > 0) {
            const updatedCartLineItems = cartData?.cartLineItems.map((entry) => {
                return {...entry, shipToAddress: convertUserDataAddressToCartData(newAddresses)};
            });
            dispatch(setCartData({...cartData, cartLineItems: updatedCartLineItems}));
        }
    }

    let totalDoses = 0;
    let totalPrice = 0;

    if (isAuthenticated) {
        const result = cartLineItems.reduce(({totalDoses, totalPrice}, product) => {
            const pricePerDose = ( product.contractPriceValue !== undefined) ? product.contractPriceValue : product.listPriceValue;
            return {
                totalDoses: totalDoses + product.doses,
                totalPrice: totalPrice + product.doses * pricePerDose
            };
        }, {totalDoses: 0, totalPrice: 0});

        totalDoses = result.totalDoses;
        totalPrice = result.totalPrice;
        updateDosesQty(totalDoses);
    }

    const displayAddress = () => {
        const address = isAuthenticated ? cartLineItems[0]?.address : null;
        return address
    };

    const medicalLicensesForSelectedState = () => {
        return medicalLicenses.filter(license => license.state === cartLineItems[0]?.address?.state)
    }

    return (
        <>
            <ProductDetailModal openModal={openProductModal} closeModal={closeProductModal}/>
            {openAddressModal && <AddressSelectionModal
                openModal={openAddressModal}
                closeModal={closeAddressModal}
                currentAddress={displayAddress()}
                handleAddNewAddress={handleAddNewAddress}
                isOrdering={true}
            />}
                <UserAddressFormModal
                    openModal={showUserAddressFormModal}
                    closeModal={() => setShowUserAddressFormModal(false)}
                    handleNewAddresses={updateEntriesOnNewAddress}
                    addressType={"ship_to"}
                />
            <MedicalLicenseSelectionModal 
                isMedicalLicenseSelectionModalOpen={openLicenseSelectionModal}
                handleCloseModal={handleCloseLicenseSelectionModal}
                medicalLicensesForSelectedState={medicalLicensesForSelectedState()}
                setSelectedMedicalLicense={setSelectedMedicalLicense}
                currentAddress={displayAddress()}
            />
            <Box flexGrow={1} minHeight={'412px'} mt={5} mb={5}>
                <CustomCard
                    sx={{padding: "32px"}}
                >
                    <Box display="flex" flexGrow={1} flexDirection="column" alignContent={'space-between'}>
                        <Box display="flex" flexGrow={1} flexWrap={'wrap'} mb={3} gap={8} minHeight={'240px'}>
                            <Box minWidth={'240px'}>
                                <OrderingAddressBlock
                                    shipToAddress={displayAddress()}
                                    setOpenAddressModal={setOpenAddressModal}
                                    deleteAddressRow={deleteAddressRow}
                                    billToAddress={billingAddress}
                                    handlePoNumberChange={handlePoNumberChange}
                                    cartLineItems={cartLineItems} 
                                    setOpenLicenseSelectionModal={setOpenLicenseSelectionModal}
                                    selectedMedicalLicense={selectedMedicalLicense}
                                />
                            </Box>
                            <Box flex={1} width={'65%'}>
                                <OrderProductTable
                                    products={cartLineItems}
                                    isAuthenticated={isAuthenticated}
                                    handleDosesChange={handleDosesChange}
                                    setOpenModal={setOpenProductModal}
                                    orderPageContent={orderPageContent}
                                />
                                <Box minHeight={'72px'} mt={2}>
                                    <Divider orientation="horizontal" variant="middle" />
                                    <Box display="flex" flexDirection="row"  justifyContent="space-between" flexWrap={'wrap'} mt={2} ml={3}>
                                        <Box>
                                            <Typography data-testid="orderContentText" style={{fontSize:'14px', fontWeight:'400', lineHeight:'20px', whiteSpace: "pre-line"}}>
                                                {orderPageContent?.dosesMultipleOf10Text?.content[0]?.content[0]?.value}
                                            </Typography>
                                        </Box>
                                        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', minWidth: '48px'}}>
                                            <Typography style={{fontSize:'16px', fontWeight:'700', lineHeight:'22px'}}>{isAuthenticated ? `Total Doses: ${totalDoses}` : `Total Doses: 0`}</Typography>
                                            <Typography style={{fontSize:'16px', fontWeight:'400', lineHeight:'28px'}}>{isAuthenticated && `Total Price: ${formattedPrice(totalPrice)}`} </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </CustomCard>
            </Box>
        </>
    )
}
export default OrderDetailsCard
