import {createAsyncThunk} from "@reduxjs/toolkit";
import authAxiosInstance from "../../sevices/authAxiosInstance";

export const createCartApi = createAsyncThunk(
    "commerce/createCartApi",
    async (payload , { rejectWithValue }) => {
        try {
            const res = await authAxiosInstance({
                url: `orderservice/carts`,
                method: "POST",
                data: JSON.stringify(payload),
                params: {
                    userId: payload.userId
                },
            });
            return res?.data
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const getOrdersApi = createAsyncThunk(
    "commerce/getOrdersApi",
    async ({ userId } , { rejectWithValue }) => {
        try {
            const res = await authAxiosInstance({
                url: `orderservice/orders`,
                method: "GET",
                params: {
                    userId,
                    //todo: hard coding it now to fetch all orders for a user, in the future we should hook up pagination with api
                    pageSize:100
                },
            });
            return res?.data?.orders
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error.response.data);
        }
    }
)

export const getOrderByIdApi = createAsyncThunk(
    "commerce/getOrderByIdApi",
    async ({ userId, orderId }) => {
        try {
            const res = await authAxiosInstance({
                url: `orderservice/orders/${orderId}`,
                method: "GET",
                params: {
                    userId
                },
            });
            return res?.data?.order
        } catch (error) {
            if (!error.response) {
                throw error;
            }
        }
    }
)

export const createOrder = async (payload) => {
    try {
        const res = await authAxiosInstance({
            url: `orderservice/orders`,
            method: "POST",
            data: JSON.stringify(payload),
        });
        return res;
    } catch (error) {
        throw error;
    }
}