import { useState } from 'react';
import { useSelector } from 'react-redux'
import { useOktaAuth } from '@okta/okta-react'
import { Box, TextField, Typography } from '@mui/material'
import { styled } from "@mui/material/styles";
import MapIcon from '../../assets/images/mapIcon.svg';
import CoinIcon from '../../assets/images/coins.svg';
import MedicalInformationOutlinedIcon from '@mui/icons-material/MedicalInformationOutlined';
import { Button } from '../../components';
import { useModal } from '../../hooks/useModal';

const RequiredSymbol = styled('span')({
    color: 'red',
});

const OrderingAddressBlock = ({ shipToAddress, setOpenAddressModal, deleteAddressRow, billToAddress, handlePoNumberChange, cartLineItems , setOpenLicenseSelectionModal }) => {
    const { authState } = useOktaAuth() || '';
    const isAuthenticated = authState?.isAuthenticated;
    const locale = useSelector((state) => state.globalStates.locale);
    const { handleOpenModal } = useModal();
    const [addPONumberClicked, setAddPONumberClicked] = useState(false);
    const selectedMedicalLicense = cartLineItems?.[0]?.healthcareLicense;

    const openLoginModal = (redirectPath) => {
        handleOpenModal(redirectPath);
    }

    const onAddAddress = () => {
        !isAuthenticated && openLoginModal(`/${locale}/order-form`);
    }

    const internalCartLineItemId = cartLineItems?.[0]?.id;
    const handlePONumberUpdate = (event) => {
        handlePoNumberChange(internalCartLineItemId, event.target.value);
    };

    const handlePOButtonClick = () => {
        setAddPONumberClicked(true);
    }

    let customPadding = isAuthenticated ? "0 34px" : "0px";

    const PONumberSection = (
        <>
        {addPONumberClicked ?
            <>
                <Typography padding={customPadding} fontWeight="700" fontFamily="Aeonik Bold">P.O. number</Typography>
                <TextField
                    type="text"
                    size="small"
                    variant="outlined"
                    sx={{ padding: customPadding }}
                    onChange={handlePONumberUpdate}
                    inputProps={{ maxLength: 35 }}
                />
            </>
            :
            <Button 
                data-testid="addPONumberBtn" 
                buttonType='link' 
                sx={{textTransform: "none", padding: customPadding}} 
                onClick={handlePOButtonClick}>
                Add a P.O. number
            </Button>
        }
        </>
    );

    const DeleteAddressRow = ({requireStyle}) => {
        let customPadding = requireStyle ? "10px 34px" : "0px";
        return (
            <Button data-testid="deleteAddresRow" buttonType='link' sx={{textTransform: "none", padding: customPadding, color:"#E31737"}} onClick={deleteAddressRow}>Delete this row</Button>
        )
    }

    const AddressSection = ({addressType, iconType}) => {
        const addressInfo = {
            bill_to: {
                title: "Bill To: ",
                address: billToAddress,
                dbaName: ""
            },
            ship_to: {
                title: "Ship To: ",
                address: shipToAddress,
                dbaName: shipToAddress?.dba
            }
        };
        
        const { title: addressTitle, address, dbaName: addressDbaName } = addressInfo[addressType];

        return (
            <Box display="flex" gap="10px">
                <img src={iconType} alt="address icon" width="24" height="22" />
                <div>
                    <Typography fontWeight="700" fontFamily="Aeonik Regular">{addressTitle}</Typography>
                    <Typography fontWeight="700" fontFamily="Aeonik Regular">{addressDbaName}</Typography>
                    <Typography fontWeight="400" fontFamily="Aeonik Regular">{address?.line1}</Typography>
                    <Typography fontWeight="400" fontFamily="Aeonik Regular">{address?.line2}</Typography>
                    <Typography fontWeight="400" fontFamily="Aeonik Regular">
                        {`${address?.city}, ${address?.state}, ${address?.zip}`}
                    </Typography>
                </div>
            </Box>
        )
    }

    const AddAddressButton = ({addressType, iconType}) => {
        const buttonName = addressType === "ship_to" ? "Add shipping address" : "Add billing address";
        return (
            <Button
                buttonType={'mds-secondary'}
                leftIcon={iconType}
                sx={{ padding: "16px 32px", textTransform: "none", fontWeight: "700" }}
                onClick={onAddAddress}
                data-testid="addAddressBttn"
            >
                {buttonName} <RequiredSymbol> *</RequiredSymbol>
            </Button>
        )
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            gap="10px"
            paddingTop="25px"
            alignItems="flex-start"
        >
            {
            (isAuthenticated && shipToAddress && billToAddress) ?
                <>
                    <AddressSection addressType="ship_to" iconType={MapIcon} />
                    <Button data-testid="changeAddressBtn" buttonType='link' sx={{textTransform: "none", padding: "0 34px", marginBottom:'16px'}} onClick={() => {setOpenAddressModal(true)}}>Change</Button>
                    <AddressSection addressType="bill_to" iconType={CoinIcon} />
                    {Object.keys(selectedMedicalLicense).length > 0 ?
                        <>
                            <Box display="flex" gap="10px" marginTop={"16px"}>
                                <MedicalInformationOutlinedIcon sx={{color: "#383D47"}}/>
                                <div>
                                    <Typography fontWeight="700" fontFamily="Aeonik Regular">Medical License:</Typography>
                                    <Typography fontWeight="700" fontFamily="Aeonik Regular">
                                        {selectedMedicalLicense?.name}
                                    </Typography>
                                    <Typography fontWeight="400" fontFamily="Aeonik Regular">{`License No. ${selectedMedicalLicense?.licenseNumber}, Exp ${selectedMedicalLicense?.expirationDate}`}</Typography>
                                </div>
                            </Box> 
                            <Button data-testid="changeMedicalLicenseBtn" buttonType='link' sx={{textTransform: "none", padding: "0 34px", marginBottom:'16px'}} onClick={() => setOpenLicenseSelectionModal(true)}>Change</Button>
                        </>
                        :
                        <Button
                            buttonType={'link'}
                            sx={{ padding: "16px 32px", textTransform: "none" }}
                            onClick={() => setOpenLicenseSelectionModal(true)}
                            data-testid="addMedicalLicenseBttn"
                        >
                            Select a medical license <RequiredSymbol> *</RequiredSymbol>
                        </Button>
                    }
                    {PONumberSection}
                    <DeleteAddressRow requireStyle={true} />
                </> :
                <Box display="flex" flexDirection="column" gap={2}>
                    <AddAddressButton addressType="ship_to" iconType={MapIcon} />
                    <AddAddressButton addressType="bill_to" iconType={CoinIcon} />
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            ...(addPONumberClicked ? {gap: "10px"} : {
                                paddingLeft: "34px",
                                alignItems: "flex-start"
                            })
                        }}
                    >
                        {PONumberSection}
                        <DeleteAddressRow />
                    </Box>
                </Box>
            }
        </Box>
    )
}

export default OrderingAddressBlock
