import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@mui/material';
import CardComponent from '../CardComponent';

const isReservationsEnabled = process.env.IS_RESERVATIONS_ENABLED;

export const HomepageRightCard = () => {
    const locale = useSelector((state) => state.globalStates.locale);
    const { homePage: homePageContent } = useSelector((store) => store?.contentful);
    const history = useHistory();
    const theme = useTheme();

    return (
        <CardComponent 
            title={homePageContent?.rightCardTitle}
            description={homePageContent?.rightCardDescription}
            actions={[
            { label: 'Get Help', onClick: () => {history.push(`/${locale}/contact-us`) } 
            },
            ]}
            sx={{ 
                width: '60%',
                height: isReservationsEnabled === 'true' ? '296px': '192px',
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    height: '100%',
                    paddingBottom: '20px'
                },
            }}
        />
    );
};
