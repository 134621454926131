import {Grid, Typography, Link as HrefLink } from "@mui/material";
import React, { useEffect, useState,useRef, useMemo,useCallback } from "react";
import { useParams, Link } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";
import dayjs from "dayjs";
import {OrderDetailSummaryCard} from '../components/Ordering/OrderDetailSummaryCard';
import {getOrderableProductsApi } from "../features/commerce/commerceThunkApi";
import { setCurrentOrderData } from "../features/commerce/commerceSlice";
import OrderSummaryTable from '../components/Ordering/OrderSummaryTable';
import { getOrderByIdApi } from "../utils/OrderService/OrderApi";
import { AlertMessage, Spinner } from '../components';
import MessageBanner from "../components/MessageBanner/MessageBanner";
import {
    mapProductNames
} from "../utils/HelperFunctions/OrderAndReservationHelper";
    

const BannerStyle= {
    padding: "5px",
    color: "#0379B2"
}


const OrderDetailsPage = () => {
    const alertRef = useRef(null);
    const userData = useSelector((store) => store?.commerce?.userData);
    const dispatch = useDispatch();
    const orderableProducts = useSelector((store) => store?.commerce?.orderableProducts);
    const orderDetails = useSelector((store) => store?.commerce?.currentOrderData);
    const { userId } = userData;
    const { id:orderId } = useParams();
    const[processing, setProcessing] = useState(false);
    const[error,setError] = useState();

    useEffect( () => {
        try{
            setProcessing(true);
            dispatch(getOrderByIdApi({userId,orderId})).unwrap()
            .then((res) => {
                dispatch(setCurrentOrderData({currentOrderData:res}))
                setProcessing(false)
            });
        }catch(err){
            setError(err?.message);
            alertRef.current?.openAlert(err);
            setProcessing(false);
        }
    }, [dispatch, orderId, userId]);

    useEffect(() => {
        try{
            setProcessing(true);
            dispatch(getOrderableProductsApi({ catalogVersion: 'modernaProductCatalog' })).unwrap()
            .then(()=>{
                setProcessing(false);
            })
        }catch(err){
            setError(err?.message);
            alertRef.current?.openAlert(err);
            setProcessing(false);
        }
    }, [dispatch]);

    const productMap = mapProductNames(orderableProducts);

    const formattedOrderDetail = useMemo(()=>{
        return {
            subtotalListPrice:orderDetails?.orderPriceTotals?.subtotalListPrice?.displayValue,
            subtotalContractPrice:orderDetails?.orderPriceTotals?.subtotalContractPrice?.displayValue,
            totalTax:orderDetails?.orderPriceTotals?.totalTax?.displayValue,
            totalContractPrice:orderDetails?.orderPriceTotals?.totalContractPrice?.displayValue,
            purchaseOrderNumber:orderDetails?.purchaseOrderNumber,
            shipToAddress:{
                ...orderDetails?.shipToAddress
            },
            billToAddress:{
                ...orderDetails?.billToAddress
            },
            items:orderDetails?.orderLineItems?.map((item)=>{
                return {
                    //todo what to do if didn't find matched name for productId?
                    productName: productMap[item?.productId],
                    listPrice:item?.unitListPrice?.displayValue,
                    contractPrice:item?.unitContractPrice?.displayValue,
                    doses: item?.quantity
                }
            })
        }
    },[orderDetails,productMap]);

    const summaryData=useMemo(()=>{
        return {
            totalDoses: orderDetails?.totalQuantity || orderDetails?.orderLineItems?.reduce((sum, item) => sum + item.quantity, 0),
            totalPrice: orderDetails?.orderPriceTotals?.totalContractPrice?.displayValue,
            listPrice:orderDetails?.orderPriceTotals?.subtotalListPrice?.displayValue,
            contractPrice:orderDetails?.orderPriceTotals?.subtotalContractPrice?.displayValue,
            savings:orderDetails?.orderPriceTotals?.discountTotal?.displayValue,
            taxes:orderDetails?.orderPriceTotals?.totalTax?.displayValue
        }
    },[orderDetails]);

    const getBannerInfo=useCallback((orderStatus)=>{
        switch(orderStatus){
            case "ON_HOLD": 
            case "INTERNAL_HOLD":
                return {
                    type: "warning",
                    headerText: "This order has been placed on hold",
                    bannerContentComponent: 
                    (
                        <Typography>
                            We encountered an issue with some of your information. We will contact you to resolve the issue. To make changes to order or cancel 
                            <Link to="/contact-us" style={BannerStyle}>Contact us</Link>
                        </Typography>
                    ),
                    dataTestId:"OrderDetailStatusBanner"
                };
            case "CANCELLED":
                return {
                    type: "canceled",
                    headerText: "This order has been cancelled",
                    bannerContentComponent: 
                    (
                        <Typography>
                            This order has been cancelled. Please 
                            <Link to="/contact-us" style={BannerStyle}>Contact us</Link> 
                            for more information or for help replacing your order.
                        </Typography>
                    ),
                    dataTestId:"OrderDetailStatusBanner"
                };
            case "COMPLETED":
            case "FULFILLED":
                return {
                    type: "shipped",
                    headerText: `This order shipped on ${dayjs(orderDetails?.orderShippedDate, 'YYYY-MM-DD').format('MMMM D, YYYY')}`,
                    bannerContentComponent: 
                    (
                        <>
                        <Typography>
                            Carrier: UPS
                        </Typography>
                        {orderDetails?.shipment?.trackingId && 
                            <Typography>
                                Tracking number:
                                <HrefLink target="_blank" rel='#trackingNumber' href={`https://www.ups.com/track?trackingNumber=${orderDetails?.shipment?.trackingId}`} style={BannerStyle}>{orderDetails?.shipment?.trackingId}</HrefLink>
                            </Typography>
                        }
                        </>
                    ),
                    dataTestId:"OrderDetailStatusBanner"
                };
            case "CREATED":
            case "CONFIRMED":    
            default:
                return {
                    type: "info",
                    headerText: "This order is processing",
                    bannerContentComponent: 
                    (
                        <Typography>
                            For questions about your order please
                            <Link to="/contact-us" style={BannerStyle}>Contact us</Link>
                        </Typography>
                    ),
                    dataTestId:"OrderDetailStatusBanner"
                };
        }
    },[orderDetails?.orderId]);

    const bannerInfo= useMemo(()=>{
        return getBannerInfo(orderDetails?.orderStatus);
    },[getBannerInfo, orderDetails?.orderStatus]);
    
    return (
        <>
            <AlertMessage
            variant={"filled"}
            type={"error"}
            message={error}
            sx={{ top: 120 }}
            ref={alertRef}
            />
            <Spinner processing={processing} />
            <Grid
                data-testid="orderDetailContainer"
                container
                spacing={{ xs: 1, lg: 4 }}
                padding={{ xs: 2, md: 3, lg: 12 }}
            >
                {orderDetails?.orderStatus && 
                    <Grid item xs={12}>
                        <MessageBanner
                        type={bannerInfo?.type}
                        headerText={bannerInfo?.headerText}
                        bannerContentComponent={bannerInfo?.bannerContentComponent}
                        dataTestId={bannerInfo?.dataTestId}
                        />
                    </Grid>
                }
                <Grid item xs={12}>
                    <Typography
                        data-testid="orderDetailsPageHeader"
                        sx={{
                            fontSize: "30px",
                            fontWeight: 500,
                            lineHeight: "36px",
                            fontFamily: "Aeonik Regular",
                        }}
                    >
                    {`Order #${orderDetails?.orderNumber}`}
                    </Typography>
                </Grid>
                <Grid data-testid={"order-summary-table"} item xs={12} sm={8}>
                    <OrderSummaryTable orderDetail={formattedOrderDetail} sx={{'#boxContainer':  "33px" }}/>
                </Grid>
                <Grid data-testid={"order-summary-card"} item xs={12} sm={4} overflow="unset">
                    <OrderDetailSummaryCard summaryData={summaryData}/>
                </Grid>
            </Grid>
        </>
        )
}

export default OrderDetailsPage;
