import { createSlice } from "@reduxjs/toolkit";
import {
    loadLocaleISOCodeOptions,
    loadHomeStaticContent,
    loadProfileStaticContent,
    loadLandingStaticContent,
    loadManageReservationListStaticContent,
    loadManageReservationBannerStaticContent,
    loadHeaderStaticContent,
    loadFooterStaticContent,
    loadNotAuthorizedPageContent,
    loadNotFoundPageContent,
    loadImpersonationLookupContent,
    loadUserRegistrationLicenseStaticContent,
    loadUserRegistrationAccountStaticContent,
    loadUserRegistrationContactStaticContent,
    loadUserRegistrationPasswordStaticContent,
    loadUserRegistrationReviewStaticContent,
    loadUserRegistrationFinalStepStaticContent,
    loadResetPasswordStaticContent,
    loadRecoveryLinkStaticContent,
    loadNewPasswordStaticContent,
    loadTokenExpiredStaticContent,
    loadResetSuccessStaticContent,
    loadSessionHeaderStaticContent,
    loadProductsComponentStaticContent,
    loadProductDetailContentComponent,
    loadPreBookConfirmationStaticContent,
    loadContactUsStaticContent,
    loadFaqStaticContent,
    loadFaqLocaleContent,
    loadCartComponentStaticContent,
    loadInterstitialComponentStaticContent,
    loadCategoryHeroBannerComponentStaticContent,
    loadOrderEducationalCardContent,
    loadOrderSummaryComponentContent,
    loadContractAllocationComponent,
    loadCartComponentContent,
    loadOrderConfirmationComponent,
    loadOrderHistoryBannerComponent,
    loadOrderHistoryTableComponent,
    loadCheckoutPageComponentContent,
    loadCategoryProductTilesComponent,
    loadOrderDetailSummaryComponent,
    loadOrderItemGridTableComponent,
    loadAddressComponent,
    loadReservationPage,
    loadDatesComponent,
    loadHomePage,
    loadConfirmationModalComponent,
    loadOrderPage,
}
    from "./contentfulThunkApi";

const initialState = {
    error: "",
    loading: false,
    localeISOCodeOptions: [],
    homePageContent: {},
    profilePageContent: {},
    userRegistrationLicenseContent: {},
    userRegistrationAccountContent: {},
    userRegistrationContactContent: {},
    userRegistrationPasswordContent: {},
    userRegistrationReviewContent: {},
    userRegistrationFinalStepContent: {},
    landingPageContent: {},
    manageReservationListContent: {},
    manageReservationBannerContent: {},
    headerContent: {},
    footerContent: {},
    notAuthorizedPageContent: {},
    notFoundPageContent: {},
    impersonationLookupContent: {},
    productsComponentContent: {},
    productDetailContentComponent: {},
    preBookConfirmationContent: {},
    cartComponentContent: {},
    resetPasswordContent: {},
    recoveryLinkContent: {},
    newPasswordContent: {},
    tokenExpiredContent: {},
    resetSuccessContent: {},
    contactusPageContent: {},
    faqPageContent: {},
    faqLocaleContent: [],
    sessionHeaderContent: {},
    interstitialComponentContent: {},
    orderProductBannerComponentContent: {},
    orderSummaryComponentContent:{},
    contractAllocationComponentContent: {},
    cartContent: {},
    orderConfirmationComponent: {},
    orderHistoryBannerComponent: {},
    orderHistoryTableComponent: {},
    checkoutPageContent: {},
    categoryProductTilesComponent: {},
    orderDetailSummaryComponent: {},
    orderItemGridTableComponent: {},
    addressComponent: {},
    reservationPage:{},
    orderPage:{},
    homePage: {},
    datesComponent: {},
    confirmationModalComponent: {},
}


export const contentfulSlice = createSlice({
    name: "contentful",
    initialState,
    reducers: {
        clearContentfulStore: (state, action) => {
            return {
                ...initialState
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadLocaleISOCodeOptions.fulfilled, (state, { payload }) => {
            state.localeISOCodeOptions = payload;
        });
        builder.addCase(loadHomeStaticContent.fulfilled, (state, { payload }) => {
            state.homePageContent = payload;
        });
        builder.addCase(loadProfileStaticContent.fulfilled, (state, { payload }) => {
            state.profilePageContent = payload;
        });
        builder.addCase(loadUserRegistrationLicenseStaticContent.fulfilled, (state, { payload }) => {
            state.userRegistrationLicenseContent = payload;
        });
        builder.addCase(loadUserRegistrationAccountStaticContent.fulfilled, (state, { payload }) => {
            state.userRegistrationAccountContent = payload;
        });
        builder.addCase(loadUserRegistrationContactStaticContent.fulfilled, (state, { payload }) => {
            state.userRegistrationContactContent = payload;
        });
        builder.addCase(loadUserRegistrationPasswordStaticContent.fulfilled, (state, { payload }) => {
            state.userRegistrationPasswordContent = payload;
        });
        builder.addCase(loadUserRegistrationReviewStaticContent.fulfilled, (state, { payload }) => {
            state.userRegistrationReviewContent = payload;
        });
        builder.addCase(loadUserRegistrationFinalStepStaticContent.fulfilled, (state, { payload }) => {
            state.userRegistrationFinalStepContent = payload;
        });
        builder.addCase(loadLandingStaticContent.fulfilled, (state, { payload }) => {
            state.landingPageContent = payload;
        });
        builder.addCase(loadManageReservationListStaticContent.fulfilled, (state, { payload }) => {
            state.manageReservationListContent = payload;
        });
        builder.addCase(loadManageReservationBannerStaticContent.fulfilled, (state, { payload }) => {
            state.manageReservationBannerContent = payload;
        });
        builder.addCase(loadHeaderStaticContent.fulfilled, (state, { payload }) => {
            state.headerContent = payload;
        });
        builder.addCase(loadFooterStaticContent.fulfilled, (state, { payload }) => {
            state.footerContent = payload;
        });
        builder.addCase(loadNotAuthorizedPageContent.fulfilled, (state, { payload }) => {
            state.notAuthorizedPageContent = payload;
        });
        builder.addCase(loadNotFoundPageContent.fulfilled, (state, { payload }) => {
            state.notFoundPageContent = payload;
        });
        builder.addCase(loadImpersonationLookupContent.fulfilled, (state, { payload }) => {
            state.impersonationLookupContent = payload;
        });
        builder.addCase(loadProductDetailContentComponent.fulfilled, (state, { payload }) => {
            state.productDetailContentComponent = payload;
        });
        builder.addCase(loadProductsComponentStaticContent.fulfilled, (state, { payload }) => {
            state.productsComponentContent = payload;
        });
        builder.addCase(loadPreBookConfirmationStaticContent.fulfilled, (state, { payload }) => {
            state.preBookConfirmationContent = payload;
        });
        builder.addCase(loadCartComponentStaticContent.fulfilled, (state, { payload }) => {
            state.cartComponentContent = payload;
        });
        builder.addCase(loadResetPasswordStaticContent.fulfilled, (state, { payload }) => {
            state.resetPasswordContent = payload;
        });
        builder.addCase(loadRecoveryLinkStaticContent.fulfilled, (state, { payload }) => {
            state.recoveryLinkContent = payload;
        });
        builder.addCase(loadNewPasswordStaticContent.fulfilled, (state, { payload }) => {
            state.newPasswordContent = payload;
        });
        builder.addCase(loadTokenExpiredStaticContent.fulfilled, (state, { payload }) => {
            state.tokenExpiredContent = payload;
        });
        builder.addCase(loadResetSuccessStaticContent.fulfilled, (state, { payload }) => {
            state.resetSuccessContent = payload;
        });
        builder.addCase(loadInterstitialComponentStaticContent.fulfilled, (state, { payload }) => {
            state.interstitialComponentContent = payload;
        });
        builder.addCase(loadContactUsStaticContent.fulfilled, (state, { payload }) => {
            state.contactusPageContent = payload;
        });
        builder.addCase(loadFaqStaticContent.fulfilled, (state, { payload }) => {
            state.faqPageContent = payload;
        });
        builder.addCase(loadFaqLocaleContent.fulfilled, (state, { payload }) => {
            state.faqLocaleContent = payload;
        });
        builder.addCase(loadSessionHeaderStaticContent.fulfilled, (state, { payload }) => {
            state.sessionHeaderContent = payload;
        });
        builder.addCase(loadCategoryHeroBannerComponentStaticContent.fulfilled, (state, { payload }) => {
            state.orderProductBannerComponentContent = payload;
        });
        builder.addCase(loadOrderEducationalCardContent.fulfilled, (state, { payload }) => {
            state.orderEducationalCardsContent = payload;
        });
        builder.addCase(loadContractAllocationComponent.fulfilled, (state, { payload }) => {
            state.contractAllocationComponentContent = payload;
        });
        builder.addCase(loadOrderSummaryComponentContent.fulfilled, (state, { payload }) => {
            state.orderSummaryComponentContent = payload;
        });
        builder.addCase(loadCartComponentContent.fulfilled, (state, { payload }) => {
            state.cartContent = payload;
        });
        builder.addCase(loadOrderConfirmationComponent.fulfilled, (state, { payload }) => {
            state.orderConfirmationComponent = payload;
        });
        builder.addCase(loadOrderHistoryBannerComponent.fulfilled, (state, { payload }) => {
            state.orderHistoryBannerComponent = payload;
        });
        builder.addCase(loadOrderHistoryTableComponent.fulfilled, (state, { payload }) => {
            state.orderHistoryTableComponent = payload;
        });
        builder.addCase(loadCheckoutPageComponentContent.fulfilled, (state, { payload }) => {
            state.checkoutPageContent = payload;
        });
        builder.addCase(loadCategoryProductTilesComponent.fulfilled, (state, { payload }) => {
            state.categoryProductTilesComponent = payload;
        });
        builder.addCase(loadOrderDetailSummaryComponent.fulfilled, (state, { payload }) => {
            state.orderDetailSummaryComponent = payload;
        });
        builder.addCase(loadOrderItemGridTableComponent.fulfilled, (state, { payload }) => {
            state.orderItemGridTableComponent = payload;
        });
        builder.addCase(loadAddressComponent.fulfilled, (state, { payload }) => {
            state.addressComponent = payload;
        });
        builder.addCase(loadReservationPage.fulfilled, (state, { payload }) => {
            state.reservationPage = payload;
        });
        builder.addCase(loadOrderPage.fulfilled, (state, { payload }) => {
            state.orderPage = payload;
        });
        builder.addCase(loadHomePage.fulfilled, (state, { payload }) => {
            state.homePage = payload;
        });
        builder.addCase(loadDatesComponent.fulfilled, (state, { payload }) => {
            state.datesComponent = payload;
        });
        builder.addCase(loadConfirmationModalComponent.fulfilled, (state, { payload }) => {
            state.confirmationModalComponent = payload;
        });
    }
})

export const {clearContentfulStore} = contentfulSlice.actions;

export default contentfulSlice.reducer