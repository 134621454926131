import { Box, Typography } from '@mui/material'
import { styled } from "@mui/material/styles";
import {SelectDropdown} from "../SelectDropdown";
import {TextInput} from "../TextInput";
import React, {useRef, useState} from "react";
import CustomCard from "../CustomCardComponent/CustomCard";
import { useDispatch, useSelector } from "react-redux";
import {useHistory} from "react-router-dom";
import { Button } from '../Button';
import GroupAffiliationSelectionModal from './GroupAffiliationSelectionModal';
import { updateUserPreferencesApi } from "../../utils/UserService/UserApis";
import { Spinner } from "../Spinner";

const RequiredSymbol = styled('span')({
    color: 'red',
  });

const GroupAffiliationComponent = ({ isAuthenticated, reservationPageContent }) => {
    const { commerceUser } = useSelector((store) => store?.commerce);
    const GPOAccountFound = !! commerceUser?.data?.orgUnit?.gpoDetails?.length;
    const GPOName = commerceUser?.data?.orgUnit?.defaultGPO?.gpoName
    const locale = useSelector((state) => state.globalStates.locale);
    const history = useHistory();
    const dispatch = useDispatch();
    const { globalErrorMessage = [] } = useSelector(store => store?.globalMessages);
    const generalApiErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "GENERAL_API_ERROR");
    const generalApiError = generalApiErrorData && generalApiErrorData[0]?.fields?.message;
    const alertRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const userData = useSelector((store) => store?.commerce?.userData);
    const selectedGpoAffiliatedId = userData?.selectedContract?.id || '';

    const isGpoSelectionModalEnabled = process.env.IS_GPO_SELECTION_MODAL_ENABLED === 'true';
    const [openSelectionModal, setOpenSelectionModal] = useState(false);

    const isGpoAccountFound = () => {
        return isGpoSelectionModalEnabled ? !!userData?.selectedContract?.id : GPOAccountFound;
    }

    const getGpoName = () => {
        return isGpoSelectionModalEnabled ? userData?.selectedContract?.displayName : GPOName;
    }

    const handleConfirm = async (selectedPriceContract) => {
        const { id: priceContractId, displayName, medicalIdentifier, contractType } = selectedPriceContract;
        const updateUserPreferencesRequestBody = {
            userId: userData?.userId,
            priceContract: {
                id: priceContractId,
                displayName: displayName,
                medicalIdentifier: medicalIdentifier,
                priceReferenceType: contractType
            }
        }
        try {
            setIsLoading(true);
            await dispatch(updateUserPreferencesApi({payload: updateUserPreferencesRequestBody})).unwrap();
        } catch (error) {
            setErrorMsg(generalApiError);
            alertRef.current?.openAlert(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleChangeGroupAffiliation = () => {
        isGpoSelectionModalEnabled ?
          setOpenSelectionModal(true) :
          history.push(`/${locale}/my-profile/affiliations`);
    };

    return (
        <>
            <Spinner processing={isLoading} />
            {
                <Box display="flex" flexDirection="column" mt={3}>
                    { isGpoAccountFound() ? (
                        <CustomCard
                            sx={{padding: "16px 32px"}}
                        >
                            <Box display="flex">
                                <Box mr={2}>
                                    <Typography variant='h5' style={{fontSize: '18px'}}>Group affiliation</Typography>
                                </Box>
                                <Box>
                                    <Box display="flex" alignItems="center" mb={1}>
                                        <Typography variant='h5' style={{fontSize: '18px'}} mr={1}>
                                            {getGpoName()}
                                        </Typography>
                                        <Typography
                                            variant="p1"
                                            color='secondary.textLinks'
                                            onClick={ handleChangeGroupAffiliation }
                                            style={{cursor: 'pointer', textDecoration: 'underline', fontSize: '18px'}}
                                        >
                                            Change
                                        </Typography>
                                    </Box>
                                    <Typography variant='p1'>{reservationPageContent?.gpoAffiliationMessage}</Typography>

                                </Box>
                            </Box>
                        </CustomCard>
                    ) : (
                        <CustomCard
                            title={'Contract affiliation'}
                            sx={{padding: "16px 32px", display: 'flex', flexDirection: 'column'}}
                        >
                            <Typography variant='p1' mt={1}>{reservationPageContent?.gpoMissingText}</Typography>
                            { isGpoSelectionModalEnabled && <Button buttonType="mds-secondary" 
                                    sx={{ width: "260px", gap: "12px", 
                                        fontSize: "14px", fontWeight: 700, 
                                        textTransform:"none",
                                        padding: "16px 24px"
                                    }} 

                                    onClick={() => setOpenSelectionModal(true)}>
                                        Select a group affiliation
                                        <RequiredSymbol> *</RequiredSymbol>
                            </Button> }
                        </CustomCard>
                    )}
                    { openSelectionModal &&
                        <GroupAffiliationSelectionModal
                            openModal={openSelectionModal}
                            closeModal={()=> setOpenSelectionModal(false)}
                            handleConfirm={ handleConfirm }
                            defaultGpoAffiliatedId={selectedGpoAffiliatedId}
                        />
                    }
                </Box>
            }
        </>
        )
}

export default GroupAffiliationComponent
