import { Typography, Select, FormHelperText, MenuItem } from "@mui/material";
import { styled } from '@mui/material/styles';

const Dropdown = ({ name, label, options, value, onChangeHandler, isRequired, errors, dataTestId, isDisabled }) => {
    const RequiredSymbol = styled('span')({
        color: 'red',
    });

    const menuItemStyle = {
        '&.Mui-selected': {
            backgroundColor: '#FFFFFF',
            color: 'primary.main',
            fontFamily: 'Aeonik Bold',
        },
        fontFamily: 'Aeonik Regular', 
        fontSize: '14px',
    }

    const helperTextStyles = {
        '&.Mui-error': {
        color: '#E22929',
        fontFamily: 'Aeonik Regular',
        marginLeft: '0',
        fontSize: '14px',
        letterSpacing: '-0.28px'
        },
    }

    const FormLabel = ({ label, isRequired }) => {
        return (
            <Typography textAlign="left" paddingBottom='7px' fontFamily="Aeonik Regular" color="#191F2A" fontWeight="700" fontSize="16px">
                {label}{isRequired && <RequiredSymbol>*</RequiredSymbol>}
            </Typography>
        )
    }

    const selectFieldStyles = (name) => {
        return {
            minWidth: '100%',
            height: '48px',
            ".MuiSelect-select": {
                fontFamily: 'Aeonik Regular',
                textAlign: 'left'
            },
            "& .MuiFormHelperText-root": {
                minHeight: '20px',
            },
            ".MuiOutlinedInput-notchedOutline": {
                borderRadius: '6px',
                border: errors[name] ? '2px solid #E22929' : '1px solid #191F2A',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: errors[name] ? '2px solid #E22929' : '1px solid #191F2A',
            },
        }
    }
    
    return (
        <>
            {label && <FormLabel label={label} isRequired={isRequired}/>}
            <Select
                id={name}
                data-testid={dataTestId}
                value={value || ""}
                onChange={onChangeHandler}
                sx={selectFieldStyles(name)}
                error={Boolean(errors[name])}
                helpertext={errors[name]}
                disabled={isDisabled}
                MenuProps={{
                    PaperProps: {
                    sx: {
                        boxShadow: '0px 3px 6px #00000029',
                        borderRadius: '0px 0px 8px 8px',
                        maxHeight: 200,
                        maxWidth: 'fit-content',
                        overflow: 'auto',
                    },
                    },
                }}
            >
                {options?.map(({ value, label }) => (
                    <MenuItem sx={menuItemStyle} id={value} key={value} value={value}>
                        {value === '' ? '-' : label}
                    </MenuItem>
                ))}
            </Select>
            {errors[name] && (
                <FormHelperText 
                    sx={helperTextStyles} 
                    error={Boolean(errors[name])}
                >
                    {errors[name]}
                </FormHelperText>
        )}
        </>
    )
}

export default Dropdown