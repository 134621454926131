import { createAsyncThunk } from "@reduxjs/toolkit";
import noAuthAxiosInstance from "../../sevices/noAuthAxiosInstance";
import authAxiosInstance from "../../sevices/authAxiosInstance";


export const getCheckoutCart = createAsyncThunk(
    "commerce/checkoutCart",
    async ({cartId, userId}, { rejectWithValue }) => {
        try {
            const res = await authAxiosInstance({
                url: `orderservice/carts/${cartId}/checkout`,
                method: "PATCH",
                params: {
                    userId: userId,
                },
                });
            return res?.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error.response.data);
        }
    }
);