import { createAsyncThunk } from "@reduxjs/toolkit";
import noAuthAxiosInstance from "../../sevices/noAuthAxiosInstance";

export const createPricingApi = createAsyncThunk(
    "commerce/getPricing",
    async (payload, { rejectWithValue }) => {
        try {
            const res = await noAuthAxiosInstance({
                url: `pricingservice/pricing/anonymous`,
                method: "POST",
                data: JSON.stringify(payload)
              });
            return res?.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const getEligibleContractsApi = createAsyncThunk(
    "commerce/getPricingContracts",
    async ({payload, customerId}, { rejectWithValue }) => {
        try {
            const res = await noAuthAxiosInstance({
                url: `pricingservice/pricing/${customerId}/price-contracts`,
                method: "POST",
                data: JSON.stringify(payload)
              });

            if(!res?.data?.priceContracts){
                return [];
            }

            return res?.data?.priceContracts;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const getPricingApi = createAsyncThunk(
    "commerce/getAllPrices",
    async ({medicalIdentifier, payload}, { rejectWithValue }) => {
        try {
            const res = await noAuthAxiosInstance({
                url: `pricingservice/pricing/${medicalIdentifier}`,
                method: "POST",
                data: JSON.stringify(payload)
              });
            return res?.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error.response.data);
        }
    }
);
