import { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Tabs, Tab, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import MedicalIdentification from "../../components/Onboarding/medicalIdentification";
import LogoHeader from "../../components/Header/LogoHeader";
import LogoutFooter from "../../components/Footer/LogoutFooter";
import { OnboardingContactInformation } from "../../components/Onboarding/ContactInfo/contactInfo";
import ShippingAddressForm from "../../components/Onboarding/ShippingAddressAndLicense/ShippingAddressForm";
import BillingAddressForm from "../../components/Onboarding/BillingAddress/BillingAddress";

const OnboardingPage = () => {
    const [value, setValue] = useState(0);
	const { tab } = useParams();
	const history = useHistory();
	const TOTAL_TABS = 4;
	
	const tabMapping = {
		"contact-info": 0,
		"shipping-address": 1,
		"billing-address": 2,
		"medical-identification": 3,
	};
		
	useEffect(() => {
		const tabName = tab || 'contact-info';
        const tabIndex = tabMapping[tabName];
        setValue(tabIndex);
    }, [tab]);
	
	const getTabName = (index) => Object.keys(tabMapping).find(key => tabMapping[key] === index);

	const handleTabChange = (newTabIndex) => {
		if (newTabIndex < TOTAL_TABS) {
			const tabName = getTabName(newTabIndex);
			if (tabName) {
				setValue(tabMapping[tabName]);
				history.push(`/onboarding/${tabName}`);
			}
		}
	};
    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ p: 3 }} display={'flex'} flexDirection={'row'} alignItems={'flex-start'} justifyContent={'center'}>
                <Typography component="div" fontFamily={'Aeonik Regular'} fontSize={'24px'} fontWeight={700}>{children}</Typography>
              </Box>
            )}
          </div>
        );
    }
    
    CustomTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const CustomTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
        textTransform: 'none',
        fontSize:'16px',
        minWidth: 0,
        [theme.breakpoints.up('sm')]: {
          minWidth: 0,
        },
        fontWeight: 500,
        marginRight: theme.spacing(1),
        color: 'rgba(0, 0, 0, 0.85)',
        fontFamily: [
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Aeonik',
          'Aeonik Regular'
        ].join(','),
        '&.Mui-selected': {
          color: '#00557E',
          fontWeight: 500,
        },
        '&.Mui-focusVisible': {
          backgroundColor: '#d1eaff',
        },
    }));

    const StyledTabs = styled((props) => (
        <Tabs
          {...props}
          TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
        />
      ))({
        '& .MuiTabs-indicator': {
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: '#00557E',
        },
        '& .MuiTabs-indicatorSpan': {
          maxWidth: 10,
          width: '100%',
          backgroundColor: '#00557E',
        },
    });

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}
        padding='24px'
        >
            <Box alignItems='flex-start' >
                <LogoHeader />
                <Box sx={{ flex: 1 }}>
                    <StyledTabs
                        value={value}
                        sx={{ '.MuiTab-root': { marginRight: 20 } }}
                    >
                        <CustomTab label="Your Organization" {...a11yProps(0)} data-testid='contactInfoTab' />
                        <CustomTab label="Shipping Address and License" {...a11yProps(1)} data-testid='shippingAddressTab' />
                        <CustomTab label="Billing Address" {...a11yProps(2)} data-testid='billingAddressTab' />
                        <CustomTab label="Medical Identification" {...a11yProps(3)} data-testid='medicalIdentificationTab' />
                    </StyledTabs>
                    <CustomTabPanel value={value} index={0} data-testid='contactInfoPanel'>
                        <OnboardingContactInformation tabIndex={value} onTabChange={handleTabChange}/>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1} data-testid='shippingAddressPanel'>
                        <ShippingAddressForm tabIndex={value} onTabChange={handleTabChange} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2} data-testid='billingAddressPanel'>
                      Billing Address
                        <BillingAddressForm tabIndex={value} onTabChange={handleTabChange}/>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={3} data-testid='medicalIdentificationPanel'>
                      Medical Identification
                        <MedicalIdentification/>
                    </CustomTabPanel>
                </Box>
                <LogoutFooter />
            </Box>
        </Box>
    );
}

export default OnboardingPage;
