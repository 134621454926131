import { createAsyncThunk } from "@reduxjs/toolkit";
import authAxiosInstance from "../../sevices/authAxiosInstance";

export const createReservationApi = createAsyncThunk(
    "commerce/createReservationApi",
    async (payload , { rejectWithValue }) => {
        try {
            const res = await authAxiosInstance({
                url: `reservationservice/reservation`,
                method: "POST",
                data: JSON.stringify(payload),
                params: {
                    userId: payload.userId
                },
                headers: {
                    'Moderna-Email-Address': payload.userEmail,
                }
              });
            return res?.data
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const confirmReservationApi = createAsyncThunk(
    "commerce/confirmReservationApi",
    async ({ payload, reservationId, userEmail} , { rejectWithValue }) => {
        try {
            const res = await authAxiosInstance({
                url: `reservationservice/reservation/${reservationId}/confirm`,
                method: "PUT",
                data: JSON.stringify(payload),
                params: {
                    userId: payload.userId
                },
                headers: {
                    'Moderna-Email-Address': userEmail,
                }
            });
            return res?.data
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error.response.data);
        }
    }
    );

    export const updateReservationApi = createAsyncThunk(
        "commerce/updateReservationApi",
        async ({ payload } , { rejectWithValue }) => {
            try {
            const res = await authAxiosInstance({
                url: `reservationservice/reservation/${payload?.reservation?.reservationId}`,
                method: "PUT",
                data: JSON.stringify(payload),
                params: {
                    userId: payload.userId
                }
              });
            return res?.data
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const getReservationById = createAsyncThunk(
    "reservation/reservationId",
    async ({userId, reservationId }, { rejectWithValue }) => {
        try {
            const response = await authAxiosInstance({
                url: `reservationservice/reservation/${reservationId}?userId=${userId}`,
                method: "GET",
            });
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const getAllReservations = createAsyncThunk(
    "/reservations",
    async ({ userId }, { rejectWithValue }) => {
        try {
            const response = await authAxiosInstance({
                url: `reservationservice/reservations`,
                method: "GET",
                params: {
                    userId: userId
                }
            });
            return response.data;
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const cancelReservationApi = async ( userId, reservationId ) => {
    try {
        const res = await authAxiosInstance({
            url: `reservationservice/reservation/${reservationId}`,
            method: "PATCH",
            params: {
                userId: userId
            }
        });
        return res?.data
    } catch (error) {
        throw error
    }
};

export const cancelReservation = createAsyncThunk(
    "commerce/cancelReservation",
    async ({userId, reservationId } , { rejectWithValue }) => {
            try {
            const res = await authAxiosInstance({
                url: `reservationservice/reservation/${reservationId}`,
                method: "PATCH",
                params: {
                    userId: userId
                }
            });
            return res?.data
        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error.response.data);
        }
    }
);