import { Box, Typography } from '@mui/material'
import { styled } from "@mui/material/styles";
import { useEffect, useRef, useState } from "react";
import { loadReservationPage } from "../../features/contentful/contentfulThunkApi";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Spinner } from "../Spinner";
import CustomCard from "../CustomCardComponent/CustomCard";
import GroupAffiliationSelectionModal from "../Reservation/GroupAffiliationSelectionModal";
import { updateUserPreferencesApi } from "../../utils/UserService/UserApis";
import { Button} from "../Button";
import {AlertMessage} from "../AlertMessage";


const RequiredSymbol = styled('span')({
    color: 'red',
});

const GPOComponent = () => {
    const dispatch = useDispatch();
    const { globalErrorMessage = [] } = useSelector(store => store?.globalMessages);
    const generalApiErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "GENERAL_API_ERROR");
    const generalApiError = generalApiErrorData && generalApiErrorData[0]?.fields?.message;
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const userData = useSelector((store) => store?.commerce?.userData);
    const selectedGpoAffiliatedId = userData?.selectedContract?.id || '';
    const [openSelectionModal, setOpenSelectionModal] = useState(false);
    const { reservationPage: reservationPageContent } = useSelector((store) => store?.contentful);
    const alertRef = useRef(null);


    useEffect(() => {
        if (reservationPageContent && Object.keys(reservationPageContent).length === 0){
            dispatch(loadReservationPage());
        }
    }, [reservationPageContent, dispatch]);
    const isGPOSelected = () => {return !!userData?.selectedContract?.id}

    const handleConfirm = async (selectedPriceContract) => {
        const { id: priceContractId, displayName, medicalIdentifier, contractType } = selectedPriceContract;
        const updateUserPreferencesRequestBody = {
            userId: userData?.userId,
            priceContract: {
                id: priceContractId,
                displayName: displayName,
                medicalIdentifier: medicalIdentifier,
                priceReferenceType: contractType
            }
        }
        try {
            setIsLoading(true);
            await dispatch(updateUserPreferencesApi({payload: updateUserPreferencesRequestBody})).unwrap();
        } catch (error) {
            setErrorMsg(generalApiError);
            alertRef.current?.openAlert(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Spinner processing={isLoading} />
            <AlertMessage data-testid='GPOAlert' variant={"filled"} type={"error"} message={errorMsg} ref={alertRef} />
            {
                <Box display="flex" flexDirection="column" mt={2} minHeight={'50px'}>
                    { !isGPOSelected() ? (
                        <CustomCard
                            title={'Contract affiliation'}
                            sx={{padding: "16px 32px", display: 'flex', flexDirection: 'column'}}
                        >
                            <Typography variant='p1' mt={1} mb={1}>{reservationPageContent?.gpoMissingText}</Typography>
                            <Button
                                buttonType="mds-secondary"
                                data-testid="selectAffiliationBtn"
                                sx={{ width: "260px", gap: "12px",
                                    fontSize: "14px", fontWeight: 700,
                                    textTransform:"none",
                                    padding: "16px 24px"
                                }}
                                onClick={() => setOpenSelectionModal(true)}>
                                Select a contract affiliation
                            </Button>
                        </CustomCard>
                    )
                        :
                        (
                            <CustomCard
                                sx={{padding: "16px 32px"}}
                            >
                                <Box display="flex" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
                                    <Box display="flex" alignItems="center">
                                        <Typography variant='h5' style={{ fontSize: '18px', marginRight: '8px' }}>
                                            {'Contract affiliation:' + '\u00A0' + (userData?.selectedContract?.displayName || '')}
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            color='secondary.textLinks'
                                            data-testid="selectAffiliationBtn"
                                            onClick={() => setOpenSelectionModal(true)}
                                            style={{
                                                cursor: 'pointer',
                                                fontSize: '18px'
                                            }}
                                        >
                                            Change
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography>
                                            Incorrect pricing?
                                            <Link to={"/contact-us"} style={{ color: "#0379B2" }}> Contact us</Link>
                                        </Typography>
                                    </Box>
                                </Box>
                            </CustomCard>
                        )
                    }
                    { openSelectionModal &&
                        <GroupAffiliationSelectionModal
                            openModal={openSelectionModal}
                            closeModal={()=> setOpenSelectionModal(false)}
                            handleConfirm={ handleConfirm }
                            defaultGpoAffiliatedId={selectedGpoAffiliatedId}
                        />
                    }
                </Box>
            }
        </>
    )
}

export default GPOComponent
