import { Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";

import moment from 'moment';

export const validateName = (value) => {
    if (!value.trim()) return "Required";
    if (/[\d]/.test(value)) return "This field cannot contain numbers";
    if (!/^[a-zA-Z'.`\-\s]*$/.test(value)) return "This field cannot contain special characters";
    if (value.length > 50) return "Max 50 characters allowed";
    return "";
};

export const validateOrgLegalName = (value) => {
    if (!value.trim()) return "Required";
    if (value.length > 1000) return "Max 1000 characters allowed";
    return "";
};

export const validateRequiredField = (value) => {
    return !value.trim() ? "Required" : "";
};

export const validateZipcode = (value) => {
    if (!value.trim()) return "Required";
    if (!/^\d{5}(-\d{4})?$/.test(value)) return "Please enter a valid zipcode";
    return "";
};

export const validatePhoneNumber = (value, phoneNumbers) => {
    if (phoneNumbers.length === 0 && !value.trim()) return "Required";
    if (!/^(\d{3})[- ]?(\d{3})[- ]?(\d{4})$/.test(value)) return "Please enter a valid US phone number";
    return "";
};

export const validateEmail = (value) => {
    const pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!pattern.test(value)) {
        return "Please enter a valid email address";
    }
    return "";
};

export const validateShippingPhoneNumber = (value) => {
    if (!/^(\d{3})[- ]?(\d{3})[- ]?(\d{4})$/.test(value)) {
        return "Please enter a valid US phone number";
    }
    return "";
};

export const validateCity = (value) => {
    if (/[\d]/.test(value)) {
        return "This field cannot contain numbers";
    } else if (!/^[a-zA-Z'.`\-\s]*$/.test(value)) {
        return "This field cannot contain special characters";
    }
    return "";
};

export const validateTDDD = (value) => {
    if (!value.trim()) return "Required";
    if (value.length > 50) return "Max 50 characters allowed";
    return "";
}

export const formatStateOptions = (states) => states.map(state => ({
    value: state,
    label: state,
}));

export const validateExpirationDate = (value) => {
    return moment(value, "MM/DD/YYYY", true).isValid() ? "" : "Please enter a valid expiration date in the format MM/DD/YYYY"
};

const HINPattern = new RegExp("^[a-zA-Z0-9]{9}$");
const DEAPattern = new RegExp("^[a-zA-Z][a-zA-Z0-9][0-9]{7}$");
const NPIPattern = new RegExp(/^\d{10}$/);

export const validateMedicalIdentification = (name, value) => {
    const errorMessageText = `Please enter a valid ${name} separate with a space, tab or comma`
    const stringPattern = /^[ ,A-Za-z0-9\n]*$/
    let trimValue = value.trim()
    if(trimValue !== "" && !stringPattern.test(trimValue)) return errorMessageText
    const valueList = value.split(/[ ,\n]+/).map(s => s.trim()).filter(Boolean);

    for(const val of valueList){
        let pattern;
        if (name === 'HIN') {
            pattern = HINPattern;
        } else if (name === 'DEA') {
            pattern = DEAPattern;
        } else {
            pattern = NPIPattern;
        }
        if (!pattern.test(val)) {
            return errorMessageText;
        }
    }
    return '';
}

const RequiredSymbol = styled('span')({
    color: 'red',
});

export const FormLabel = ({ label, isRequired }) => {
    return (
        <Typography textAlign="left" paddingBottom='7px' fontFamily="Aeonik Regular" color="#191F2A" fontWeight="700" fontSize="16px">
            {label}{isRequired && <RequiredSymbol>*</RequiredSymbol>}
        </Typography>
    )
}

export const textFieldStyles = (errorName) => {
    return {
        "& input": {
            fontFamily: 'Aeonik Regular',
            padding: "12px",
            height: '24px',
            '&::placeholder': {
                fontStyle: 'italic',
            },
        },
        "& .MuiFormHelperText-root": {
            minHeight: '20px',
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '6px',
            '&:hover fieldset': {
                border: errorName ? '2px solid #E22929' : '1px solid #191F2A',
            },
            '&.Mui-focused fieldset': {
                border: errorName ? '2px solid #E22929' : '1px solid #191F2A',
            },
        },
    }
}
export const helperTextStyles = {
    sx: {
        '&.Mui-error': {
            color: '#E22929',
            fontFamily: 'Aeonik Regular',
            marginLeft: '0',
            fontSize: '14px',
            letterSpacing: '-0.28px'
        },
    },
}

