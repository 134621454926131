import { useState, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Container, Grid, TextField, Snackbar, SnackbarContent, useTheme } from "@mui/material";
import { styled } from '@mui/material/styles';
import dayjs from "dayjs";
import { Button } from "../../Button";
import { US_STATES } from "../../../constants";
import CheckIcon from "../../../assets/images/checkIconWhite.svg"
import Dropdown from "../Dropdown";
import { formatStateOptions, validateCity, validateEmail, validateRequiredField, validateShippingPhoneNumber, validateTDDD, validateZipcode } from "../Helper";
import MedicalLicenseFormModal from "./MedicalLicenseFormModal";
import MedicalLicenseSelectionModal from "./MedicalLicenseSelectionModal";
import MedicalLicenseCard from "./MedicalLicenseCard";
import { getSAPUserByEmail, getUserByEmail, updateUserDetailsApi } from "../../../utils/UserService/UserApis";
import { AlertMessage, Spinner } from "../../../components";
import { setUserStatus } from "../../../features/globalStates/globalStatesSlice";

const RequiredSymbol = styled('span')({
    color: 'red',
});

const buttonsContainerSxStyles = {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'left',
    gap: '24px'
}

const helperTextStyles = {
    sx: {
      '&.Mui-error': {
        color: '#E22929',
        fontFamily: 'Aeonik Regular',
        marginLeft: '0',
        fontSize: '14px',
        letterSpacing: '-0.28px'
      },
    },
}

const snackbarStyles = {
    justifyContent: 'center',
    borderRadius: '8px',
    backgroundColor: '#079AE0',
    padding: '10px 32px',
    boxShadow: '0px 3px 12px 0px rgba(0, 0, 0, 0.16)',
    '.MuiSnackbarContent-message': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '13px'
    }
}

const FormLabel = ({ label, isRequired }) => {
    return (
        <Typography textAlign="left" paddingBottom='7px' fontFamily="Aeonik Regular" color="#191F2A" fontWeight="700" fontSize="16px">
            {label}{isRequired && <RequiredSymbol>*</RequiredSymbol>}
        </Typography>
    )
}

export const ShippingAddressForm = ({ tabIndex, onTabChange, handleNewAddresses, closeModal }) => {
    const theme = useTheme()
    const defaultAddressFields = {
        dbaName: '',
        contactName: '',
        contactEmail: '',
        phoneNumber: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zipcode: '',
        zipcodeExt: '',
        tddd: '',
    }

    const [savedShippingAddresses, setSavedShippingAddresses] = useState([]);
    const [addressInputFields, setAddressInputFields] = useState(defaultAddressFields);
    const [selectedMedicalLicense, setSelectedMedicalLicense] = useState({});
    const dispatch = useDispatch();
    const [medicalLicensesForSelectedState, setMedicalLicensesForSelectedState] = useState([]);
    const [errors, setErrors] = useState({});
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [isAddingAddress, setIsAddingAddress] = useState(false);
    const [openLicenseFormModal, setOpenLicenseFormModal] = useState(false);
    const [openLicenseSelectionModal, setOpenLicenseSelectionModal] = useState(false);
    const { globalErrorMessage = [] } = useSelector(store => store?.globalMessages);
    const generalApiErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "GENERAL_API_ERROR");
    const generalApiError = generalApiErrorData && generalApiErrorData[0]?.fields?.message;
    const alertRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const userData = useSelector((store) => store?.commerce?.userData);
    const userAddresses = userData?.addresses || [];
    const userStatus = useSelector((store) => store?.globalStates?.userStatus)

    const getMedicalLicenses = (licenses) => {
        return licenses.filter(license => license.issuer !== "DEA" && license.issuer !== "HIN" && license.issuer !== "NPI")
    };

    const medicalLicenses = getMedicalLicenses(useSelector(store => store?.commerce?.userData.licenses || []));
    const getSnackbarMessage = () => {
        return (
            <>
                <img src={CheckIcon} alt="success icon" width={'32px'} height={'32px'}/>
                <Typography fontFamily={'Aeonik Regular'} fontSize={'20px'} fontWeight={'700'}>
                    {snackbarMessage}, saved
                </Typography>
            </>
        )
    }

    const USStates = formatStateOptions(US_STATES);
    const requiredFields = ['dbaName', 'contactName', 'addressLine1', 'city', 'state', 'zipcode'];

    const textFieldStyles = (name) => {
        return {
            "& input": {
                fontFamily: 'Aeonik Regular',
                padding: "12px",
                height: '24px',
                '&::placeholder': {
                    fontStyle: 'italic',
                },
            },
            "& .MuiFormHelperText-root": {
                minHeight: '20px',
            },
            '& .MuiOutlinedInput-root': {
                borderRadius: '6px',
                '&:hover fieldset': {
                    border: errors[name] ? '2px solid #E22929' : '1px solid #191F2A',
                },
                '&.Mui-focused fieldset': {
                    border: errors[name] ? '2px solid #E22929' : '1px solid #191F2A',
                },
            },
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        const newInputFields = { ...addressInputFields, [name]: value };
        setAddressInputFields(newInputFields);
    };

    const handleBlur = (event) => {
        const { name, value } = event.target;
        const errorMsg = validateField(name, value);
        setErrors({ ...errors, [name]: errorMsg });
    };

    const handleStateSelection = (event) => {
        const { value } = event.target
        const newInputFields = { ...addressInputFields, state: value }
        if (value !== 'OH') {
            newInputFields.tddd = ''
        }
        setAddressInputFields(newInputFields)
        setMedicalLicensesForSelectedState(medicalLicenses.filter(license => license.state === value))
    
        const newErrors = { ...errors, state: "" }
        setErrors(newErrors);
    };

    const handleAddAddress = async () => {
        const validationErrors = getValidationErrors()
        
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return
        }

        try {
            setIsLoading(true);
            const response = await updateUserDetailsApi(updateUserRequestBody);
            if (response?.status === 200) {
                const newAddress = {
                    dba: addressInputFields.dbaName,
                    line1: addressInputFields.addressLine1,
                    line2: addressInputFields.addressLine2,
                    city: addressInputFields.city,
                    state: addressInputFields.state,
                    zip: addressInputFields.zip,
                }
                setSavedShippingAddresses(savedAddresses => [...savedAddresses, newAddress])
                setSnackbarMessage(addressInputFields.dbaName)
                setIsSnackbarOpen(true)
                setAddressInputFields(defaultAddressFields);
                setSelectedMedicalLicense({})
                setIsAddingAddress(true)
            }
        } catch (error) {
            const errMsg = error?.response?.data?.message;
            setErrorMsg(errMsg || generalApiError);
            alertRef.current?.openAlert(error);
        } finally {
            setIsLoading(false);
        }
    }

    const handleCancelAddAddress = async () => {
        const response = await dispatch(getUserByEmail({ emailAddress: userData.email })).unwrap();
        dispatch(setUserStatus(response?.userStatus));
        onTabChange(tabIndex + 1);
    }

    const handleCloseLicenseFormModal = () => {
        setOpenLicenseFormModal(false)
    } 
    
    const handleCloseLicenseSelectionModal = () => {
        setOpenLicenseSelectionModal(false)
    }

    const handleOnAddLicense = () => {
        if (medicalLicensesForSelectedState.length) {
            setOpenLicenseSelectionModal(true)
        } else {
            setOpenLicenseFormModal(true)
        }
    }

    const removeLicense = () => {
        setSelectedMedicalLicense({})
    }

    const validateMedicalLicense = () => {
        const errors = {};
        const isLicenseSelected = Object.keys(selectedMedicalLicense).length > 0;
        
        if (!isLicenseSelected) {
            errors.medicalLicense = "Please add a medical license to continue.";
        } 
        else if (selectedMedicalLicense.state !== addressInputFields.state) {
            errors.medicalLicense = "The Medical license's state must match the Shipping address.";
        }
        
        return errors;
    };

    const getValidationErrors = () => {
        let validationErrors = validateMedicalLicense()

        Object.keys(addressInputFields).forEach((fieldName) => {
            const errorMsg = validateField(fieldName, addressInputFields[fieldName]);
            if (errorMsg) validationErrors[fieldName] = errorMsg;
        });

        return validationErrors
    }

    const updateUserRequestBody = {
        userId: userData?.userId,
        updateType: "shipping",
        organization: {
            orgType: "ship_to",
            dbaName: addressInputFields.dbaName, 
            contactEmail: addressInputFields.contactEmail,
            contactPhone: addressInputFields.phoneNumber,
            address: {
                addressType: "ship_to",
                attention: addressInputFields.contactName,
                line1: addressInputFields.addressLine1,
                line2: addressInputFields.addressLine2,
                city: addressInputFields.city,
                state: addressInputFields.state,
                zip: addressInputFields.zipcode,
                zipExtension: addressInputFields.zipcodeExt,
                tddd: addressInputFields.tddd, 
                licenses: [
                    {
                        licenseType: selectedMedicalLicense.licenseType, 
                        firstName: selectedMedicalLicense.firstName,
                        lastName: selectedMedicalLicense.lastName,
                        name: selectedMedicalLicense.name,
                        number: selectedMedicalLicense.number, 
                        state: selectedMedicalLicense.state, 
                        expiration: dayjs(selectedMedicalLicense.expiration, 'MM/DD/YYYY').format('YYYY-MM-DD'),
                    }
                ]
            } 
        }
    }

    const filterNewAddresses = (previousAddresses, newResponseAddresses) => {
        const prevAddrIds = new Set(previousAddresses.map(addr => addr.id));

        return newResponseAddresses.filter(newAddr =>
          !prevAddrIds.has(newAddr.id) && newAddr.addressType === 'ship_to');
    }
    
    const handleSubmit = async (event) => {
        event.preventDefault();

        const validationErrors = getValidationErrors()

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        
        try {
            setIsLoading(true);
            const response = await updateUserDetailsApi(updateUserRequestBody);
            if (response?.status === 200) {
                if (handleNewAddresses) {
                    // Ideally BE API will return the new addresses to FE, here it's workaround
                    let newAddresses = filterNewAddresses(userAddresses, response.data?.addresses);
                    handleNewAddresses(newAddresses);
                }
                closeModal && closeModal()
                await dispatch(getSAPUserByEmail({ emailAddress: response?.data?.email })).unwrap();
                await dispatch(getUserByEmail({ emailAddress: response?.data?.email })).unwrap();
                dispatch(setUserStatus(response?.data?.userStatus));
                setAddressInputFields(defaultAddressFields);
                onTabChange(tabIndex + 1);
            }
        } catch (error) {
            const errMsg = error?.response?.data?.message;
            setErrorMsg(errMsg || generalApiError);
            alertRef.current?.openAlert(error);
        } finally {
            setIsLoading(false);
        }
    };

    const validateField = (name, value) => {
        let errorMsg = "";
        if (requiredFields.includes(name) && value.trim() === '') {
            errorMsg = validateRequiredField(value)
        } else if (name === "contactEmail" && value) {
            errorMsg = validateEmail(value)
        } else if (name === "phoneNumber" && value) {
            errorMsg = validateShippingPhoneNumber(value)
        } else if (name === "city") {
            errorMsg = validateCity(value)
        } else if (name === "zipcode") {
            errorMsg = validateZipcode(value)
        } else if (name === "tddd" && addressInputFields.state === "OH") {
            errorMsg = validateTDDD(value)
        }
        return errorMsg;
    };

    const createTextField = (name, label, value, onChange, onBlur, isRequired, placeholder) => {
        return (
            <>
                <FormLabel label={label} isRequired={isRequired}/>
                <TextField
                    id={name}
                    data-testid={name}
                    name={name}
                    type="text"
                    value={value}
                    placeholder={placeholder}
                    onChange={onChange}
                    onBlur={onBlur}
                    focused
                    variant="outlined"
                    fullWidth
                    sx={textFieldStyles(name)}
                    error={Boolean(errors[name])}
                    helperText={errors[name]}
                    FormHelperTextProps={helperTextStyles}
                    inputProps={{
                        'data-testid': `${name}-input`
                    }}
                />
            </>
        )
    }

    const ShippingAddressForm = (
        <>
            <Spinner processing={isLoading} />
            <AlertMessage variant={"filled"} type={"error"} message={errorMsg} sx={{ top: 120 }} ref={alertRef} />
            <form onSubmit={handleSubmit}>
                <MedicalLicenseFormModal 
                    isMedicalLicenseFormModalOpen={openLicenseFormModal}
                    handleCloseModal={handleCloseLicenseFormModal}
                    selectedState={addressInputFields.state}
                    setSelectedMedicalLicense={setSelectedMedicalLicense}
                    setAddressFormErrors={setErrors}
                    addressFormErrors={errors}
                />
                <MedicalLicenseSelectionModal 
                    isMedicalLicenseSelectionModalOpen={openLicenseSelectionModal}
                    handleCloseModal={handleCloseLicenseSelectionModal}
                    medicalLicensesForSelectedState={medicalLicensesForSelectedState}
                    setSelectedMedicalLicense={setSelectedMedicalLicense}
                    setOpenLicenseFormModal={setOpenLicenseFormModal}
                    setAddressFormErrors={setErrors}
                    addressFormErrors={errors}
                />
                <Container data-testid='onboardingShippingAndLicenseInfo'>
                    <Snackbar 
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        autoHideDuration={3000}
                        open={isSnackbarOpen}
                        onClose={() => {setIsSnackbarOpen(false)}}
                    >
                        <SnackbarContent
                            message={getSnackbarMessage()}
                            sx={snackbarStyles}
                        />
                    </Snackbar>
                    {savedShippingAddresses?.length ? (
                        <Box data-testid='savedAddressesSection' display={'flex'} flexDirection={'column'} gap={'24px'} marginY={'28px'}>
                            <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
                                <Typography fontSize={'20px'} fontWeight={700} textAlign={'left'} fontFamily="Aeonik Regular" color="#191F2A">Saved Addresses</Typography>
                                {savedShippingAddresses.map((address, i) => {
                                    return (
                                        <Typography key={i} fontFamily={'Aeonik Regular'}>
                                            <span style={{ fontWeight: '700' }}>{address.dba},</span> 
                                            {` ${address.line1}${address.line2 ? ` ${address.line2}` : ''}, ${address.city}, ${address.state}`}
                                        </Typography>
                                    )
                                })}
                            </Box>
                        </Box>
                        ) : undefined
                    }
                    <Box display={'flex'} justifyContent={'left'} padding={'20px 0px'} flexDirection={'column'} gap={2}>
                        <Typography fontSize={'24px'} fontWeight={700} textAlign={'left'} fontFamily="Aeonik Regular" color="#191F2A">Shipping Address</Typography>
                        <Typography fontSize={'16px'} fontWeight={400} textAlign={'left'} fontFamily="Aeonik Regular" color="#191F2A"><RequiredSymbol>*</RequiredSymbol> Required field</Typography>
                    </Box>
                    <Grid container data-testid='facilityInfoSection' rowSpacing={'24px'} columnSpacing={'16px'} justifyContent="start" alignItems="flex-start" marginBottom={'48px'}>
                        <Grid item xs={12} paddingBottom={'1px'}>
                            {createTextField("dbaName", 'Facility "Doing business as" name', addressInputFields.dbaName, handleChange, handleBlur, true, 'e.g., Riverside Hospital, Greenleaf Community Pharmacy')}
                        </Grid>
                        <Grid item xs={12} paddingBottom={'1px'}>
                            {createTextField("contactName", "Facility contact person or department", addressInputFields.contactName, handleChange, handleBlur, true, 'e.g., Johnathan Doe, Pharmacy')}
                        </Grid>
                        <Grid item xs={12} sm={6} paddingBottom={'1px'}>
                            {createTextField("contactEmail", "Facility contact email", addressInputFields.contactEmail, handleChange, handleBlur, false, 'Optional')}
                        </Grid>
                        <Grid item xs={12} sm={6} paddingBottom={'1px'}>
                            {createTextField("phoneNumber", "Facility contact phone", addressInputFields.phoneNumber, handleChange, handleBlur, false, 'Optional')}
                        </Grid>
                    </Grid>
                    <Grid container data-testid='addressInfoSection' rowSpacing={'24px'} columnSpacing={'16px'} justifyContent="start" alignItems="flex-start" marginBottom={'48px'}>
                        <Grid item xs={12} paddingBottom={'1px'}>
                            {createTextField("addressLine1", "Address Line 1", addressInputFields.addressLine1, handleChange, handleBlur, true, 'e.g., 123 Main St')}
                            <Typography textAlign={'left'} fontFamily={'Aeonik Regular'} fontWeight={'400'} letterSpacing={'-0.28px'}>Note: Orders cannot be delivered to P.O. Boxes</Typography>
                        </Grid>
                        <Grid item xs={12} paddingBottom={'1px'}>
                            {createTextField("addressLine2", "Address Line 2", addressInputFields.addressLine2, handleChange, handleBlur, false, 'e.g., Dock 5, Ste 301')}
                        </Grid>
                        <Grid item xs={12} sm={4} paddingBottom={'1px'}>
                            {createTextField("city", "City", addressInputFields.city, handleChange, handleBlur, true)}
                        </Grid>
                        <Grid item xs={12} sm={4} paddingBottom={'1px'}>
                            <Dropdown
                                dataTestId={"stateDropdownSelection"}
                                name={"state"}
                                label={"State"}
                                options={USStates}
                                value={addressInputFields.state}
                                onChangeHandler={handleStateSelection}
                                isRequired={true}
                                errors={errors}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2} paddingBottom={'1px'}>
                            {createTextField("zipcode", "ZIP Code", addressInputFields.zipcode, handleChange, handleBlur, true)}
                        </Grid>
                        <Grid item xs={12} sm={2} paddingBottom={'1px'}>
                            {createTextField("zipcodeExt", "ZIP +4", addressInputFields.zipcodeExt, handleChange, handleBlur, false)}
                        </Grid>
                        {addressInputFields.state === 'OH' && 
                            <Grid item xs={12} paddingBottom={'1px'}>
                                {createTextField("tddd", "Terminal Distributer of Dangerous Drugs License (TDDD License)", addressInputFields.tddd, handleChange, handleBlur, true, 'Required for orders in the state of Ohio')}
                            </Grid>
                        }
                    </Grid>
                    <Box data-testid='medicalLicenseSection' display={'flex'} flexDirection={'column'} gap={'24px'} marginBottom={'48px'}>
                        <Box>
                            <Typography fontSize={'20px'} fontWeight={700} textAlign={'left'} fontFamily="Aeonik Regular" color="#191F2A">Medical Licenses<RequiredSymbol>*</RequiredSymbol></Typography>
                            <Typography fontSize={'14px'} textAlign={'left'} fontFamily="Aeonik Regular" color="#191F2A">
                                Shipping address must be associated with a valid license before your order ships.
                            </Typography>
                        </Box>
                        {Object.keys(selectedMedicalLicense).length > 0 ? 
                            <MedicalLicenseCard selectedMedicalLicense={selectedMedicalLicense} removeLicense={removeLicense} /> :
                            <Box sx={buttonsContainerSxStyles}>
                                <Button 
                                    sx={{ padding: "16px 24px", textTransform: "none" }}
                                    buttonType="mds-primary"
                                    data-testid='addLicenseBtn'
                                    onClick={handleOnAddLicense}
                                >
                                    Add a medical license
                                </Button>
                            </Box>
                        }
                        {errors.medicalLicense && <Typography textAlign={'left'} color={'#E22929'} fontFamily={'Aeonik Regular'}>{errors.medicalLicense}</Typography>}
                    </Box>
                    <Box data-testid='buttonsSection' sx={buttonsContainerSxStyles}>
                        <Button 
                            type="submit"
                            id="submit"
                            sx={{ padding: "16px 24px", textTransform: "none" }}
                            buttonType="mds-primary"
                            data-testid='continueBtn'
                        >
                            Continue
                        </Button>
                        {userStatus !== 'ACTIVE' && 
                            <Button 
                                sx={{ padding: "16px 24px", textTransform: "none" }}
                                buttonType="mds-secondary"
                                data-testid='addAddressBtn'
                                onClick={handleAddAddress}
                            >
                                Save and add another address
                            </Button>
                        }
                        {isAddingAddress &&
                            <Button 
                                buttonType="link"
                                data-testid='cancelAddAddressBtn'
                                onClick={handleCancelAddAddress}
                            >
                                <Typography fontFamily={'Aeonik Regular'} fontSize={'14px'} letterSpacing={'-0.28px'} textTransform={'none'}>
                                    Discard and continue
                                </Typography>
                            </Button>
                        }
                    </Box>
                </Container>
            </form>
        </>
    )

    return (
        <Box
            data-testid="onboardingShippingAddressTab" 
            maxWidth={'700px'}
            display={'flex'}
            sx={{
                [theme.breakpoints.down('md')]: {
                    maxWidth: '600px',
                },
                [theme.breakpoints.down('sm')]: {
                    width: '350px',
                },
            }}
        >
            {ShippingAddressForm}
        </Box>
    )
}

export default ShippingAddressForm