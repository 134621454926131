import React, {useRef, useState} from "react";
import { Box, Typography, Container, Grid, useTheme, IconButton, InputAdornment, TextField, List, ListItem } from "@mui/material";
import { styled } from '@mui/material/styles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { ModalComponent } from "./index";
import { Button } from "../Button";
import UncheckedCircle from '../../assets/images/uncheckedCircle.svg'
import CheckedCircle from '../../assets/images/checkedCircle.svg'
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { createIdentity } from "../../utils/Registration/RegistrationAPI";
import {AlertMessage} from "../AlertMessage";
import { setEmailAddress } from "../../features/globalStates/globalStatesSlice";

const RequiredSymbol = styled('span')({
    color: 'red',
});

const buttonsContainerSxStyles = {
    flexDirection: {xs: 'column', sm:'row'},
    display:"flex",
    justifyContent:'left',
    marginY: "24px",
}

const helperTextStyles = {
    sx: {
        '&.Mui-error': {
            color: '#E22929',
            fontFamily: 'Aeonik Regular',
            marginLeft: '0',
            fontSize: '14px',
            letterSpacing: '-0.28px'
        },
    },
}

const FormLabel = ({ label }) => {
    return (
        <Typography textAlign="left" paddingBottom='7px' fontFamily="Aeonik Regular" color="neutral.gray7" fontWeight="700">
            {label}<RequiredSymbol>*</RequiredSymbol>
        </Typography>
    )
}

export const CreateAccountModal = ({isCreateAccountModalOpen, handleCloseModal}) => {
    const theme = useTheme();

    const defaultFields = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: ''
    }

    const defaultPasswordCriteria = {
        minLength: false,
        containsLowercase: false,
        containsUppercase: false,
        containsNumber: false,
        containsSpecialChar: false,
        passwordsMatch: false,
    }

    const [inputFields, setInputFields] = useState(defaultFields);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordCriteria, setPasswordCriteria] = useState(defaultPasswordCriteria);
    const [errors, setErrors] = useState({})
    const dispatch = useDispatch();
    const history = useHistory();
    const locale = useSelector((state) => state.globalStates.locale);
    const alertRef = useRef(null);
    const { globalErrorMessage = [] } = useSelector(store => store?.globalMessages);
    const generalApiErrorData = globalErrorMessage && globalErrorMessage.length && globalErrorMessage.filter((data) => data?.fields?.code === "GENERAL_API_ERROR");
    const generalApiError = generalApiErrorData && generalApiErrorData[0]?.fields?.message;
    const [processing, setProcessing] = useState(false);

    const UncheckedCircleIcon = () => {
        return <img src={UncheckedCircle} alt="circle icon" width="24px" height="24px" />
    }

    const CheckedCircleIcon = () => {
        return <img src={CheckedCircle} alt="checked circle icon" width="24px" height="24px" />
    }

    const PasswordCriteriaListItem = (criteria, message) => {
        return (
            <ListItem sx={{display: 'flex', gap: '8px', padding: '0'}}>
                {passwordCriteria[criteria] ? <CheckedCircleIcon /> : <UncheckedCircleIcon />}
                <Typography color="neutral.gray7" fontFamily="Aeonik Regular" letterSpacing="-0.32px">{message}</Typography>
            </ListItem>
        )
    }

    const textFieldStyles = (name) => {
        return {
            "& input": {
                padding: "12px",
                height: '24px',
            },
            '& .MuiOutlinedInput-root': {
                borderRadius: '6px',
                '&:hover fieldset': {
                    border: errors[name] ? '2px solid #E22929' : '1px solid #545F71',
                },
                '&.Mui-focused fieldset': {
                    border: errors[name] ? '2px solid #E22929' : '1px solid #545F71',
                },
            },
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        const newInputFields = { ...inputFields, [name]: value };
        setInputFields(newInputFields);

        if (name === 'password' || name === 'confirmPassword') {
            validatePassword(newInputFields.password, newInputFields.confirmPassword);
        }
    };

    const handleBlur = (event) => {
        const { name, value } = event.target;
        const errorMsg = validateField(name, value, inputFields.password);
        setErrors({ ...errors, [name]: errorMsg });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const validationErrors = {};
        Object.keys(inputFields).forEach((fieldName) => {
            const errorMsg = validateField(fieldName, inputFields[fieldName], inputFields.password);
            if (errorMsg) validationErrors[fieldName] = errorMsg;
        });

        if (Object.keys(validationErrors).length === 0) {
            const submitData = {
                firstName: inputFields.firstName,
                lastName: inputFields.lastName,
                emailAddress: inputFields.email,
                password: inputFields.password,
            }
            try {
                setProcessing(true);
                const response = await dispatch(createIdentity({payload: submitData})).unwrap();
                dispatch(setEmailAddress(inputFields.email));
                setInputFields(defaultFields)
                setPasswordCriteria(defaultPasswordCriteria)
                if (response.status === 200) {
                    localStorage.setItem('awaitingEmailVerification', 'true');
                    setProcessing(false);
                    history.push(`/${locale}/registration/email-verification`);
                    handleCloseModal();
                }
            } catch (error) {
                if(error?.status === 409){
                    setErrors({email: 'This email address already exists. Please try logging in.'})
                }
                else{
                    alertRef.current?.openAlert(error);
                }
            }

        } else {
            setErrors(validationErrors);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }

    const validatePassword = (password, confirmPassword) => {
        const minLength = password.length >= 12;
        const containsLowercase = /[a-z]/.test(password);
        const containsUppercase = /[A-Z]/.test(password);
        const containsNumber = /\d/.test(password);
        const containsSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
        const passwordsMatch = password === confirmPassword;

        setPasswordCriteria({
            minLength,
            containsLowercase,
            containsUppercase,
            containsNumber,
            containsSpecialChar,
            passwordsMatch,
        });
    };

    const validateField = (name, value, password) => {
        let errorMsg = "";
        if (!value.trim()) {
            errorMsg = "Required";
        } else if (name === "firstName" || name === "lastName") {
            if (/[\d]/.test(value)) {
                errorMsg = "This field cannot contain numbers";
            } else if (!/^[a-zA-Z'.`\-\s]*$/.test(value)) {
                errorMsg = "This field cannot contain special characters";
            } else if (value.length > 50) {
                errorMsg = "Max 50 characters allowed";
            }
        } else if (name === "email") {
            const pattern = new RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            );
            if (!pattern.test(value)) {
                errorMsg = "Please enter a valid email address";
            } else if (/^.*@moderna@\.com$/i.test(value)) {
                errorMsg = "Email from modernatx@.com is not allowed";
            }
        } else if (name === "password") {
            if (value.length < 12) {
                errorMsg = "Passwords must be at least 12 characters";
            } else if (!/[a-z]/.test(value)) {
                errorMsg = "Passwords must contain a lower-case letter";
            } else if (!/[A-Z]/.test(value)) {
                errorMsg = "Passwords must contain an upper-case letter";
            } else if (!/\d/.test(value)) {
                errorMsg = "Password must contain a number";
            } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
                errorMsg = "Passwords must contain a special character";
            } else if (/moderna/i.test(value)) {
                errorMsg = "Password should not contain 'Moderna'";
            }
        } else if (name === "confirmPassword") {
            if (value !== password) {
                errorMsg = "Passwords do not match";
            }
        }
        return errorMsg;
    };

    const PasswordCriteriaList = () => (
        <List sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            {PasswordCriteriaListItem('minLength', 'Be at least 12 characters')}
            {PasswordCriteriaListItem('containsUppercase', 'Contain an upper-case letter')}
            {PasswordCriteriaListItem('containsLowercase', 'Contain a lower-case letter')}
            {PasswordCriteriaListItem('containsNumber', 'Contain a number')}
            {PasswordCriteriaListItem('containsSpecialChar', 'Contain a special character (!, ?, &, @, etc)')}
            {PasswordCriteriaListItem('passwordsMatch', 'Match')}
        </List>
    );

    const createTextField = (name, label, type = 'text', value, onChange,
                             onBlur, showPasswordToggle = false, toggleVisibility, autoComplete) => {
        return (
            <>
                <FormLabel label={label}/>
                <TextField
                    id={name}
                    data-testid={name}
                    name={name}
                    type={showPasswordToggle ? 'text' : type}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    focused
                    variant="outlined"
                    fullWidth
                    sx={textFieldStyles(name)}
                    autoComplete={autoComplete}
                    error={Boolean(errors[name])}
                    helperText={errors[name]}
                    FormHelperTextProps={helperTextStyles}
                    InputProps={(type === 'password') ? {
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={toggleVisibility}
                                    onMouseDown={(event) => event.preventDefault()}
                                    edge="end"
                                >
                                    {showPasswordToggle ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    } : undefined
                    }
                    inputProps={{
                        'data-testid': `${name}-input`
                    }}
                />
            </>
        )
    }

    const CreateAccountForm = (
        <form onSubmit={handleSubmit}>
            <Container data-testid='createAccountContainer'>
                <Box marginBottom="20px"><Typography textAlign='left' fontFamily="Aeonik Regular" letterSpacing="-0.32px">Please enter your business email address and password to get started.</Typography></Box>
                <Grid container rowSpacing={'16px'} columnSpacing={'16px'} justifyContent="start" alignItems="center">
                    <Grid item xs={12} sm={6} paddingBottom={'1px'}>
                        {createTextField("firstName", "Your first name", "text", inputFields.firstName, handleChange, handleBlur)}
                    </Grid>
                    <Grid item xs={12} sm={6} paddingBottom={'1px'}>
                        {createTextField("lastName", "Your last name", "text", inputFields.lastName, handleChange, handleBlur)}
                    </Grid>
                    <Grid item xs={12} sm={12} paddingBottom={'1px'}>
                        {createTextField("email", "Email address", "text", inputFields.email, handleChange, handleBlur)}
                    </Grid>
                    <Grid item xs={12} sm={12} paddingBottom={'1px'}>
                        {createTextField("password", "Password", "password", inputFields.password,
                            handleChange, handleBlur, showPassword, togglePasswordVisibility, "new-password")}
                    </Grid>
                    <Grid item xs={12} sm={12} paddingBottom={'1px'}>
                        {createTextField("confirmPassword", "Re-enter password", "password", inputFields.confirmPassword,
                            handleChange, handleBlur, showConfirmPassword, toggleConfirmPasswordVisibility, "new-password")}
                    </Grid>
                    <Grid item xs={12} sm={12} paddingBottom={'1px'}>
                        <Typography textAlign="left" paddingBottom='7px' fontFamily="Aeonik Regular" color="neutral.gray7" fontWeight="700">
                            Passwords must:
                        </Typography>
                        {PasswordCriteriaList()}
                    </Grid>
                </Grid>
                <Grid sx={buttonsContainerSxStyles}>
                    <Button
                        type="submit"
                        id="submit"
                        sx={{ padding: "16px 24px", textTransform: "none" }}
                        buttonType="mds-primary"
                        data-testid='submitBtn'
                        loading={processing}
                    >
                        Continue
                    </Button>
                </Grid>
            </Container>
        </form>
    )

    return(
        <>
            <AlertMessage variant={"filled"} type={"error"} message={generalApiError} ref={alertRef} />
            <ModalComponent
                title="Welcome to ModernaDirect.com"
                openModal={isCreateAccountModalOpen}
                closeModalHandler={handleCloseModal}
                modalContent={CreateAccountForm}
                style={{
                    '#crossIcon': {
                        marginTop: '5px'
                    },
                    '#titleContainer':{
                        padding: '5px',
                        marginBottom: '0px'
                    },
                    width: 'auto',
                    maxWidth: '548px',
                    padding: '32px',
                    '#modalTitle': {
                        marginRight: {xs: '40px'},
                    },
                    [theme.breakpoints.down('md')]: {
                        width: '500px',
                    },
                    [theme.breakpoints.down('sm')]: {
                        width: '350px',
                        marginTop: '60px'
                    },
                }}
            />
        </>
    )
}