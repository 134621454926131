import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Typography } from "@mui/material";

const CustomCheckbox = styled(Checkbox)(({ theme: { palette: { primary, status, neutral } }}) => ({
  "&": {
    color: primary?.blue,
  },
  "&.checkbox-error": {
    color: status?.error,
  },
  "&.Mui-checked": {
    color: primary?.blue,
  },
  "&.Mui-disabled": {
    color: neutral?.gray2
  }
}));

const CustomFormControlLabel = styled(FormControlLabel)(({ theme: { palette: { primary, status, neutral } }}) => ({
  ".MuiFormControlLabel-label": {
    fontSize: "14px",
    paddingLeft: "2px",
    color: primary?.main,
    "&.Mui-disabled": {
      color: primary?.main
    },
    '@media screen and (max-width: 500px)': {
      fontSize: "1rem"
    }
  },
}));

const CheckboxInput = ({
  id,
  name,
  label,
  onChange,
  value,
  disabled,
  error,
  required,
  ...checkboxProps
}) => {

  return (
    <CustomFormControlLabel
      control={
        <CustomCheckbox
          className={error ? "checkbox-error" : ""}
          checked={value}
          disabled={disabled}
          onChange={onChange}
          inputProps={{ "aria-label": "controlled" }}
          name={name}
          {...checkboxProps}
          sx={{
            padding: "8px",
            "& .MuiSvgIcon-root": {
              fontSize: 20,
            },
          }}
        />
      }
      id={id}
      label={<Typography variant={'p2'}>{label}{required && <Typography variant={'p2'} sx={{ color: "primary.red" }}>*</Typography>}</Typography>}
      sx={{...checkboxProps.sx}}
    />
  );
};

export { CheckboxInput as Checkbox };
