import * as React from "react";
import PropTypes from "prop-types";
import {styled} from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ScheduleIcon from '@mui/icons-material/Schedule';
import CloseIcon from '@mui/icons-material/Close';
import CircleIcon from '@mui/icons-material/Circle';
import OutputRoundedIcon from '@mui/icons-material/OutputRounded';
import { Typography } from "@mui/material";
import deleveredIcon from "./../../assets/images/delivered.svg";
import pendingIcon from "./../../assets/images/pending.svg";
import refreshIcon from "./../../assets/images/refresh.svg";
import badgeCheckIcon from "./../../assets/images/badgeCheckIcon.svg";
import warningInfoIcon from "./../../assets/images/warningInfoIcon.svg";

const CustomAlert = styled(Alert)(({width = 18, height = 18, theme: {palette: {neutral}}}) => ({
  '&': {
    alignItems: 'center',
    backgroundColor: 'transparent',
    padding: 0
  },
  "& .MuiAlert-icon": {
    marginRight: '6px',
    padding: 0,
    svg: {
      width,
      height
    },
  },
  "& .MuiAlert-message": {
    fontSize: 14,
    textAlign: "left",
    color: "#222222",
    opacity: 1,
    padding: '0 0',
  },
  "&.orderDetails": {
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end'
  },
  "&.orderDetails .MuiAlert-icon": {
    marginRight: '0',
    marginLeft: '6px',
    svg: {
      width: 40,
      height: 40
    },
  }
}));

const DeleveredIconStyle = styled("img")`
  width: 18px;
  height: 18px;
`;

const PendingIconStyle = styled("img")`
  width: 18px;
  height: 18px;
`;

const IconStyle = styled("img")`
  width: 18px;
  height: 18px;
`;

const renderStatus = (s, content, props) => {
  switch (s.toLowerCase()) {
    case "delivered":
      return (
        <CustomAlert className={props?.className}
          icon={<DeleveredIconStyle src={deleveredIcon} data-testid={'deliveredIcon'}/>}
        >
          <Typography variant={props?.variant ? props?.variant : 'p2'}>{content}</Typography>
        </CustomAlert>
      );
    case "invoiced":
      return (
        <CustomAlert className={props?.className}
          icon={<CheckCircleOutlineIcon sx={{color: 'status.success'}}/>}
        >
          <Typography variant={props?.variant ? props?.variant : 'p2'}>{content}</Typography>
        </CustomAlert>
      );
    case "open":
      return (
        <CustomAlert className={props?.className}
          icon={<OutputRoundedIcon sx={{color: 'primary.main'}}/>}
        >
          <Typography variant={props?.variant ? props?.variant : 'p2'}>{content}</Typography>
        </CustomAlert>
      );
    case "delayed":
      return (
        <CustomAlert className={props?.className}
          icon={<WarningAmberIcon sx={{color: 'status.error'}}/>}
        >
          <Typography variant={props?.variant ? props?.variant : 'p2'}>{content}</Typography>
        </CustomAlert>
      );
    case "shipped":
      return (
        <CustomAlert className={props?.className}
          icon={<LocalShippingOutlinedIcon sx={{color: 'blue'}}/>}
        >
          <Typography variant={props?.variant ? props?.variant : 'p2'}>{content}</Typography>
        </CustomAlert>
      );
    case "pending":
      return (
        <CustomAlert className={props?.className}
          icon={<PendingIconStyle src={pendingIcon} data-testid={'pendingIcon'}/>}
        >
          <Typography variant={props?.variant ? props?.variant : 'p2'}>{content}</Typography>
        </CustomAlert>
      );
    case "paid":
      return (
        <CustomAlert className={props?.className}
          icon={<CheckCircleOutlineIcon sx={{color: 'status.success'}}/>}
        >
          <Typography variant={props?.variant ? props?.variant : 'p2'}>{content}</Typography>
        </CustomAlert>
      );
    case "overdue":
      return (
        <CustomAlert className={props?.className}
          icon={<WarningAmberIcon sx={{color: 'status.error'}}/>}
        >
          <Typography variant={props?.variant ? props?.variant : 'p2'}>{content}</Typography>
        </CustomAlert>
      );
    case "cancelled":
      return (
        <CustomAlert className={props?.className}
          icon={<CloseIcon sx={{color: 'red'}}/>}
        >
          <Typography variant={props?.variant ? props?.variant : 'p2'}>{content}</Typography>
        </CustomAlert>
      );
    case "active":
      return (
        <CustomAlert className={props?.className}
          width={props.width}
          icon={<CircleIcon fontSize="small" sx={{color: 'status.success'}}/>}
        >
          <Typography variant={props?.variant ? props?.variant : 'p2'}>{content}</Typography>
        </CustomAlert>
      );
    case "inactive":
      return (
        <CustomAlert className={props?.className}
          width={props.width}
          icon={<CircleIcon fontSize="large" sx={{color: 'neutral.gray2'}}/>}
        >
          <Typography variant={props?.variant ? props?.variant : 'p2'}>{content}</Typography>
        </CustomAlert>
      );
    case "maintenance":
      return (
        <CustomAlert className={props?.className}
          width={'24px'}
          height={'24px'}
          icon={<WarningAmberIcon sx={{ color:'neutral.white', marginRight: '10px' }} />}
        >
          <Typography variant='h4' color='neutral.white' sx={{ lineHeight: 'inherit' }}>{content}</Typography>
        </CustomAlert>);
    case "processing":
      return (
        <CustomAlert className={props?.className} width={'24px'} height={'24px'}
          icon={<IconStyle src={refreshIcon} data-testid={'refreshIcon'}/>}
        >
          <Typography variant={props?.variant ? props?.variant : 'p2'}>{content}</Typography>
        </CustomAlert>
      );
    case "checked":
      return (
        <CustomAlert className={props?.className} width={'24px'} height={'24px'}
        icon={<IconStyle src={badgeCheckIcon} data-testid={'checkedIcon'}/>}
      >
        <Typography variant={props?.variant ? props?.variant : 'p2'}>{content}</Typography>
      </CustomAlert>
        ); 
    case "warning":
      return (
        <CustomAlert className={props?.className} width={'24px'} height={'24px'}
        icon={<IconStyle src={warningInfoIcon} data-testid={'warningIcon'}/>}
      >
        <Typography variant={props?.variant ? props?.variant : 'p2'}>{content}</Typography>
      </CustomAlert>
        );           
    default:
      return (
        <CustomAlert className={props?.className}
          icon={<ReportProblemOutlinedIcon sx={{color: '#49B705', fontSize: 18}}/>}
        >
          <Typography variant={props?.variant ? props?.variant : 'p2'}>{content}</Typography>
        </CustomAlert>
      );
  }
};

export const StatusIndicators = ({status, children, ...rest}) => {
  return (<>
      {status &&
        <Box>{renderStatus(status, children, rest)}</Box>
      }
  </>
    )
};
